
@font-face {
	font-family: HelveticaNeue;
	src: url(../assets/fonts/Helvetica-Neue/Helvetica.ttf);
}
@font-face {
	font-family: HelveticaNeue-Thin;
	src: url(../assets/fonts/Helvetica-Neue/HelveticaNeueThin.ttf);
}
@font-face {
	font-family: HelveticaNeue-Light;
	src: url(../assets/fonts/Helvetica-Neue/HelveticaNeue-Light.ttf);
}
@font-face {
	font-family: HelveticaNeue-Medium;
	src: url(../assets/fonts/Helvetica-Neue/HelveticaNeue-Medium.ttf);
}
@font-face {
	font-family: HelveticaNeueHv;
	src: url(../assets/fonts/Helvetica-Neue/HelveticaNeueHv.ttf);
}
@font-face {
	font-family: HelveticaNeue-Bold;
	src: url(../assets/fonts/Helvetica-Neue/HelveticaNeue-Bold.ttf);
}

.main-layout{
	font-family: HelveticaNeue;
	font-size: 16px;
}

.header-public-sale { 
	width: 100%;
	padding: 20px 50px;
	position: absolute;
	z-index: 1;
	
}
.header-public-sale .ant-menu-item{
	margin-bottom: -10px !important;
}
.logo-bluesale{
	float: left;
}

iframe {
	display: none;
}

.header-desktop {
	float: right;
	background: transparent !important;
	border-right: 1px solid transparent;
	width: 200px;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) {
	color: #fff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after {
	border-bottom: none !important;
}

.ant-menu-horizontal>.ant-menu-item::after {
	border-bottom: none !important;
}

.header-desktop:not(.ant-menu-dark)>.total-balance:hover::after {
	border-bottom: none !important;
	padding: unset;
}

.header-desktop>.ant-menu-item::after {
	bottom: -11px;
}


.header-desktop:not(.ant-menu-dark)>.ant-menu-item {
	padding: 0;
	text-align: right;
}

.header-desktop .ant-menu-title-content a {
	font-family: HelveticaNeue;
	margin: 0;
	font-size: 16px;
	padding: 0px 10px;
	border-radius: 8px;
	cursor: pointer;
	line-height: 38px;
	transition: background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	font-size: 16px;
 
	color: #212529;
}

.link-blue {
	border: 1px solid #0079FF !important;
	color: #0079FF !important;
}

.link-blue:focus,
.link-blue:hover {
	background: #fff;
	color: #0079FF !important;
	text-shadow: 0 0 0px #0079FF !important;
}

.footer-social-item::before{
	display: none !important;
}
.header-desktop>.ant-menu-item a:hover {
	text-shadow: 0 0 5px #fff;
	color: #4759EB;
}

.header-desktop .ant-menu-item-selected {
	color: #4759EB;
	background: transparent !important;
	background-color: transparent;
}
.header-desktop .ant-menu-item-selected a{
	color: #4759EB;
}
.header-desktop .ant-menu-item-selected .ant-menu-title-content a::before {
	content: '';
	display: inline-block;
	background: url("../assets/images/active-menu-des.svg");
	width: 25px;
	height: 25px;
	position: absolute;
	margin-right: 0px;
	margin-top: 18px;
	margin-left: -50px;
	right: unset;
	left: unset;
	top: -10px;
}

.hide-menu {
	display: none;
}

.show-mobile {
	display: block;
}

.text-left {
	text-align: left !important;
}
.text-center {
	text-align: center !important;
}
.text-right {
	text-align: right !important;
}

.header-mobile {
	display: none;
}

.hide-menu {
	display: none;
}

.show-mobile {
	display: block;
}

@media screen and (max-width: 768px) {
	.header-social-mobile{
		padding: 0;
		margin: auto;
		padding-left: 7% !important;
		display: unset !important;
		float: left;
		width: 100%;	
		margin-top: 50px;
		
	}
	.header-social-mobile a img{
		width: 20px;
		filter: brightness(0.3);
	}
		
	.header-footer-mobile{
		position: absolute !important;
		bottom: 11px;
		padding-left: 10px !important;
		height: 100px !important;
	}
	.header-footer-mobile .header-social-mobile li a{
		font-size: 14px !important;
	}
	.header-footer-mobile p{
		float: left;
		color: #606E9C;
		width: 100%; 
		text-align: center;
	}
	.header-social-mobile li{
		list-style-type: none;
		float: left;
		padding: 0 10px;
		color: #000000;
	}
	.item-menu-height{
		height: 70px !important;
	}
	.logo-bluesale{
		display: none;
	}
	.header-public-sale{
		padding: 10px 15px;
		position: fixed; 
		backdrop-filter: blur(20px);
	}
	.mobile-icon-menu {
		color: #fff;
	}

	.header-desktop:not(.ant-menu-dark)>.ant-menu-item {
		padding: 0 !important;
	}

	.header-desktop {
		display: none !important;
	}

	.btnConnect {
		margin-right: 0px !important;
	}
	.btn-menu-mobile {
		color: #4759EB;
		padding: 5px 11px;
		height: 40px;
		width: 40px;
		text-align: center;
		border: 0px solid transparent;
		font-size: 18px;
		background: #FFFFFF;
		box-shadow: 0px 4px 30px rgba(45, 52, 128, 0.21);
		border-radius: 6px;
	}
	.btn-menu-mobile-close {
		color: #4759EB;
		padding: 5px 11px;
		height: 40px;
		width: 40px;
		text-align: center;
		border: 0px solid transparent;
		font-size: 18px;
		background: #FFFFFF;
		box-shadow: 0px 4px 30px rgba(45, 52, 128, 0.21);
		border-radius: 6px;
	}
	/* .btn-menu-mobile-close svg {
		font-size: 20px !important;
		font-weight: bold;
		margin-top: 6px;
		color: #fff !important;
	} */
	.header-mobile {
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		background: transparent;
		position: relative;
		z-index: 1001;
	}
	.item-menu-clo .header-left {
		width: 50%;
		float: left;
		text-align: left;
	}
	.item-menu-clo .header-right {
		width: 50%;
		float: left;
		text-align: right;
		margin-top: 2px;
	}
	.header-mobile .header-left {
		width: 50%;
		float: left;
		text-align: left;
	}
	.header-mobile .header-right {
		width: 50%;
		float: left;
		text-align: right;
	}
	.logo-mobile {
		max-width: 135px;
		width: auto;
	}
	.icon-mobile{
		width: 100%;
	}
	.menu-mobile {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1005;
	}

	.menu-mobile .menu-mobile-ul {
		padding-top: 15px;
	}

	.header-mobile .header-right {
		position: relative;
		z-index: 999;
	}

	.menu-mobile .ant-menu-root {
		height: 100vh;
		background: url(../assets/images/bg-menu-mobile.png);
		background-size: 100%;
		margin-top: 0px;
	}

	.menu-mobile .ant-menu-dark.ant-menu-inline .ant-menu-item {
		margin-top: -10px !important;
	}

	.menu-mobile .ant-menu-dark .ant-menu-item>span>a {
		padding-left: 0;
	}

	.menu-mobile-ul .item-menu-clo {
		background: transparent;
		margin-top: 0px !important;
		height: 70px;
		padding: 0px !important;
		margin-bottom: 15% !important;
	}
	.menu-mobile-ul .item-menu-clo .ant-menu-title-content{
		height: 85px;
    	padding: 10px 15px;
	}

	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: transparent !important;
		color: #0079ff;
	}
	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content .title::before {
		content: '';
		display: inline-block;
		background: url("../assets/images/active-menu.svg");
		width: 50px;
		height: 50px;
		position: absolute;
		margin-right: 0px;
		margin-top: 18px;
		margin-left: -65px;
		right: unset;
		left: unset;
		top: -17px;
	}

	.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
	.ant-menu-dark .ant-menu-item-selected .anticon {
		color: #0079ff;
	}
	.menu-mobile .menu-mobile-ul .ant-menu-item .ant-menu-title-content {
		text-align: center;
	}
	.menu-mobile .menu-mobile-ul .ant-menu-item .ant-menu-title-content a { 
		font-size: 22px;
		text-align: right;
		color: #212529;  
	} 
	.menu-mobile .menu-mobile-ul .ant-menu-item-selected .ant-menu-title-content a {
		color: #4759EB;
	}

	.menu-mobile .ant-menu-dark.ant-menu-inline .ant-menu-item:nth-child(8){
		margin-bottom: 58px !important;
	}

}