.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}
.text-left-xs{
    text-align: right;
    @include sp {
        margin-top: 10px;
        text-align: left !important;
    }
    
}
.text-info{
    color: #3947B7;
}
.text-gray-blue {
    color: #5C6D8A;
}
.text-normal-gray {
    color: #94A7C6;
}
.text-warning{
    color: #D25F00;
}
.text-brown{
    color: #887100;
}
.text-normal{
    color: #1D2129;
}
.bg-transparent{
    background: transparent !important;
}
.mt-15{
    margin-top: 15px;
}
.ml-15 {
    margin-left: 15px;
}
.w-auto{
    width: max-content !important;
}
.m-auto{
    margin: auto !important;
}
.font-20{
    font-size: 20px;
}
.font-16{
    font-size: 16px;
}
.font-18{
    font-size: 18px;
}
.font-medium {
    font-weight: 500;
}
.btn-p50{
    padding-left: 50px;
    padding-right: 50px;
}
.btn-p20 {
    padding-left: 20px;
    padding-right: 20px;
}
.custom-modal{
    border-radius: 10px;

    .ant-modal-content{
        border-radius: 0px !important;
    }
}
.p-lr-15{
    padding-left: 15px;
    padding-right: 15px;
}
.text-gray{
    color: #94A7C6;
}
.text-white {
    color: #FFFFFF;
}
.text-blue{
    color: #3947B7;
}
.d-mobile{
    display: none;
    @include sp {
        display: block !important;
    }
}
.d-desktop {
    display: block;

    @include sp {
        display: none !important;
    }
}
.p-airdropList {
    background: url(../images/bg-main.png) no-repeat top right;
    background-attachment: fixed;
    background-size: cover;
    padding: 96px 32px 100px;
    min-height: 100vh;

    @include sp {
        padding: 76px 16px 50px;
    }

    &__banner_airdrop {
        width: 100%;
        border-radius: 10px;
        padding-top: 15px;

        img {
            width: 100%;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: -32px;
        width: calc(100% + 32px);

        @include sp {
            display: block;
            margin-right: -16px;
            width: calc(100% + 16px);
        }

        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.14em;
            text-transform: uppercase;
            color: #5C6D8A;
            margin: 0;
            width: 195px;

            @include sp {
                width: 100%;
                margin: 0 0 8px;
            }
        }

        
    }

    &__head {
        margin: 15px 0 32px;

        @include sp {
            margin: 40px 0 35px;
        }

        h2 {
            font-weight: 400;
            font-size: 36px;
            line-height: 44px;
            text-align: left;
            color: #1D2129;
            margin-bottom: 8px;

            @include sp {
                font-size: 28px;
                line-height: 32px;
                text-align: center;
                margin-bottom: 20px;
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #5C6D8A;
            margin-bottom: 18px;
            text-align: center;

            @include sp {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .c-search {
            width: 578px;
            margin: 0 auto;
            margin-right: 0px;

            @include sp {
                width: 100%;
                max-width: 560px;
            }
        }
    }

    &__box {
        .ant-tabs-nav {
            padding: 0px !important;

            .ant-tabs-extra-content {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >p {
                    margin: 0;
                    display: flex;

                    span {
                        display: block;
                        width: 24px;
                        cursor: pointer;

                        +span {
                            margin-left: 24px;
                        }

                        svg {
                            display: block;
                            width: 100%;

                            path {
                                fill: #5C6D8A;
                                transition: .3s ease-in-out;
                            }
                        }

                        &.is-active {
                            svg {
                                path {
                                    fill: #4759EB;
                                }
                            }
                        }
                    }
                }
            }
        }
        .empty-airdrop {
            width: 100%;
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;

            &img {
                width: 100%;
                max-width: 180px;
                border-radius: 10px;
            }
        }

        .airdrop-item {
            min-height: 480px;
            width: 100%;
            box-shadow: (0px 0px 20px rgba(88, 104, 233, 0.1));
            border-radius: 40px;
            background: #FFFFFF;
            padding: 15px;
            margin-top: 15px;
            float: left;
            padding-bottom: 25px;
            filter: drop-shadow(0px 0px 20px rgba(88, 104, 233, 0.1));
            transition: .3s ease-in-out;

            &:hover {
                filter: drop-shadow(4px 4px 0px #4759EB);    
            }
            .airdrop-status {
                width: 100%;
                text-align: right;
                & a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    margin: 0 0 0 auto;
                    /* background: #FBE842; */
                    border-radius: 50%;
                    position: relative;
                    z-index: 1;
                    transition: 0.3s ease-in-out;
                }
            }
            .airdrop-name{
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #1D2129;

                a {
                    color: #1D2129;
                }
            }
            .airdrop-text {
                width: 100%;
                float: left;
                margin-top: 8px;

                & span:nth-child(1){
                    float: left;
                    font-weight: 400;
                }
                & span:nth-child(2){
                    float: right;
                    font-weight: 500;
                }
            }
            .airdrop-img{
                width: 100%;
                margin-bottom: 15px;

                & img{
                    margin-top: 15px;
                    width: 100%;
                    border-radius: 5px;
                }
            }
            .airdrop-button{
                width: 100%;
                margin-top: 15px;
                text-align: center;
                float: left;
                .btn-airdrop{
                    width: 100%;
                    max-width: 240px;
                    height: 44px;
                    background: #4759EB;
                    border-radius: 2px;
                    color: #fff;
                    font-weight: 600;
                    border: none;

                    &:hover{
                        background: #2d3db9;
                        cursor: pointer;
                    }
                    &:disabled{
                        opacity: 0.6;
                    }
                }
            }
        }
        .airdrop-upcoming {
            .airdrop-status {
                & a{
                    background: #FBE842; 
                    & svg {
                        margin-top: 3px;
                        & path {
                            fill: #887100;
                        }
                        &:hover path {
                            fill: #fff;
                        }
                    }
                }
            }
            .text-time-countdown .c-countDown{
                color: #887100 !important;
                & span {
                    color: #887100 !important;
                }
            }
        }
        .airdrop-opening {
            .airdrop-status {
                & a {
                    background: #FF9A2E;
                    & svg {
                        margin-top: 3px;
                        & path {
                            fill: #D25F00;
                        }
                        &:hover path {
                            fill: #fff;
                        }
                    }
                }
            }
            .text-time-countdown .c-countDown{
                color: #D25F00 !important;
                & span {
                    color: #D25F00 !important;
                }
            }
        }
        .airdrop-completed {
            .airdrop-status {
                & a {
                    background: #23C343;
                    & svg {
                        margin-top: 3px;
                        & path {
                            fill: #009A29;
                        }
                        &:hover path {
                            fill: #fff;
                        }
                    }
                }
            }
            .text-time-countdown .c-countDown{
                color: #009A29 !important;
                & span {
                    color: #009A29 !important;
                }
            }
        }
        .airdrop-ended {
            .airdrop-status {
                & a {
                    background: #f37660;

                    & svg {
                        margin-top: 3px;

                        & path {
                            fill: #ff4d2e;
                        }

                        &:hover path {
                            fill: #fff;
                        }
                    }
                }
            }

            .text-time-countdown .c-countDown {
                color: #ff4d2e !important;

                & span {
                    color: #ff4d2e !important;
                }
            }
        }
    }

    &__sort {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__listHead {
        padding: 24px 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;

        @include max(990px) {
            padding: 16px 24px;
        }

        p {
            width: calc(100%/8);
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #5C6D8A;
            padding: 0 4px;
            text-align: center;

            @include max(990px) {
                width: 100%;
                text-align: left;
                padding: 0;
            }
        }
    }
}
.airdrop-relative{
    position: relative;
}
.airdrop-fixed {
    position: fixed;
}
.airdrop-right {
    display: block;
    // width: 100%;
    height: auto;
    top: 65px;
    background: #F2F3FC;
    border-radius: 0px;
    padding: 24px;
    @include sp {
        position: relative;
        width: 100%;
        top: 15px;
        padding: 8px;
    }

    .airdrop-right-title{
        // font-family: 'Inter';
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: left;
        color: #5C6D8A;
    }
    .airdrop-right-item-50{
        width: 50%;
    }
    .airdrop-right-item-100 {
        width: 100%;
    }
    .airdrop-right-item{
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 15px;
        padding-top: 10px;
        padding-bottom: 10px;

        .airdrop-right-item-img{
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;

            & img{
                width: auto;
                max-width: 100%;
            }
        }
        & p {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 24px;
            color: #5C6D8A;

            & span{
                color: #1D2129 !important;
                font-weight: 500 !important;
            }
        }
        .airdrop-banner{
            width: 100%;
            & img{
                width: 100%;
            }
        }
    }
}
.p-airdropCreate {
    background: #f2f3fc;
    background-attachment: fixed;
    background-size: 100% 100%;
    padding-top: 64px;
    padding-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    min-height: 100vh;

    @include sp {
        padding: 76px 16px 50px;
    }

    .airdrop-create-step{
        display: block !important;

        @include sp {
            display: none !important;
        }

        .ant-steps-item{
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .ant-steps-item-active{
            border-bottom: 2px solid #4759EB;;
        }
    }
    
    .airdrop-create-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 25px;
        position: relative;
        @include sp {
            padding: 0px 15px;
        }

        .step {
            text-align: center;
            padding-top: 40px;
            padding-bottom: 30px;
    
            @include sp {
                padding-top: 34px;
                padding-bottom: 16px;
            }
        }
    
        .step-text {
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
    
            @include max(1024px) {
                padding: 0px;
            }
    
            @include sp {
                font-size: 20px;
                line-height: 24px;
                padding: 0px;
            }
        }

        .airdrop-inputs-steps{
            width: 80%;
            margin: auto;
            margin-top: 25px;
            @include sp {
                width: 100%;
            }
        }
        
        .airdrop-step-bg-title{
            background: #D9DFFB;
            width: 100%;
            border: 1px solid #D9DFFB;
            border-radius: 5px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 40px;
            margin-bottom: 30px;
            color: #5C6D8A;
            font-size: 16px;
            @include sp {
                margin-top: 15px;
            }
        }
        .airdrop-info-form{
            width: 80%;
            margin: auto;
            margin-top: 25px;
            @include sp {
                width: 100%;
            }
        }
        .p-0{
            padding: 0px !important;
        }
        
    }
    .airdrop-create-action {
        width: 100%;
        text-align: center;
    }
}
.des-input-form{
    font-size: 14px;
    line-height: 20px;
    color: #1A7EC8;
    margin-top: 5px;
    margin-bottom: 0px;
}
.airdrop-detail{
    background: url(../images/bg-airdrop-detail.png);
    background-position-y: 64px;
    background-size: 100% 100%;
    padding-top: 64px;
    padding-bottom: 80px;
    position: relative;
    min-height: 100vh;

    .airdrop-breadcrum{
        background: #F2F3FC;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;

        & p{
            margin-bottom: 5px;
        }
    }
    .airdrop-page-content{
        padding: 30px;
        @include sp {
            padding: 15px;
        }

        .airdrop-detail-banner{
            width: 100%;
            margin-bottom: 15px;

            & img{
                width: 100%;
                border-radius: 10px;
            }
        }
        .airdrop-detail-des{
            margin-bottom: 10px;
            font-size: 16px;
            color: #5C6D8A;
        }
        .airdrop-detail-social{
            width: 100%;
            margin-bottom: 10px;
            border-bottom: 1px solid #E5E6EB;
            padding-bottom: 20px;
            & img{
                width: 25px;
                margin-right: 10px;
            }
        }
        .airdrop-line{
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #E5E6EB;
        }

        .airdrop-detail-right{
            width: 100%;

            h3.airdrop-name{
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: #1D2129;

                @include sp {
                    margin-top: 25px;
                }
            }
            .airdrop-detail-coundown{
                width: 100%;
                margin-top: 0px;
                margin-bottom: 10px;

                p{
                    margin-bottom: 0px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #5C6D8A;
                }
                
                .aird-detail-countdown-block{
                    margin-top: 10px;
                    display: inline-block;
                    margin-bottom: 10px;
                }
                .aird-detail-countdown-block span {
                    background: #FBE842;
                    opacity: 0.8;
                    border-radius: 4px;
                    padding: 10px 15px;
                    text-align: center;
                    color: #1D2129;
                    display: inline-block;
                }
            }
            .airdrop-detail-allocation-act{
                margin-top: 10px;
                padding-top: 15px;
                padding-bottom: 10px;
                font-size: 18px;
            }
            .airdrop-allocation-empty{
                width: 100%;
                padding-top: 10px;
                padding-bottom: 30px;
                text-align: center;
            }
            .loading-black{
                color: #D25F00;
                font-size: 20px;
                
                .ant-spin-dot-item{
                    background-color: #1890ff !important;
                }
            }
            .airdrop-your-allocation{
                border-radius: 5px;
                background: #5C6D8A;
            }
            .card-box-custom{
                box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
                border-radius: 5px;
                margin-top: 30px !important;
            }
            .card-title-custom{
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .card-title-p{
                white-space: break-spaces;
                font-weight: 400 !important;
                color: #5C6D8A;
            }
            .air-edit{
                margin-left: 15px;
                margin-top: -5px;
            }
            .table-allocation{

                .ant-table-cell{
                    border: none !important;
                }
            }
        }
    }
}