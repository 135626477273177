@mixin max($point) {
    @media screen and (max-width: $point) {
        @content;
    }
}
@mixin min($point) {
    @media screen and (min-width: $point) {
        @content;
    }
}

@mixin tablet() {
    @media screen and (max-width: 1024px) {
        @content;
    }
}
@mixin sp() {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@import 'layout';
@import 'sideBar';
@import 'component';
@import 'button';
@import 'input';
@import 'popup';
@import 'page';
@import 'airdrop';
@import 'public-sale';
@import 'header';
@import 'lauchpad';
@import 'home';