@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.approve-more {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p-multiSender__note.is-red {
  color: #ff4d4f;
}
ul.ant-pagination.pagin-multi {
  text-align: end;
  padding-top: 15px;
}
.is-pending {
  color: #faad14;
  background: #faad142e;
}
.is-fail{
  color: #ff4d4f;
    background: #ff4d4f38;
}
.err-net {
  display: flex;
}
.modal-sample .ant-modal-content{
border-radius: 0
}
.line-csv {
  border: 1px solid #d9d9d9;
  padding: 15px;
}
.btn_switch_net_pri {
  margin: 0 auto;
  margin-top: 24px;
  height: 44px;
  background: #394df5;
  border-radius: 2px;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.inputfile {
  display: none;
}
.multi-hash{
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .multi-hash{
    width: 71%;
      word-wrap: break-word;
  }
  .item-multi {
      width: 71%;
      word-wrap: break-word;

    }
  .c-input textarea::placeholder {
      font-size: 14px;
    }
    .p-multiSender__infoToken{
      font-size: 14px !important;
    }
    .p-multiSender__infoAddress{
      font-size: 14px !important;
    }
}


.file-button {
  border-radius: 2px;
  padding: 10px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background: #fff;
  color: #5c6d8a;
  border: 1px solid #5c6d8a;
}
.modal-multi {
  background: none;
  color: #4759eb;
  border: none;
  padding-left: 15px;
}
.file-button.mobie {
  border-radius: 2px;
  padding: 10px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background: #fff;
  color: #5c6d8a;
  border: 1px solid #5c6d8a;
}

.p-pageComponent {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 20px;
  &__item {
    width: calc(100% / 3 - 20px);
    margin: 0 10px 30px;
    h3 {
      font-size: 16px;
      font-weight: normal;
    }
  }
  > span {
    display: block;
    width: 100%;
    height: 1px;
    background: #333;
  }
}

.p-home {
  background: url(../images/bg-home.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  padding: 95px 0 0;
  min-height: 100vh;
  transition: 0.3s ease-in-out;
  @include sp {
    padding: 76px 0 0;
    overflow: hidden;
  }
  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 32px;
    @include sp {
      display: block;
      padding-left: 16px;
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #5c6d8a;
      margin: 0;
      width: 195px;
      @include sp {
        width: 100%;
        margin: 0 0 8px;
      }
    }
    .marquee-container {
      width: calc(100% - 195px);
      background: #dcf3ec;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 72px 0 0 72px;
      padding: 10px 14px 10px 32px;
      box-sizing: border-box;
      @include sp {
        width: 100%;
      }
      &:before {
        content: "";
        width: 32px;
        height: 100%;
        background: #dcf3ec;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      a {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #1a96a0;
        padding: 0 0 0 15px;
        margin: 0 16px;
        position: relative;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          border: 1px solid #1a96a0;
          position: absolute;
          top: calc(50% - 3px);
          left: 0;
          box-sizing: border-box;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__head {
    display: flex;
    margin-top: 56px;
    padding: 0 32px;
    @include max(1024px) {
      display: block;
    }
    @include sp {
      padding: 0 16px;
      display: block;
      margin-top: 36px;
    }
    h2 {
      width: calc(50% - 32px);
      font-weight: 400;
      font-size: 48px;
      line-height: 72px;
      color: #1d2129;
      margin: 0;
      @include max(1280px) {
        width: calc(45% - 16px);
      }
      @include max(1024px) {
        width: 100%;
      }
      @include sp {
        font-size: 32px;
        line-height: 48px;
      }
      span {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
        color: #94a7c6;
        @include sp {
          font-size: 16px;
          line-height: 27px;
        }
        img {
          display: block;
          margin-left: 24px;
          @include sp {
            margin-left: 16px;
            width: 116px;
          }
        }
      }
    }
    p {
      width: calc(50% - 32px);
      margin-left: 64px;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      color: #1d2129;
      padding-top: 10px;
      @include max(1280px) {
        width: calc(55% - 16px);
        margin-left: 32px;
        margin-bottom: 0;
      }
      @include max(1024px) {
        width: 100%;
        margin-left: 0;
        margin-top: 32px;
        padding: 0;
      }
      @include sp {
        font-size: 16px;
        line-height: 24px;
      }
      > span {
        display: block;
        margin-bottom: 32px;
        @include sp {
          margin-bottom: 16px;
        }
      }
      .c-btn {
        @include sp {
          width: 100%;
        }
        + .c-btn {
          margin-left: 8px;
          @include sp {
            margin-left: 0;
            margin-top: 8px;
          }
        }
      }
    }
  }
  &__title {
    text-align: center;
    @include sp {
      text-align: left;
      padding: 0 16px;
    }
    &.is-center {
      text-align: center;
      p {
        @include sp {
          margin-bottom: 16px;
        }
      }
    }
    h3 {
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
      color: #1d2129;
      margin: 0 0 8px;
      @include sp {
        font-size: 24px;
        line-height: 36px;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1d2129;
      margin-bottom: 24px;
      @include sp {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__boxWhite {
    background: #fff;
    padding: 72px 0 40px;
    margin-top: 72px;
    @include sp {
      padding: 56px 0 40px;
      margin-top: 0;
    }
  }
  &__link {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    &.is-left {
      justify-content: flex-start;
      padding: 0 16px;
      margin-top: 16px;
    }
    a {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      transition: color 0.3s ease-in-out;
      img {
        display: block;
        width: 16px;
        margin-left: 6px;
        filter: brightness(0);
        transition: transform 0.3s ease-in-out;
      }
      span {
        border-bottom: 1px solid #1d2129;
        transition: color 0.3s ease-in-out;
        color: #1d2129;
      }
      &:hover {
        img {
          transform: translateX(6px);
          filter: brightness(1);
        }
        span {
          color: #4759eb;
          border-bottom: 1px solid #4759eb;
        }
      }
    }
  }
  &__airdrop {
    display: flex;
    margin: 72px 0;
    @include max(991px) {
      display: block;
    }
    @include sp {
      margin: 56px 0;
    }
    > h3 {
      width: 324px;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
      color: #1d2129;
      border-right: 1px solid #d9dffb;
      margin: 0 8px 0 0;
      padding-right: 16px;
      @include max(1280px) {
        width: 280px;
      }
      @include max(991px) {
        width: 100%;
        border-right: 0;
        padding-left: 32px;
        margin-bottom: 24px;
      }
      @include sp {
        padding-left: 16px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1d2129;
        margin: 24px 0;
      }
      .c-btn {
        width: 170px;
      }
      a {
        width: 200px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        transition: color 0.3s ease-in-out;
        img {
          display: block;
          width: 16px;
          margin-left: 6px;
          transition: transform 0.3s ease-in-out;
          filter: brightness(0);
        }
        span {
          color: #1d2129;
          border-bottom: 1px solid #1d2129;
          transition: color 0.3s ease-in-out;
        }
        &:hover {
          img {
            transform: translateX(6px);
            filter: brightness(1);
          }
          span {
            color: #4759eb;
            border-bottom: 1px solid #4759eb;
          }
        }
      }
    }
  }
  &__supported {
    background: linear-gradient(110.63deg, #fefefe 0%, #ebecf3 100.47%);
    padding: 88px 32px 48px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @include max(1200px) {
      display: block;
    }
    @include sp {
      padding: 36px 40px 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: url(../images/bg-chain.png) no-repeat bottom left;
      background-size: auto;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      @include sp {
        background-size: 100%;
      }
    }
    h3 {
      width: 100%;
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      background: linear-gradient(90deg, #e662f2 12.5%, #3cd1e6 88.07%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 36px;
      @include sp {
        font-size: 24px;
        line-height: 36px;
      }
    }
    ul {
      width: calc(100% - 400px);
      padding: 0 0 0 70px;
      margin: 0;
      position: relative;
      z-index: 1;
      @include max(1200px) {
        width: 100%;
        padding: 0 32px;
      }
      @include sp {
        padding: 0;
      }
      li {
        padding: 40px 40px 40px 0;
        display: flex;
        align-items: center;
        @include max(1280px) {
          padding: 40px 0;
        }
        @include sp {
          padding: 24px 0;
          display: block;
        }
        + li {
          border-top: 1px solid #86909c;
        }
        span {
          display: block;
          width: 165px;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #1d2129;
          margin-right: 36px;
          @include sp {
            width: 100%;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 8px;
          }
        }
        p {
          width: calc(100% - 201px);
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #1d2129;
          margin: 0;
          @include sp {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  &__chain {
    width: 400px;
    height: 400px;
    position: relative;
    border-radius: 50%;
    z-index: 1;
    @include max(1200px) {
      margin: 0 auto;
    }
    @include sp {
      width: 290px;
      height: 290px;
    }
    &:before {
      content: "";
      width: 184px;
      height: 184px;
      background: url(../images/chain-center.png) no-repeat center;
      background-size: cover;
      position: absolute;
      top: calc(50% - 92px);
      left: calc(50% - 92px);
      z-index: 1;
      @include sp {
        width: 136px;
        height: 136px;
        top: calc(50% - 68px);
        left: calc(50% - 68px);
      }
    }
    p {
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 50%;
      animation: rotation 15s infinite linear;
      span {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 92px;
        position: absolute;
        top: calc(50% - 46px);
        left: 0;
        @include sp {
          height: 68px;
          top: calc(50% - 34px);
        }
        &:nth-child(1) {
          transform: rotate(90deg);
        }
        &:nth-child(2) {
          transform: rotate(148deg);
        }
        &:nth-child(3) {
          transform: rotate(210deg);
        }
        img {
          display: block;
          width: 92px;
          height: 92px;
          @include sp {
            width: 68px;
            height: 68px;
          }
        }
      }
    }
  }
  &__collabor {
    background: #fff;
    padding: 75px 32px 45px;
    @include sp {
      padding: 56px 16px;
    }
    h3 {
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: #1d2129;
      margin-bottom: 16px;
      @include sp {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
      }
    }
    ul {
      padding: 0;
      margin: 0 -32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include sp {
        margin: 0 -14px;
      }
      li {
        width: 143px;
        margin: 20px 32px;
        text-align: center;
        @include sp {
          margin: 12px 14px;
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  &__footer {
    padding: 72px 0 100px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #94a7c6;
      img {
        display: block;
        width: 24px;
        margin-right: 4px;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #94a7c6;
      margin: 24px 0 0;
    }
  }
  &__imgSP {
    padding: 0 16px;
    margin: 24px 0 56px;
    img {
      display: block;
      width: 100%;
    }
  }
  .c-scrollHome {
    position: relative;
    width: 932px;
    margin: 90px auto 60px;
    @include max(1280px) {
      width: 603px;
    }
    &__boxImg {
      width: 932px;
      height: 480px;
      position: absolute;
      top: 0;
      z-index: 1;
      transition: 0.3s ease-in-out;
      @include max(1280px) {
        width: 603px;
        height: 322px;
      }
      &.is-img01 {
        top: 0;
      }
      &.is-img02 {
        top: calc(100% - 480px);
        @include max(1280px) {
          top: calc(100% - 322px);
        }
      }
      img {
        width: 100%;
      }
    }
    &__img01,
    &__img02 {
      width: 932px;
      height: 480px;
      padding: 87px 29px 37px 200px;
      position: relative;
      z-index: 2;
      @include max(1280px) {
        width: 603px;
        height: 322px;
        padding: 61px 14px 27px 127px;
      }
      img {
        width: 100%;
      }
    }
    &__img02 {
      margin-top: 128px;
    }
  }
}

.p-launchpadList {
  background: url(../images/bg-main.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  padding: 96px 32px 50px;
  min-height: 100vh;
  @include sp {
    padding: 76px 16px 50px;
  }
  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -32px;
    width: calc(100% + 32px);
    @include sp {
      display: block;
      margin-right: -16px;
      width: calc(100% + 16px);
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #5c6d8a;
      margin: 0;
      width: 195px;
      @include sp {
        width: 100%;
        margin: 0 0 8px;
      }
    }
    .marquee-container {
      width: calc(100% - 195px);
      background: #dcf3ec;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 72px 0 0 72px;
      padding: 10px 14px 10px 32px;
      box-sizing: border-box;
      @include sp {
        width: 100%;
      }
      &:before {
        content: "";
        width: 32px;
        height: 100%;
        background: #dcf3ec;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      a {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #1a96a0;
        padding: 0 0 0 15px;
        margin: 0 16px;
        position: relative;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          border: 1px solid #1a96a0;
          position: absolute;
          top: calc(50% - 3px);
          left: 0;
          box-sizing: border-box;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__head {
    margin: 48px 0 32px;
    @include sp {
      margin: 40px 0 35px;
    }
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: #1d2129;
      margin-bottom: 8px;
      @include sp {
        font-size: 28px;
        line-height: 32px;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5c6d8a;
      margin-bottom: 18px;
      text-align: center;
      @include sp {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .c-search {
      width: 578px;
      margin: 0 auto;
      @include sp {
        width: 100%;
        max-width: 560px;
      }
    }
  }
  &__box {
    &.is-private {
      .ant-tabs-nav {
        display: flex;
        @include sp {
          display: block;
        }
        .ant-tabs-extra-content {
          margin-top: 0;
        }
      }
    }
    .ant-tabs-nav {
      display: block;
      @include sp {
        padding: 0 !important;
      }
      .ant-tabs-extra-content {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include sp {
          display: block;
        }
        > p {
          margin: 0;
          display: flex;
          span {
            display: block;
            width: 24px;
            cursor: pointer;
            + span {
              margin-left: 24px;
            }
            svg {
              display: block;
              width: 100%;
              path {
                fill: #5c6d8a;
                transition: 0.3s ease-in-out;
              }
            }
            &.is-active {
              svg {
                path {
                  fill: #4759eb;
                }
              }
            }
          }
        }
      }
    }
  }
  &__sort {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include sp {
      margin-top: 16px;
    }
  }
  &__listHead {
    padding: 24px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    @include max(990px) {
      padding: 16px 24px;
    }
    p {
      width: calc(100% / 8);
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5c6d8a;
      padding: 0 4px;
      text-align: center;
      @include max(990px) {
        width: 100%;
        text-align: left;
        padding: 0;
      }
    }
  }
}

.p-launchpadDetail {
  background: url(../images/bg-detail.png) no-repeat top left;
  background-attachment: fixed;
  background-size: 100% 100%;
  padding-top: 64px;
  padding-bottom: 80px;
  position: relative;
  
  @include sp {
    padding-top: 60px;
  }
  &__banner {
    position: relative;
    z-index: 1;
    max-height: 670px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include max(1280px) {
      max-height: 420px;
    }
    @include sp {
      max-height: 300px;
    }
    img {
      display: block;
      width: 100%;
      position: relative;
      z-index: 1;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        178.75deg,
        rgba(71, 89, 235, 0) 42.97%,
        #4759eb 98.93%
      );
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
    }
    .c-btn {
      position: absolute;
      top: 24px;
      right: 78px;
      z-index: 3;
      @include max(1280px) {
        right: 24px;
      }
      @include sp {
        right: 16px;
        top: 16px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__logo {
    padding-left: 78px;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 108px;
    z-index: 3;
    @include max(1280px) {
      padding-left: 24px;
    }
    @include sp {
      display: none;
    }
    img {
      display: block;
      width: 50px;
      box-shadow: inset -2px 0px 0px #4759eb;
      border-radius: 5px;
      margin-bottom: 24px;
    }
  }
  &__detail {
    padding: 0 78px;
    margin-top: -75px;
    position: relative;
    z-index: 2;
    @include max(1280px) {
      padding: 0 24px;
    }
    @include sp {
      margin-top: 0;
      padding: 16px;
    }
  }
  &__content {
    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 34px;
        @include max(1280px) {
          padding: 0 !important;
        }
        @include sp {
          margin-bottom: 24px;
        }
        &:before {
          display: none;
        }
        .ant-tabs-tab {
          background: transparent;
          border-radius: 10px 10px 0px 0px;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          padding: 24px;
          transition: 0.3s ease-in-out;
          @include max(1100px) {
            padding: 10px 24px;
            height: 75px;
          }
          @include sp {
            height: auto;
            border-radius: 2px;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            padding: 8px;
          }
          + .ant-tabs-tab {
            @include sp {
              margin: 0 0 0 16px;
            }
          }
          .ant-tabs-tab-btn {
            color: #fff;
            @include max(1100px) {
              white-space: break-spaces;
              text-align: center;
            }
            @include sp {
              color: #5c6d8a;
              white-space: unset;
            }
          }
          &:hover {
            background: #5868e9;
          }
          &.ant-tabs-tab-active {
            background: #d9dffb;
            .ant-tabs-tab-btn {
              color: #4759eb;
            }
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }
    }
  }
  &__info {
    max-width: 500px;
    min-height: 700px;
    @include min(1440px) {
      width: calc(100% - 500px);
      max-width: 100%;
    }
    @include max(1280px) {
      width: calc(100% - 360px);
    }
    @include sp {
      width: 100%;
      max-width: 100%;
      min-height: auto;
    }
    > h3 {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 24px;
      @include max(1100px) {
        display: block;
      }
      @include sp {
        margin-bottom: 16px;
      }
      p {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #1d2129;
        margin: 0;
        padding: 0;
        display: flex;
        @include sp {
          font-size: 20px;
          line-height: 24px;
        }
        + p {
          @include max(1100px) {
            margin-top: 16px;
          }
          @include sp {
            margin-top: 8px;
          }
        }
        &:first-child {
          display: block;
          > span {
            display: block;
            margin-top: 4px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #5c6d8a;
            background: transparent;
            padding: 0;
          }
        }
        span {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1a7ec8;
          padding: 6px 10px;
          border-radius: 5px;
          background: #c2e5ff;
          &:nth-child(2) {
            background: #e8ffea;
            color: #00b42a;
          }
          &:nth-child(3) {
            background: #f2f3fc;
            color: #4759eb;
          }
          + span {
            margin-left: 8px;
          }
        }
      }
    }
    > ul {
      list-style: none;
      padding: 0;
      margin: 0 0 24px;
      display: flex;
      li {
        display: block;
        width: 24px;
        + li {
          margin-left: 32px;
        }
        a {
          display: block;
        }
      }
    }
  }
  &__boxInfo {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 5px;
    > h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #1d2129;
      padding: 24px 32px;
      margin: 0;
      border-bottom: 1px solid #f2f3f5;
      span {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #5c6d8a;
        margin-top: 4px;
      }
    }
  }
  &__chart {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 5px;
    margin-top: 16px;
    padding-bottom: 16px;
    > h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #1d2129;
      text-align: center;
      padding: 24px;
      border-bottom: 1px solid #f2f3f5;
    }
    canvas {
      padding: 10%;
      @include max(1440px) {
        padding: 0;
      }
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 14px 24px;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #5c6d8a;
    + .p-launchpadDetail__row {
      border-top: 1px solid #f2f3f5;
    }
    span {
      display: flex;
      text-align: right;
      font-size: 16px;
      line-height: 24px;
      color: #1d2129;
      &.is-network {
        img {
          display: block;
          width: 22px;
          margin-right: 5px;
        }
      }
      a {
        color: #1a7ec8;
      }
      button {
        display: inline-block;
        width: 24px;
        appearance: none;
        padding: 0;
        margin: 0 0 0 20px;
        border: 0;
        background: transparent;
        cursor: pointer;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
  &__txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c6d8a;
    margin: 0;
    @include sp {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__btn {
    padding: 32px;
    .c-btn {
      width: 100%;
      + .c-btn {
        margin-top: 16px;
      }
    }
  }
  &__radio {
    padding: 24px 32px;
    border-bottom: 1px solid #f2f3f5;
    @include max(1100px) {
      padding-bottom: 8px;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .ant-radio-wrapper {
        @include max(1100px) {
          margin-bottom: 16px;
        }
      }
    }
  }
  &__selectRadio {
    padding: 24px 32px 32px;
    &.affiliate-value { 
        display: flex !important;
        align-items: center;
        .c-btn{
          margin: 0;
          width: 20%;
        }
    }
    @include sp {
      padding: 24px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      li {
        display: block;
        width: calc(50% - 12px);
        + li {
          margin-left: 24px;
        }
      }
    }
    > .c-btn {
      width: 100%;
      margin-top: 24px;
      + .c-btn {
        margin-top: 16px;
      }
    }
    .c-input {
      margin-top: 16px;
    }
  }
  &__twoBtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    > .c-btn {
      width: calc(50% - 12px);
      height: auto;
      margin-top: 24px;
      + .c-btn {
        margin-left: 24px;
      }
    }
  }
  &__infoSC {
    // @include max(1100px) {
    //     position: relative;
    // }
    // @include sp {
    //     padding: 16px;
    // }
    position: absolute;
    top: 109px;
    right: 78px;
    z-index: 9;
    width: 350px;
    @include max(1280px) {
      right: 24px;
    }
    @include sp {
      position: unset;
      width: 100%;
    }
  }
  &__boxFixed {
    width: 100%;
    background: #5c6d8a;
    border-radius: 15px;
    z-index: 3;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    margin-top: 16px;
    &.createLauchpad {
      margin-bottom: 120px;
    }
    @include sp {
      width: 100%;
      position: unset;
      margin: 16px 0 48px;
    }
    p {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 10px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #94a7c6;
      + p {
        border-top: 1px solid #94a7c6;
      }
      span {
        padding-left: 8px;
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        text-align: right;
      }
    }
  }
  &__status {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 15px;
    padding-bottom: 24px;
    z-index: 3;
    transition: 0.3s ease-in-out;
    @include sp {
      width: 100%;
      position: unset;
    }
    &.is-normal {
      @include min(1101px) {
        position: fixed;
        top: 102px;
      }
    }
    &.is-Opening {
      h3 {
        background: #faac7b;
        color: #a23511;
      }
    }
    &.is-Completed {
      h3 {
        background: #4cd263;
        color: #004d1c;
      }
    }
    > h3 {
      background: #fadc19;
      border-radius: 15px;
      padding: 20px 32px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #887100;
      margin: 0;
      > span {
        display: block;
        margin-bottom: 16px;
      }
      .c-countDown {
        justify-content: space-between;
        letter-spacing: 0.2em;
        font-size: 20px;
        line-height: 20px;
        @include sp {
          justify-content: center;
        }
        span {
          color: #1d2129;
        }
      }
    }
    .c-progress {
      padding: 24px 16px 0;
      &__title {
        color: #1d2129;
        font-size: 16px;
        line-height: 24px;
      }
      &__txt {
        color: #6b7aef;
        margin-top: 8px;
      }
    }
    .c-input {
      padding: 16px 16px 0;
    }
    .c-txt {
      padding: 8px 16px 16px 0;
      text-align: right;
      color: #6b7aef;
    }
    .c-btn {
      margin: 0 auto;
    }
  }
  &__team {
    margin-top: 32px;
    > h3 {
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #1d2129;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4759eb;
        img {
          display: block;
          width: 14px;
          margin-left: 8px;
        }
      }
    }
    table {
      width: 100%;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
      th {
        width: 25%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #5c6d8a;
        border-bottom: 1px solid #d9dffb;
        padding: 16px 5px;
      }
      td {
        width: 25%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #1d2129;
        padding: 28px 5px;
      }
    }
  }
  &__btnStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    .c-btn {
      height: auto;
      + .c-btn {
        margin-left: 8px;
      }
    }
  }
}

.p-launchpadEdit {
  background: #f2f3fc;
  padding: 95px 32px 60px;
  min-height: 100vh;
  h3 {
    width: 100%;
    background: #d9dffb;
    border: 1px solid #d9dffb;
    border-radius: 5px;
    color: #5c6d8a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 16px;
    margin-bottom: 36px;
  }
  &__box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 64px;
    @include sp {
      padding: 0 24px;
    }
  }
  &__images {
    width: calc(50% - 18px);
    margin-left: 36px;
    order: 2;
    @include sp {
      width: 100%;
      margin-left: 0;
      margin-top: 36px;
    }
  }
  &__info {
    width: calc(50% - 18px);
    order: 1;
    @include sp {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .c-input {
      margin-bottom: 24px;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .c-btn {
      + .c-btn {
        margin-left: 24px;
      }
    }
  }
}

.p-vestingTeam {
  padding: 64px 0 0;
  @include sp {
    padding: 60px 0 0;
  }
  &__unlockTimer {
    background: #fff;
    padding: 32px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    h3 {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5c6d8a;
      text-align: center;
      margin-bottom: 12px;
    }
    p {
      width: 65px;
      height: 65px;
      border: 1px solid #5868e9;
      border-radius: 5px;
      color: #1d2129;
      font-weight: 400;
      font-size: 30px;
      line-height: 1;
      padding-bottom: 26px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0;
      + p {
        margin-left: 24px;
      }
      span {
        width: 100%;
        display: block;
        background: #6b7aef;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        left: 0;
        bottom: 6px;
      }
    }
  }
  &__box {
    padding: 32px 32px 50px;
    background: #f2f3fc;
    display: flex;
    flex-wrap: wrap;
    @include sp {
      padding: 22px 16px 50px;
      display: block;
    }
  }
  &__left {
    width: calc(50% - 18px);
    @include sp {
      width: 100%;
    }
  }
  &__right {
    width: calc(50% - 18px);
    margin-left: 36px;
    @include sp {
      width: 100%;
      margin-left: 0;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1d2129;
    margin-bottom: 16px;
    @include sp {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 8px;
    }
  }
  &__lock {
    padding: 0 24px;
    background: #f2f3fc;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 5px;
    @include sp {
      margin-bottom: 16px;
    }
    p {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #5868e9;
      margin: 0;
      padding: 16px 0;
      border-bottom: 1px solid #d9dffb;
      &:last-child {
        border-bottom: 0;
      }
      span {
        display: flex;
        padding-left: 20px;
        color: #3947b7;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
      }
    }
  }
  &__token {
    margin-bottom: 24px;
    @include sp {
      margin-bottom: 16px;
    }
    p {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(88, 104, 233, 1);
      background: #f2f3fc;
      box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
      border-radius: 2px;
      padding: 4px 8px;
      margin: 0;
      + p {
        margin-top: 8px;
      }
      img {
        display: block;
        width: 24px;
        margin-right: 12px;
      }
      span {
        display: flex;
        align-items: center;
        color: rgba(57, 71, 183, 1);
        margin-left: 6px;
      }
    }
  }
  &__vesting {
    table {
      width: 100%;
      background: #f2f3fc;
      border-radius: 2px;
      box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
      th {
        width: 40%;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #5868e9;
        padding: 16px 5px;
        &:first-child {
          width: 20%;
        }
      }
      td {
        width: 40%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #3947b7;
        padding: 16px 5px;
        border-top: 1px solid #d9dffb;
        &:first-child {
          width: 20%;
        }
      }
    }
  }
  &__btn {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .c-btn {
      @include sp {
        width: calc(50% - 8px);
      }
      &:nth-child(2) {
        order: 3;
        margin-left: 24px;
        @include sp {
          order: 2;
          margin-left: 16px;
        }
      }
      &:nth-child(3) {
        order: 2;
        margin-left: 24px;
        @include sp {
          order: 3;
          margin-left: 0;
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }
}

.p-myToken {
  padding: 64px 32px;
  background: url(../images/bg-main.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  @include sp {
    padding: 64px 16px;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0 16px;
    @include sp {
      flex-direction: column;
      align-items: flex-start;
    }
    h3 {
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      color: #1d2129;
      margin: 0;
      @include sp {
        margin-bottom: 8px;
      }
    }
    a {
      display: block;
      padding: 10px 16px;
      background: #4759eb;
      border-radius: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__box {
    background: rgba(242, 243, 252, 0.5);
    padding: 32px 24px;
    @include sp {
      padding: 24px 16px;
    }
    .c-search {
      max-width: 578px;
      margin-bottom: 24px;
    }
    .c-nodata {
      padding: 150px 32px;
    }
    .ant-table {
      table {
        border-collapse: collapse;
        th {
          padding: 16px 5px;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #5c6d8a;
          background: #ffffff;
          @include max(991px) {
            text-align: left;
          }
        }
        td {
          padding: 16px 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #1d2129;
          background: #ffffff;
          @include max(991px) {
            text-align: left;
            background: #f7f8fa;
          }
        }
      }

      .ant-table-row-level-0 {
        td {
          @include max(991px) {
            border: 0;
          }
          &.ant-table-row-expand-icon-cell {
            text-align: center;
            .ant-table-row-expand-icon {
              width: 25px;
              height: 25px;
              border: 1px solid #94a3b8;
              box-sizing: border-box;
              background: #f7f8fa;
              &:before {
                background: #94a3b8;
                top: 11px;
              }
              &:after {
                background: #94a3b8;
                left: 11px;
              }
              &.ant-table-row-expand-icon-expanded {
                border: 1px solid #1a7ec8;
                &:before {
                  background: #1a7ec8;
                }
                &:after {
                  background: #1a7ec8;
                }
              }
            }
          }
        }
      }
      .ant-table-expanded-row-level-1 {
        td {
          padding: 0;
        }
      }
    }
    .ant-pagination {
      text-align: right;
      .ant-pagination-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1d2129;
        border: 0;
        width: 36px;
        height: 36px;
        background: transparent;
        border-radius: 2px;
        transition: 0.3s ease-in-out;
        @include sp {
          width: 32px;
          height: 32px;
        }
        a {
          color: #1d2129;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          @include sp {
            width: 32px;
            height: 32px;
          }
        }
        &:hover {
          background: #d9dffb;
        }
        &.ant-pagination-item-active {
          background: #5868e9;
          a {
            color: #fff;
          }
        }
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        width: 36px;
        height: 36px;
        border: 0;
        @include sp {
          width: 32px;
          height: 32px;
        }
        .ant-pagination-item-link {
          border: 0;
          color: #5c6d8a;
          background: transparent;
        }
        &.ant-pagination-disabled {
          color: rgba(92, 109, 138, 1);
          opacity: 0.5;
        }
        &:hover {
          background: #d9dffb;
        }
      }
    }
  }
  &__tableSP {
    padding: 16px 53px;
    p {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #1d2129;
      margin-bottom: 0;
      + p {
        margin-top: 8px;
      }
      span {
        display: block;
        margin-left: 16px;
        font-weight: 400;
        a {
          color: rgba(26, 126, 200, 1);
        }
      }
    }
  }
  .c-pagingEdit {
    margin-top: 36px;
  }
}

.p-tokenCreateLock {
  padding: 95px 32px 45px;
  background: #f2f3fc;

  &.form {
    padding: 0px;
    .c-input {
      margin-bottom: 24px;
    }
  }

  &.formlock {
    padding-top: 12px !important;
  }
  h3 {
    background: #d9dffb;
    border: 1px solid #d9dffb;
    border-radius: 5px;
    color: #5c6d8a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 16px;
    margin: 0;
  }
  &__box {
    margin-top: 12px;
    padding: 0 90px;
    display: flex;
    @include max(1280px) {
      padding: 0;
    }
    @include sp {
      display: block;
    }
    .c-input {
      margin-top: 24px;
    }
  }
  &__left {
    width: calc(50% - 16px);
    @include sp {
      width: 100%;
    }
  }
  &__right {
    width: calc(50% - 16px);
    margin-left: 32px;
    &.antibot_form {
      margin-top: 24px;
      .mb-10 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1d2129;
        margin: 0 0 8px;
      }
      .c-input {
        margin-top: 0px;
      }
    }
    @include sp {
      width: 100%;
      margin-left: 0;
    }
    .c-ttl {
      margin-top: 24px;
    }
    .ant-checkbox-wrapper {
      margin-top: 24px;
      font-size: 16px;
      line-height: 24px;
      color: #1d2129;
      font-weight: 400;
      display: flex;
      align-items: center;
      span {
        display: block;
      }
      .ant-checkbox {
        width: 24px;
        height: 24px;
        top: 0;
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #4759eb;
            border-color: #4759eb;
          }
        }
        .ant-checkbox-inner {
          width: 24px;
          height: 24px;
          &:after {
            left: 32.5%;
          }
        }
      }
    }
  }
  &__footer {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    p {
      padding: 9px 40px;
      margin: 0 0 40px;
      background: #e8f3ff;
      border-radius: 2px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5c6d8a;
      position: relative;
      a {
        color: rgba(26, 126, 200, 1);
      }
      span {
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        &:nth-child(1) {
          left: 16px;
        }
        &:nth-child(2) {
          right: 16px;
          cursor: pointer;
          padding: 2px;
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  &__btn {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    .c-btn {
      + .c-btn {
        margin-left: 24px;
      }
    }
  }

  &.is-create {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    z-index: 999;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    padding: 0 0 0 240px;
    @include max(991px) {
      padding: 0;
    }
  }
  &__body {
    background: #f2f3fc;
    height: 100vh;
  }
}

.p-myLaunchpad {
  background: url(../images/bg-main.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  padding: 96px 32px 0;
  min-height: 100vh;
  @include sp {
    padding: 76px 16px 0;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 16px;
    @include sp {
      flex-direction: column;
      align-items: flex-start;
    }
    h3 {
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      color: #1d2129;
      margin: 0;
      @include sp {
        margin-bottom: 8px;
      }
    }
    a {
      display: block;
      padding: 10px 16px;
      background: #4759eb;
      border-radius: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__box {
    background: rgba(242, 243, 252, 0.5);
    border-radius: 5px;
    padding: 40px 24px;
    min-height: calc(100vh - 156px);
    @include sp {
      min-height: calc(100vh - 172px);
      padding: 40px 16px;
    }
    .ant-tabs-nav {
      padding: 0 !important;
      display: block;
      .ant-tabs-extra-content {
        display: flex;
        justify-content: flex-end;
      }
    }
    .p-launchpadList__sort {
      @include sp {
        margin-top: 0;
      }
      .c-sortBy,
      .is-ml24 {
        @include sp {
          margin-left: 8px;
        }
      }
    }
  }
}

.p-createToken {
  background: #f2f3fc;
  min-height: 100vh;
  padding: 95px 32px;
  @include sp {
    padding: 90px 16px;
  }
  h3 {
    width: 100%;
    background: #d9dffb;
    border: 1px solid #d9dffb;
    border-radius: 5px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5c6d8a;
    padding: 16px;
    span {
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: #1a7ec8;
      margin-top: 8px;
    }
  }
  &__box {
    width: 90%;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
    .ant-checkbox-wrapper {
      span {
        color: #1a7ec8;
      }
    }
    .note {
      display: block;
      width: calc(50% - 16px);
      @include sp {
        width: 100%;
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .c-btn {
      width: 170px;
      + .c-btn {
        margin-left: 24px;
      }
      @include sp {
        width: calc(50% - 12px);
      }
    }
  }
  .form-createToken-main {
    height: auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-createToken-item {
      width: calc(50% - 16px);
      @include sp {
        width: 100%;
        order: 4;
      }
      &:nth-child(1) {
        @include sp {
          order: 1;
        }
      }
      &:nth-child(2) {
        @include sp {
          order: 3;
        }
      }
      &:nth-child(3) {
        @include sp {
          order: 2;
        }
      }
    }
  }
}

.p-lockRecords {
  padding: 64px 0;
  background: url(../images/bg-lockRecord.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  transition: 0.3s ease-in-out;
  .c-breadcrumb {
    background: #f2f3fc;
    width: 100%;
  }
  &__box {
    padding: 32px 36px;
    display: flex;
    align-items: flex-start;
    @include max(1280px) {
      display: block;
    }
    @include sp {
      padding: 32px 16px;
    }
  }
  &__info {
    width: calc(50% - 16px);
    margin-right: 32px;
    @include max(1440px) {
      width: calc(45% - 16px);
    }
    @include max(1280px) {
      width: 100%;
    }
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1d2129;
      margin-bottom: 16px;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        background: #f2f3fc;
        box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
        border-radius: 2px;
        padding: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5868e9;
        + li {
          margin-top: 8px;
        }
        img {
          display: block;
          width: 24px;
          margin-right: 12px;
        }
        span {
          display: flex;
          align-items: center;
          color: #3947b7;
          margin-left: 6px;
          font-size: 16px;
          .is-network {
            margin-left: 0;
            img {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .c-lockRecords {
    width: calc(50% - 16px);
    @include max(1440px) {
      width: calc(55% - 16px);
    }
    @include max(1280px) {
      width: 100%;
      margin-top: 32px;
    }
  }
}

.p-antiBotDetail {
  padding-top: 64px;
  background: #f2f3fc;
  min-height: 100vh;
  &__head {
    background: #fff;
    padding: 32px;
    text-align: center;
    @include sp {
      padding: 24px 16px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #5c6d8a;
      margin-bottom: 16px;
    }
    .c-btn {
      @include sp {
        width: 100%;
      }
      + .c-btn {
        margin-left: 24px;
        @include sp {
          margin-top: 16px;
          margin-left: 0;
        }
      }
    }
  }
  &__body {
    padding: 32px;
    display: flex;
    @include sp {
      padding: 24px 16px;
      display: block;
    }
  }
  &__left {
    width: calc(50% - 16px);
    @include sp {
      width: 100%;
    }
  }
  &__right {
    width: calc(50% - 16px);
    margin-left: 32px;
    @include sp {
      width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
  }
  &__box {
    background: #f2f3fc;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 5.47064px;
    padding: 0 32px 32px 32px;
    display: flex;
    flex-wrap: wrap;
    @include sp {
      padding: 0 16px 16px 16px;
    }
    + .p-antiBotDetail__box {
      margin-top: 24px;
    }
    h3 {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #1d2129;
      margin: 0;
      padding: 24px;
      position: relative;
      @include sp {
        font-size: 16px;
        line-height: 24px;
        padding: 16px;
      }
      &:after {
        content: "";
        width: calc(100% + 64px);
        height: 1px;
        background: #d9dffb;
        position: absolute;
        bottom: 0;
        left: -32px;
      }
    }
    p {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #5c6d8a;
      margin: 24px 0 16px;
      @include sp {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .c-btn {
      width: calc(50% - 8px);
      @include max(1100px) {
        width: 100%;
      }
      + .c-btn {
        margin-left: 16px;
        @include max(1100px) {
          margin-left: 0;
          margin-top: 16px;
        }
      }
      &:last-child {
        width: 100%;
        margin-top: 16px;
        margin-left: 0;
      }
    }
  }
  &__info {
    + .p-antiBotDetail__info {
      margin-top: 24px;
    }
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1d2129;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include sp {
        font-size: 18px;
        line-height: 27px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        background: #f2f3fc;
        box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
        border-radius: 2px;
        padding: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5868e9;
        + li {
          margin-top: 8px;
        }
        img {
          display: block;
          width: 24px;
          margin-right: 12px;
        }
        span {
          display: flex;
          align-items: center;
          color: #3947b7;
          margin-left: 8px;
          font-size: 16px;
          &.is-network {
            img {
              margin-right: 5px;
              width: 22px;
            }
          }
        }
      }
    }
  }
  &__table {
    width: 100%;
    background: #f2f3fc;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 5px;
    padding: 0 24px;
    p {
      margin: 0;
      padding: 16px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5868e9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3947b7;
        text-align: right;
      }
      &:first-child {
        span {
          color: #1a7ec8;
        }
      }
      + p {
        border-top: 1px solid #d9dffb;
      }
    }
  }
}

.p-antiBotEdit {
  padding: 95px 32px;
  background: #f2f3fc;
  min-height: 100vh;
  @include sp {
    padding: 85px 16px 110px;
  }
  h3 {
    background: #d9dffb;
    border: 1px solid #d9dffb;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5c6d8a;
    padding: 16px;
  }
  &__box {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 40px;
    @include max(1024px) {
      width: 100%;
    }
    @include sp {
      padding-bottom: 8px;
      display: block;
    }
  }
  &__row {
    width: calc(50% - 32px);
    margin: 24px 16px 0;
    @include sp {
      width: 100%;
      margin: 16px 0 0;
    }
    .c-selectEdit {
      width: 100%;
    }
    .ant-switch {
      background: #6f7c8e;
      &-handle {
        width: 16px;
        height: 16px;
        top: 3px;
        left: 3px;
      }
      &-checked {
        background: #4759eb;
        .ant-switch-handle {
          left: calc(100% - 16px - 3px);
        }
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .is-switch {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #5c6d8a;
      margin-left: 10px;
      transform: translateY(2px);
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp {
      background: #f2f3fc;
      box-shadow: 8px -4px 20px rgba(71, 89, 235, 0.5);
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      padding: 16px 16px 24px;
    }
    .c-btn {
      @include sp {
        width: calc(50% - 8px);
      }
      + .c-btn {
        margin-left: 24px;
        @include sp {
          margin-left: 16px;
        }
      }
    }
  }
  .c-ttl {
    > span {
      display: inline-block;
      margin-left: 6px;
      transform: translateY(-5px);
      cursor: pointer;
    }
  }
}

.ant-popover {
  width: 280px;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  &-inner-content {
    background: #ffffff;
    border-radius: 4px;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #94a3b8;
      margin: 0;
    }
  }
}

.mt-16 {
  margin-top: 16px;
}

.p-blsDividends {
  background: url(../images/bg-main.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  padding: 94px 32px 50px;
  min-height: 100vh;
  @include sp {
    padding: 76px 16px 30px;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
      align-items: baseline;
    }
    h2 {
      padding: 0;
      margin: 0;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #1d2129;
      padding-right: 10px;
      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 2px;
      }
    }
    p {
      padding: 10px 16px;
      background: rgba(255, 255, 255, 0.61);
      border: 1px solid #5c6d8a;
      border-radius: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4759eb;
      margin: 0;
      white-space: nowrap;
      @include sp {
        margin-top: 10px;
      }
    }
  }
  &__content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }
    .is-box {
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
      border-radius: 10px;
      background: linear-gradient(
          157.09deg,
          #ffffff 0.05%,
          rgba(71, 89, 235, 0.2) 48.84%,
          rgba(192, 211, 237, 0.4) 98.34%
        ),
        linear-gradient(0deg, #ffffff, #ffffff);
      position: relative;
      &:after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: #fff;
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 10px;
        z-index: 0;
      }
    }
  }
  &__pool {
    margin-right: 32px;
    width: calc(100% - 382px);
    @include sp {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    ul {
      padding: 0;
      margin: 0 22px;
      list-style: none;
      position: relative;
      z-index: 1;
      @include sp {
        margin: 0;
        padding: 0 16px;
      }
      li {
        padding: 25px 0;
        display: flex;
        flex-wrap: wrap;
        @include max(1200px) {
          justify-content: space-between;
        }
        @include max(1100px) {
          display: block;
          padding: 20px 0;
        }
        + li {
          border-top: 1px dashed #d9dffb;
        }
        > h3 {
          width: 100%;
          min-height: 44px;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #1d2129;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include max(1100px) {
            margin-bottom: 8px;
          }
          button {
            appearance: none;
            border-radius: 2px;
            padding: 10px 16px;
            color: #1d2129;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            border: 0;
            background: transparent;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              display: block;
              width: 24px;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  &__info {
    width: 350px;
    @include sp {
      width: 100%;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding: 20px 20px 0;
      position: relative;
      z-index: 1;
      @include sp {
        padding: 20px 16px 0;
      }
      li {
        padding-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        + li {
          border-top: 1px dashed #d9dffb;
          padding-top: 24px;
        }
        &:first-child {
          > h3 {
            margin-bottom: 8px;
          }
        }
        > h3 {
          width: 100%;
          min-height: 44px;
          margin: 0 0 16px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #1d2129;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          p {
            padding: 0;
            margin: 0;
            display: flex;
            .c-btn {
              width: 44px;
              + .c-btn {
                margin-left: 10px;
              }
            }
          }
        }
        > p {
          width: 50%;
          margin: 8px 0 0;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #1d2129;
          @include sp {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          span {
            display: block;
            color: #5c6d8a;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            &:after {
              content: ":";
              color: #5c6d8a;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              display: none;
              margin-left: 4px;
              @include sp {
                display: inline-block;
              }
            }
          }
          strong {
            color: #20a1ff;
          }
        }
        .c-poolItem {
          width: 100%;
          + .c-poolItem {
            margin-top: 13px;
            margin-left: 0;
          }
          &__img {
            width: 32px;
          }
          &__conts {
            width: calc(100% - 120px);
            @include sp {
              width: calc(100% - 104px);
            }
          }
        }
        .c-btn {
          > span {
            > span {
              margin: 0;
            }
          }
        }
        .is-notConnect {
          appearance: none;
          width: 100%;
          background: #5c6d8a;
          opacity: 0.5;
          border-radius: 2px;
          padding: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          border: 0;
        }
        .is-notChain {
          appearance: none;
          width: 100%;
          border-radius: 2px;
          padding: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          border: 0;
        }
      }
    }
  }
  &__footer {
    color: #94a7c6;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-top: 50px;
    display: none;
    @include sp {
      display: block;
    }
  }
  &__version {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    .c-btn {
      display: block;
    }
  }
  .c-statistic {
    margin: 20px 0 32px;
    padding: 20px 50px;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 10px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    @include max(1360px) {
      padding: 20px;
      flex-wrap: wrap;
    }
    @include sp {
      padding: 20px;
      margin: 30px 0 16px;
      display: block;
    }
    li {
      display: flex;
      align-items: center;
      width: 25%;
      @include max(1100px) {
        width: 50%;
        margin: 5px 0;
      }
      @include sp {
        width: 100%;
        display: block;
      }
      + li {
        @include sp {
          margin-top: 10px;
        }
      }
      &.is-mt8 {
        @include max(1100px) {
          margin-top: 10px;
        }
      }
    }
    &__img {
      width: 40px;
      @include sp {
        display: none;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #1d2129;
      margin: 0;
      padding-left: 16px;
      @include sp {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 20px;
        position: relative;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../images/after-dot.svg) repeat-x bottom center;
        color: #5c6d8a;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        display: none;
        @include sp {
          display: block;
        }
      }
      span {
        display: block;
        margin-bottom: 4px;
        color: #5c6d8a;
        font-size: 14px;
        line-height: 20px;
        @include sp {
          margin-bottom: 0;
          position: relative;
          z-index: 1;
          background: #fff;
          padding-right: 5px;
          line-height: 16px;
        }
      }
      strong {
        font-weight: 500;
        @include sp {
          position: relative;
          z-index: 1;
          background: #fff;
          padding-left: 5px;
        }
      }
    }
  }

  .c-poolItem {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    + .c-poolItem {
      margin-left: 120px;
      @include max(1360px) {
        margin-left: 80px;
      }
      @include max(1200px) {
        margin-left: 0;
      }
      @include max(1100px) {
        margin-left: 0;
        margin-top: 16px;
      }
    }
    &.is-noImg {
      display: block;
      @include sp {
        display: flex;
        justify-content: space-between;
      }
      + .is-noImg {
        @include sp {
          margin-top: 8px;
        }
      }
    }
    &__img {
      width: 32px;
      overflow: hidden;
      margin-right: 8px;
      border-radius: 50%;
      background: #fff;
      img {
        display: block;
        width: 100%;
      }
    }
    p {
      padding: 0;
      margin: 0 0 2px;
      color: #5c6d8a;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    h4 {
      padding: 0;
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #1d2129;
      span {
        color: rgba(152, 170, 192, 1);
        font-weight: normal;
      }
    }
  }
}

.blur {
  filter: blur(10px);
}

.p-blsDashboard {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 22px;
  position: relative;

  &-wrap {
    background: linear-gradient(
      157.09deg,
      #ffffff 0.05%,
      rgba(71, 89, 235, 0.2) 48.84%,
      rgba(192, 211, 237, 0.4) 98.34%
    );
    padding: 1px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    height: fit-content;
    margin-left: 30px;

    @include sp {
      margin-left: 0;
      margin-bottom: 16px;
    }
    &:first-child {
      margin-left: 0px;
    }
  }
  &-dividends {
    display: flex;
    margin-top: 20px;
    @include sp {
      display: block;
      margin-top: 0;
    }
  }
  .blur-coming {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 24px;
    background: linear-gradient(180deg, #bad5fe 0%, #66a0f1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  &-subtext {
    display: block;
    margin-bottom: 4px;
    color: #5c6d8a;
    font-size: 14px;
    line-height: 20px;
  }
  &-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  &-allocation {
    color: #5c6d8a;
    .value {
      &-myallocation {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: block;
      }
      &-allocation {
        color: #1d2129;
        font-weight: 500;
        font-size: 18px;
        margin-top: 5px;
        display: block;
      }
    }
  }
  &-manage {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      display: block;
      margin-left: 7px;
    }
  }
  &-vesting {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
  }
  &-redeem {
    display: flex;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
    img {
      margin-right: 10px;
      height: 18px;
      width: 18px;
    }
  }
  &-header {
    display: flex;
    background-color: #f2f3fc;
    width: 100%;
    @include sp {
      display: block;
      padding: 16px;
    }
    .ant-table-wrapper {
      width: 100%;
    }
    &-item {
      width: 25%;
      padding: 10px;
      @include sp {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: space-between;
        position: relative;
      }
      &:after {
        content: "";
        width: 100%;
        height: calc(100% - 3px);
        background: url(../images/after-dot.svg) repeat-x bottom center;
        color: #5c6d8a;
        position: absolute;
        bottom: 6px;
        left: 0;
        z-index: 0;
        display: none;
        @include sp {
          display: block;
        }
      }
      + .p-blsDashboard-header-item {
        @include sp {
          margin-top: 8px;
        }
      }
    }
    &-text {
      font-weight: 400;
      font-size: 14px;
      color: #5c6d8a;
      display: block;
      @include sp {
        background: #f2f3fc;
        padding-right: 5px;
        position: relative;
        z-index: 1;
        line-height: 20px;
      }
    }
    &-time {
      @include sp {
        background: #f2f3fc;
        padding-left: 5px;
        position: relative;
        z-index: 1;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &-subheader {
    display: flex;
    padding-left: 10px;
    border-bottom: 1px solid #d9dffb;
    align-items: center;
    cursor: pointer;
    .maintext {
      width: calc(100% / 4);
      color: #5868e9;
      margin: 15px 0;
    }
    .text {
      width: calc(100% / 4);
      margin: 15px 0;
      color: #3947b7;
      font-weight: 400;
      font-size: 16px;
    }
  }
  &-cancel {
    align-items: center;
    display: flex;
    background: #d9dffb;
    color: #394df5;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    img {
      margin-right: 5px;
    }
    span {
      display: block;
    }
  }
}
.c-input-form {
  display: flex;
  margin-bottom: 5px;
  .c-input__max {
    width: 80%;
    @include sp {
      width: calc(100% - 70px);
    }
  }
  .c-btn__max {
    padding: 10px;
    background-color: #d9dffb;
    margin-left: 20px;
    height: 44px;
    cursor: pointer;
    border: none;
    @include sp {
      width: 50px;
    }
    span {
      color: #394df5;
    }
  }
}
.c-balance {
  color: #5c6d8a;
  font-weight: 400;
  font-size: 13px;
}
.c-receive {
  display: flex;
  justify-content: space-between;
  &-text {
    color: #5c6d8a;
    font-weight: 400;
    font-size: 13px;
  }
  &-token {
    font-weight: 700;
    font-size: 14px;
  }
}
.c-warning {
  padding: 10px;
  background-color: #ffece8;
}
.c-btn-approved {
  margin-top: 25px;
  padding: 10px;
  width: 100%;
  background-color: #5c6d8a;
  color: #fff;
  text-align: center;
  cursor: pointer;
  height: auto;
}
.c-btn-convert {
  margin-top: 25px;
  padding: 10px;
  width: 100%;
  background-color: #4759eb;
  color: #fff;
  text-align: center;
  cursor: pointer;
  height: auto;
}

.flex-sp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.mb-0{
  margin-bottom: 0px !important;
}
.w-70 {
  width: 70%;
  @include sp {
    width: 100%;
  }
}
.w-30 {
  width: 30%;
  @include sp {
    width: 100%;
  }
}
.w-100 {
  width: 100%;
}

.your-rewards{
  display: flex;
  align-items: center;
  justify-content: space-between;
  button{
    margin-right: 20px;
  }
}

.button-100{
  button{
    width: 100% !important;
  }
}

.verifyToken-right-text.w-100 .ant-form-item {
  width: 100%; 
}



.count-tab {
  display: flex;
  .discrease,
  .increase {
    padding: 10px 18px;
    border: 1px solid #1d2129;
  }
  .count {
    text-align: center;
    margin: 0 15px;
    display: flex;
    flex-direction: row-reverse;
    &-text {
      display: flex;
      color: #5c6d8a;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      padding-left: 5px;
    }
    &-inp-lock-day {
      box-shadow: inset 0px 4px 4px #c2cdfc;
      border: none;
      width: 60px;
      padding-left: 10px;
    }
    &-count {
      display: block;
      color: #1d2129;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.redeem-duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .c-ttl {
    color: #5c6d8a;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .max-lock-day {
    cursor: pointer;
  }
  p {
    color: #1a7ec8;
    font-weight: 500;
    font-size: 14px;
  }
}
.info {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  &-text {
    color: #5c6d8a;
    font-weight: 400;
    font-size: 12px;
  }
  &-number {
    font-weight: 700;
    font-size: 14px;
    color: #1d2129;
  }
}

.p-multiSender {
  background: url(../images/bg-main.png) no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  padding: 96px 32px 85px;
  min-height: 100vh;
  @include sp {
    padding: 76px 16px 50px;
  }
  &__head {
    list-style: none;
    margin: 0 0 32px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    &::before {
      content: "";
      width: calc(100% - 632px);
      height: 2px;
      background: #c9cdd4;
      position: absolute;
      top: calc(50% - 1px);
      left: 316px;
    }
    li {
      background: linear-gradient(
        157.09deg,
        #ffffff 0.05%,
        rgba(71, 89, 235, 0.2) 48.84%,
        rgba(192, 211, 237, 0.4) 98.34%
      );
      width: 300px;
      padding: 18px;
      position: relative;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
      border-radius: 10px;
      display: flex;
      &::before {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 10px;
        z-index: 0;
      }
      span {
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #5c6d8a;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
        color: #5c6d8a;
        text-align: center;
        position: relative;
        z-index: 1;
      }
      h3 {
        width: calc(100% - 44px);
        margin: 0 0 0 8px;
        position: relative;
        z-index: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        p {
          margin: 4px 0 0;
          color: #5c6d8a;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &.is-active {
        span {
          background: #4759eb;
          color: #fff;
          border: 1px solid #4759eb;
        }
      }
    }
  }
  &__boxContent {
    padding: 24px;
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 10px;
    background: #ffffff;
    > .c-radioEdit {
      margin-top: 8px;
      .ant-radio-wrapper {
        margin-right: 24px;
      }
    }
  }
  &__note {
    margin: 4px 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    &.is-blue {
      color: #20a1ff;
    }
    &.is-red {
      color: #f76560;
    }
    &.is-bg {
      word-wrap: break-word;
      padding: 17px 16px;
      color: #1d2129;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      background: #fff7e8;
      border-radius: 2px;
    }
  }
  &__sampleFile {
    display: flex;
    margin: 16px 0;
    align-items: center;
    .c-btn {
      + .c-btn {
        margin-left: 10px;
      }
    }
  }
  &__btn {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .c-btn {
      + .c-btn {
        margin-left: 10px;
      }
    }
  }
  &__approve {
    margin-bottom: 16px;
    .c-radioEdit {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      label {
        color: #5c6d8a;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
        + label {
          margin-top: 16px;
        }
      }
    }
  }
  &__footer {
    margin: 32px auto 0;
    color: #1d2129;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    max-width: 930px;
  }
  &__infoToken {
    margin: 0;
    color: #5c6d8a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    padding: 8px 0 11px;
    border-bottom: 1px solid #f2f3f5;
    &.is-mt16 {
      margin-top: 16px;
    }
    span {
      display: block;
      color: #1d2129;
      font-weight: 700;
      text-align: right;
      &.is-blue {
        color: #20a1ff;
      }
      &.is-blue2 {
        color: #4759eb;
      }
    }
  }
  &__infoAddress {
    padding: 16px 20px;
    color: #3947b7;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9dffb;
    margin: 0;
    &.is-blue {
      color: #5868e9;
      span {
        color: #5868e9;
      }
    }
    > span {
      box-sizing: border-box;
      display: block;
      width: 30%;
      color: #1d2129;
      padding-left: 20px;
      > span {
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;
        padding: 5px 15px;
        &.is-Success {
          color: #00b42a;
          background: #e8ffea;
        }
                
      }
    }
  }
  .c-ttl {
    &.is-mt16 {
      margin-top: 16px;
    }
    small {
      color: rgba(247, 101, 96, 1);
    }
  }
  .c-input {
    position: relative;
    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px 16px;
      color: #94a7c6;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.p-comingSoon {
  padding: 96px 32px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__box {
    text-align: center;
    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: #1d2129;
      margin: 0 0 8px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #1d2129;
      margin: 0 0 10px;
    }
    img {
      width: 100%;
      max-width: 300px;
    }
  }
}
