input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
}
.ant-spin-dot-item {
  background-color: #fff !important;
}
.p-launchpadDetail__selectRadio.pri .ant-spin-dot-item {
  background-color: #4759eb !important;
}

.switch-network {
  padding-top: 25px;
  display: flex;
  justify-content: center;
}
.no-connect {
  padding-top: 25px;
  display: flex;
  justify-content: center;
}
.not-connect {
  padding-top: 25px;
  display: flex;
  justify-content: center;
}
.switch-network :hover {
  cursor: pointer;
}
.btn-not-connect {
  border-radius: 2px;
  border: none;
  height: 44px;
  background: #4759eb;
  color: #fff;
  font-size: 16px;
  padding: 10px 15px;
}

.btn-switch-network {
  border-radius: 2px;
  border: none;
  height: 44px;
  background: #4759eb;
  color: #fff;
  font-size: 16px;
  padding: 10px 15px;
}

.ant-spin-spinning {
  @include sp {
    padding-right: 8px;
  }
}
.ant-col-16 {
  max-width: 100% !important;
}

.ant-col-offset-8 {
  margin-left: 0px;
}

.ant-form-item-control-input {
  min-height: 44px !important;
}

.ant-radio-group .ant-radio-wrapper {
  margin-right: 10px;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}
.ant-tabs-nav {
  padding: 0px 30px !important;
}

.ant-picker-range {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #d9dffb;
  box-shadow: inset 0px 4px 4px #c2cdfc;
  border-radius: 2px;
}
.ant-picker {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #d9dffb;
  box-shadow: inset 0px 4px 4px #c2cdfc;
  border-radius: 2px;
}
.token {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 25px;
  position: relative;
  .p-createToken {
    min-height: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }
}

.c-createLaunchpad {
  background: #f2f3fc;
  background-attachment: fixed;
  background-size: 100% 100%;
  padding-top: 64px;
  padding-bottom: 80px;
  position: relative;
  min-height: 100vh;
  .p-launchpadDetail {
    background: none;
  }
  .ant-tabs-tab-active {
    .tab-header-circle {
      background-color: #4759eb;
      color: #ffffff;
    }
  }
  .ant-tabs-ink-bar {
    background-color: #4759eb;
  }
  .tab-header {
    display: flex;
    align-items: center;
    &-circle {
      width: 28px;
      height: 28px;
      background-color: #d9dffb;
      border-radius: 99px;
      position: relative;
    }
    &-num {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &-text {
      font-size: 18px;
      color: #1d2129;
      margin-left: 15px;
    }
  }
  .tabItem {
    display: flex;
    align-items: center;
    margin-right: 15px;
    &-num {
      background-color: #4759eb;
      border-radius: 99px;
      font-weight: 600;
      font-size: 16px;
      width: 30px;
      height: 30px;
      color: #fff;
      margin-right: 10px;
      justify-content: center;
      display: flex;
      padding-top: 2px;
    }
    &-text {
      font-weight: 500;
      font-size: 18px;
    }
  }
  .step {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 30px;
    @include sp {
      padding-top: 34px;
      padding-bottom: 16px;
    }
  }
  .step-text {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    padding: 0 215px;

    @include max(1024px) {
      padding: 0px;
    }
    @include sp {
      font-size: 20px;
      line-height: 24px;
      padding: 0px;
    }
    &-antibot {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5c6d8a;
      }
    }
  }
}
.token-select {
  display: flex;
  margin-top: 50px;
  @include sp {
    flex-direction: column;
    margin-top: 24px;
  }
  .form {
    &-availabletoken {
      &.alone {
        margin-right: 0;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      background-color: #d9dffb;
      margin-right: 40px;
      border-radius: 5px;
      img {
        display: block;
      }
      @include sp {
        margin-right: 0px;
        margin-bottom: 10px;
      }

      .title {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #1d2129;
        padding-top: 40px;
        padding-bottom: 8px;
        @include sp {
          padding-top: 24px;
          padding-bottom: 8px;
        }
      }
      .subtitle {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6b7aef;
        padding-bottom: 36px;
      }
      .input {
        padding: 10px;
        width: 430px;
        @include max(1680px) {
          width: 320px;
        }
      }
    }
    &-createtoken {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      background-color: #5868e9;
      justify-content: space-between;
      border-radius: 5px;
      .title {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        padding-top: 40px;
        padding-bottom: 8px;
        @include sp {
          padding-top: 24px;
          padding-bottom: 8px;
        }
      }
      .subtitle {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #f2f3fc;
        &-fee {
          color: #f2f3fc;
          padding-bottom: 15px;
        }
      }
      .btn-create {
        width: 430px;
        background-color: #d9dffb;
        color: #394df5;
        padding: 10px;
        border: 1px solid transparent;
        cursor: pointer;
        @include max(1680px) {
          width: 320px;
        }
      }
    }
  }
}
.btn-token {
  display: flex;
  justify-content: center;
  @include sp {
    position: fixed;
    bottom: 0px;
  }
  &.cancel-next {
    margin-top: 30px;
  }
  &.create-token {
    margin-top: 30px;
    .c-btn {
      width: 170px;
      margin-right: 30px;
    }
  }
  .cancel {
    padding: 10px;
    background-color: transparent;
    border: 1px solid #5c6d8a;
    color: #5c6d8a;
    border-radius: 2px;
    width: 170px;
    margin-right: 30px;
    border-radius: 2px;
    cursor: pointer;
  }
  .next {
    padding: 10px;
    background-color: #4759eb;
    border: 1px solid #4759eb;
    color: #fff;
    border-radius: 2px;
    width: 170px;
    border-radius: 2px;
    cursor: pointer;
  }
}
.header-createToken {
  .createToken {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #1d2129;
  }
  .fee {
    text-align: center;
    color: #5c6d8a;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.form-createToken {
  position: absolute;
  background: #f2f3fc;
  box-shadow: 0px 0px 40px rgba(71, 89, 235, 0.15);
  border-radius: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  z-index: 1000;
  width: 510px;

  &-main {
    height: 450px;
    overflow-y: auto;
    padding-right: 10px;
  }
  &-text {
    margin-bottom: 5px;
    display: block;
  }
  &-bg {
    background-color: #f2f3fc;
    position: fixed;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
  }
  &-item {
    width: 100%;
  }
}

.inputForm {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #d9dffb;
  box-shadow: inset 0px 4px 4px #c2cdfc;
  border-radius: 2px;
  &:hover {
    background-color: #f2f3fc;
    border: 1px solid transparent;
  }
  &:focus {
    border: 1px solid #4759eb;
    background-color: #fff;
  }
}
.ant-select-selector {
  width: 450px;
  height: 44px !important;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #d9dffb !important;
  box-shadow: inset 0px 4px 4px #c2cdfc;
  border-radius: 2px;
}

.tooltip {
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
  .tooltiptext {
    visibility: hidden;
    background-color: #ffffff;
    color: #94a3b8;
    text-align: center;
    border-radius: 6px;
    padding: 14px 12px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    @include sp {
      left: 0;
      top: 25px;
    }
  }
}

.verifyToken {
  &-form {
    display: flex;
    justify-content: space-between;
    padding: 0px 80px;
    margin-top: 20px;
    @include sp {
      padding: 0px 15px;
      flex-direction: column;
    }
    .c-social__row {
      span {
        margin-bottom: 24px;
      }
    }
  }
  .step {
    padding: 15px;
    background: #d9dffb;
    border: 1px solid #d9dffb;
    border-radius: 5px;
    margin: 0px 30px;
    @include sp {
      margin: 0px 15px;
    }
  }
  &-left {
    width: 50%;
    margin-right: 40px;

    @include sp {
      width: 100%;
    }
    &-item {
      margin-bottom: 25px;
      .switchText {
        margin-left: 8px;
      }
      @include sp {
        margin-bottom: 15px;
      }
    }
    .input {
      width: 100%;
      border-radius: 2px;
      background: #ffffff;
      border: 1px solid #d9dffb;
      box-shadow: inset 0px 4px 4px #c2cdfc;
      border-radius: 2px;
      height: 44px;
    }
  }
  &.affiliate-row{
      border-top: 1px solid #f2f3f5 !important;
      border-bottom: 1px solid #f2f3f5 !important;
  }
  .swaprate {
    color: #1a7ec8;
    text-align: end;
    margin-bottom: 0px;
    margin-top: -20px; 
    &.assume{ 
      display: block;
      font-size: 14px;
      text-align: right;
      margin-right: 20px;
      margin-top: 0px;
    }
  }
  &-right {
    width: 50%;
    &-text {
      display: flex;
    }
    @include sp {
      width: 100%;
    }
    .currency {
      margin-bottom: 35px;
      @include sp {
        margin-bottom: 0px;
      }
      &-text {
        display: block;
        margin-bottom: 15px;
        width: fit-content;
        @include sp {
          margin-bottom: 10px;
        }
      }
    }
    .fee {
      margin-bottom: 35px;
      &-text {
        display: block;
        margin-bottom: 15px;
      }
    }
    .listing {
      &-text {
        display: block;
        margin-bottom: 15px;
      }
    }
    .btn-upload {
      padding: 10px;
      background-color: #394df5;
      color: #fff;
      align-items: center;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }
  }
  .caution {
    display: flex;
    justify-content: center;
    &-bg {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      width: auto;
      background-color: #fff7e8;
      &.blue {
        background-color: #e8f3ff !important;
      }
      span {
        margin: 0 10px;
      }
      @include sp {
        margin-bottom: 20px;
      }
    }
  }
}
.fixed-bottom {
  padding: 20px 0 40px 0;
  width: 100%;
  @include sp {
    z-index: 1000;
    background: #f2f3fc;
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
}
.btn-verify {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  @include sp {
    margin-top: 20px;
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  &.fixed {
    position: fixed;
    bottom: 0px;
    background: #f2f3fc;
    box-shadow: 8px -4px 20px rgba(71, 89, 235, 0.5);
    z-index: 999;
    padding: 20px 0 30px 0;
    width: 90%;
  }
}
.isWidth {
  width: 170px;
  @include sp {
    width: 100px;
  }
  button {
    width: 100%;
  }
}
.m-25 {
  margin: 0 25px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-10 {
  display: block;
  margin-bottom: 10px;
}

.channel-text {
  display: flex;
}
.c-upload {
  width: 100%;
  input {
    display: none !important;
  }
  &__add {
    width: 100%;
    height: 200px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    &.logo {
      width: 50px;
      height: 50px;
    }
  }
  .btn-click {
    padding: 80px 0;
    background-color: #fff;
    margin-top: 15px;
    border-radius: 2px;
    border: 1px solid #d9dffb;
    box-shadow: inset 0px 4px 4px #c2cdfc;
    border-radius: 2px;
    cursor: pointer;
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #94a7c6;
  }
}
.channel {
  &-name {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4e5965;
    width: 30%;
  }
  &-url {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4e5965;
  }
  &-required {
    color: #4285f4;
  }
}
.c-repeater__row {
  display: flex;
  align-items: center;
  .c-repeater__remove {
    width: 5%;
  }
  .c-date {
    width: 30%;
    margin-left: 10px;
    margin-right: 8px;
    img {
      margin-right: 5px;
    }
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
  .inputForm {
    width: 80%;
    input {
      width: 100%;
      height: 100%;
      border: 0px;
    }
  }
}

.c-plus {
  background: #1a7ec8;
  align-items: center;
  padding: 10px 16px;
  width: fit-content;
  color: #ffffff;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
  img {
    margin-right: 15px;
    width: 10px;
    height: 10px;
  }
}

.btn-andt {
  width: 100% !important;
}

.contributors {
  .main-text {
    padding: 10px 16px;
    background: #d9dffb;
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 18px;
  }
  &-form {
    background: #fff;
    padding: 25px 16px 5px 16px;
  }
}

.infoToken {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 5px 0;
  border-bottom: 1px solid #f2f3f5;
}

.affiliate-form {
  display: flex;
}
