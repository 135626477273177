.main-layout{
    // transform: matrix(-1, 0, 0, 1, 0, 0);
    min-height: 2500px;
    background: #F8FAFB;
}
.block-align-right{
    margin: 0 0 0 auto;
}
.block-align-left{
    margin: 0 auto 0 0;
}
.mt-20{
    margin-top: 20px;
}
.mt-15 {
    margin-top: 15px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mb-15{
    margin-bottom: 15px !important;
}
.ml-10{
    margin-left: 10px;
}

.p-15{
    padding: 15px;
}
.p-20 {
    padding: 20px !important;
}
.mt-30per{
    margin-top: 97% !important; 
}
.purple-text{
    color: #4759EB !important;
}
.text-black{
    color: #000 !important;
}
.font-medium{
    font-family: HelveticaNeue-Medium !important;
}
.font-18{
    font-size: 18px !important;
}
.font-24 {
    font-size: 24px !important;
}
.font-30 {
    font-size: 30px !important;
}
.font-14 {
    font-size: 14px !important;
}
.d-grid{
    display: grid;
}
.text-danger{
    color: #ff4d4f;
}
.btn-danger{
    color: #ff4d4f !important;
    border: 2px solid #ff4d4f !important;
}
.public-bluesale-connect .popupwallet{
    position: relative;
    width: 100% !important;
}
.w-full{
    width: 100% !important;
}
.btn-main{
    padding: 15px 30px;
    background: linear-gradient(180deg, #4759EB 0%, #7887FF 100%);
    border-radius: 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #F1F6F9;
    border: none;
    font-family: HelveticaNeue-Medium;
   
    &.fill{
        background: #4759EB !important; 
    }
}

.btn-claim{
    @include max(991px){
        width: 100%;
        margin-top: 10px;
    }
}
.btn-white{
    padding: 15px 30px;
    background: #fff;
    border-radius: 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #4759EB;
    border: 2px solid #4759EB;
    font-family: HelveticaNeue-Medium;
}
.btn-main:hover{
    cursor: pointer;
    background: linear-gradient(180deg, #3147ec 0%, #5b68cc 100%);
}
.btn-white:hover{
    cursor: pointer;
    background: #f3f9fc;
}
.btn-main:disabled, .btn-white:disabled{
    background: rgba(71, 89, 235, 0.15);
    border-radius: 12px;
    color: #8B91C0;
    cursor: no-drop;
}
.ant-input-status-error{
    background: transparent !important;
    // border: 1px solid transparent !important;
    color: #ff4d4f !important;
    box-shadow: none !important;
    &:focus{
        box-shadow:none !important
    }
}
.icon-arrow-global{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #fff;
    filter: drop-shadow(0px 4px 20px rgba(45, 52, 128, 0.1));
    font-size: 30px;
    margin: auto;
    line-height: 65px;
    opacity: 0.5;
    @include sp{ 
        transform: rotate(-90deg); 
        margin-top: 15px !important;
    }
}
.d-mobile{
    display: none;
    @include sp {
        display: block;
    }
}
.d-desktop {
    display: block;
    @include sp {
        display: none;
    }
}
section.bls-token-section{ 
    position: relative;  
    padding-top: 71px;  

    &::before {
            content: '';
            position: fixed;
            left: 0; 
            background-image: url(../../assets/images/bls_bg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            animation: bgOpacity 20s ease-in-out infinite alternate;
            width: 100%;
            height: 100%; 
        }
}

.bls-token-section{
    .icon-public-sale{
        text-align: center;
        margin-bottom: 15px;
    }
    .bls-token-title{
        font-family: HelveticaNeue-Light;
        font-weight: 600 !important;
        font-size: 60px;
        line-height: 65px;
        align-items: center;
        text-align: center;
        color: #000A42;
        @include sp{
            font-size: 36px;
            line-height: 40px;
        }
        & span{
            font-family: HelveticaNeue-Bold !important;
            font-weight: 700 !important;
        }
    }
    & p {
        text-align: center;
        color: #5C6D8A;
        margin-top: 5px;
        font-size: 18px;
        @include sp{
            font-size: 17px;
        }
    }
    .bls-token-arb-chain{
        text-align: center;

        & button{
            padding: 5px 15px;
            height: 45px;
            border: none;
            background: rgba(52, 121, 255, 0.1);
            border-radius: 100px;
            & img{
                margin-right: 5px;
            }
        }
    }
    .bls-token-round{
        margin-top: 15px;
        margin-bottom: 15px;

        
        .bls-round-block{
            max-width: 450px;
            height: auto; 
            .bls-round-item{
                min-height: 240px;
                background: #fff;
                border-radius: 20px;
                padding: 15px;  
                box-shadow: 20px 30px 38px rgba(48, 83, 150, 0.1);
                &:hover{
                    background: #fff !important;
                    position: relative;
                    border-radius: 20px;
                    box-shadow: none;  
                    transition: 0.5s; 
                    &::before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        border-radius: 20px;
                        padding: 2px;
                        background:
                            linear-gradient(180deg, #4759EB 0%, rgba(120, 135, 255, 0) 100%),
                            linear-gradient(0deg, #E7EAF3, #E7EAF3);
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        mask-composite: xor;
                    }
                }
                .round-info{
                    margin-bottom: 15px;  
                    .ant-row{ 
                        align-items: center;
                    }
                    .round-name{
                        padding: 10px 19px;
                        width: max-content;
                        background: rgba(71, 89, 235, 0.1);
                        border-radius: 12px;
                        font-weight: 400;
                        font-size: 20px;
                        color: #4759EB;
                        @include max(991px){
                            font-size: 14px;
                            padding: 5px 10px
                        }
                        @include sp{
                            font-size: 12px;
                            padding:8px 10px;
                        }
                    }
                    .round-remain{
                        text-align: right;
                        color: #212529;
                        font-size: 16px; 
                        
                        @include sp {  
                            margin-left: auto;
                        }
                        .totalSale{
                            font-weight: 500;
                            font-size: 14px; 
                            color:#5C6D8A;
                            @include sp {
                                font-size: 12px;
                            }
                        }
                        .percent{
                            font-weight: 800;
                            font-size: 16px;
                            @include sp {
                                font-size: 14px;
                            }
                        }
                        .remain{
                            font-weight: 300;
                            font-size: 16px; 
                            @include max(991px) {
                                font-size: 12px;
                            }
                            @include sp{
                                font-size: 12px;
                            }
                        }
                        
                        @include sp{
                            font-size: 14px;
                        }
                    }
                }
                .round-time-coundown{
                    text-align: center;
                    line-height: 50px;

                    & span{
                        background: #D7DDF3;
                        border-radius: 7.5px;
                        padding: 10px;
                        font-size: 20px;
                        font-family: HelveticaNeue-Medium;
                    }
                    & span:nth-child(1){
                        margin-left: 10px;
                    }
                }
                .round-time-info{
                    text-align: center;
                    line-height: 30px;
                    margin-top: 5px;
                    color: #5C6D8A;
                    & span {
                        color: #4759EB;
                        font-weight: 500;
                        font-size: 16px;
                        font-family: HelveticaNeue-Medium;
                        @include sp{
                            font-size: 13px;
                        }
                    }
                }
                .round-accepted-payment{
                    padding: 10px 15px;    
                    background: rgba(52, 121, 255, 0.05);
                    border: 1px solid #4759EB;
                    box-shadow: inset 0px 0px 12px rgba(71, 89, 235, 0.26);
                    border-radius: 100px;
                    width: max-content;
                    margin: auto;
                    margin-top: 10px;
                    .payment{
                        color: #5C6D8A;
                    }
                    & img{
                        margin-left: 5px;
                        margin-top: -5px;
                    }
                }

                
            }
            .bls-round-item:hover {
                cursor: pointer;
                transition: 0.5s;
            }
            .round-item-active{
                background: #E7EAF3 !important; 
                position: relative;
                border-radius: 20px;   
                box-shadow: none;
                
            } 
            .round-item-active::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 20px;
                padding: 2px;
                background:
                    linear-gradient(180deg, #4759EB 0%, rgba(120, 135, 255, 0) 100%),
                    linear-gradient(0deg, #E7EAF3, #E7EAF3);
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                mask-composite: xor;
            }
            .round-item-active::after {
                content: "";
                position: absolute;
                border-radius: 20px;
                padding: 2px;
                background: url('../../assets/images/gif_arrow.gif');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 65px;
                height: 65px;
                border-radius: 50%;
                right: -31%;
                top: 80px;
                filter: drop-shadow(0px 4px 20px rgba(45, 52, 128, 0.12));
                @include sp {
                    display: none;
                }
            }
            .bls-round-item-selected{
                background: #FFFFFF;
                box-shadow: 10px 30px 38px rgba(37, 51, 77, 0.11);
                border-radius: 30px;
                position: relative;
                // z-index: 10;
               
                .bls-price-info{
                    height: auto;
                    background: url('../../assets/images/bls-price-bg.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    border-radius: 20px;
                    padding: 20px;
                    @include sp{
                        background-size: 100%;
                    }

                    .bls-price{
                        display: grid;
                        padding: 10px;
                        
                        @include max(991px) {
                            padding: 0px;
                        }
                        @include sp {
                            padding: 10px;
                        }
                        & span{
                            margin-bottom: 5px; 
                           
                            @include sp{
                                margin-bottom: 0px;
                            }
                        }
                        & span:nth-child(1){
                            font-size: 18px;
                            line-height: 21px;
                            color: #020513;
                            @include max(991px) {
                                font-size: 13px;
                            }
                            @include sp{
                                font-size: 14px;
                            }

                        }
                        & span:nth-child(2), span:nth-child(3) {
                            font-family: HelveticaNeue-Medium;
                            font-size: 26px;
                            line-height: 32px;
                            color: #000A42;
                            font-weight: 700;
                            @include max(991px) {
                                font-size: 20px;
                                line-height: 20px;
                            }
                            @include sp{
                                font-size: 24px;
                                line-height: 32px;
                            }
                        }
                        & span:nth-child(4) {
                            font-size: 14px;
                            line-height: 17px;
                            color: #020513;
                        }
                    }
                    .bls-price-img{
                        margin-top: -20px;
                        width: 100%;
                        height: auto;
                        text-align: center;
                        position: absolute; 
                        animation: 5s linear infinite running topToBotI1; 
                        & img{
                            width: 100%;
                            height: 100%;
                        }
                        @include sp{
                            margin-top: 0px;
                        }
                    }
                }
                .bls-round-selected-content{

                    .bls-sold-info{
                        padding-top: 10px !important;
                        @include max(991px){
                            padding-top: 5px !important;
                        }
                        .ant-progress-bg{
                            background: linear-gradient(90deg, #4759EB 0%, #7887FF 100%); 
                        }
                        .sold-info{
                            color:#5C6D8A;
                            font-weight: 400;
                            font-size: 14px;
                           
                            .title{
                                font-size: 16px;
                                font-weight: 100;
                                font-family: HelveticaNeue-Light;
                                @include max(991px) {
                                        font-size: 14px;
                                    }
                                @include sp {
                                        font-size: 13px;
                                    }
                            } 
                            & span{
                                font-size: 16px;
                                font-weight: 400;
                                font-family: HelveticaNeue-Medium;
                                @include max(991px) {
                                    font-size: 14px;
                                }
                                @include sp {
                                    font-size: 13px;
                                }
                            }
                        }
                        .bls-vesting-info {
                            color: #5C6D8A;
                            margin-top: 5px;
                            @include max(991px) {
                                font-size: 14px;
                            }
                            & span {
                                font-family: HelveticaNeue-Medium;
                                color: #212529;
                            }
                            @include sp{
                                font-size: 13px;
                            }
                        }
                    }
                    .bls-sold-timing{
                        background: linear-gradient(90.32deg, #E7F6FE 0.23%, #CCEEFF 99.78%);
                        padding-bottom: 7px !important;
                        padding-top: 7px !important;
                        & p{
                            color: #5C6D8A;
                            text-align: left !important;
                            font-size: 16px !important;
                            margin-bottom: 5px;
                            margin-top: 0px;
                            @include max(991px) {
                                font-size: 14px !important;
                            }
                            @include sp{
                                font-size: 13px !important;
                            }
                            & span{
                                font-family: HelveticaNeue-Medium;
                                color: #4759EB;
                                font-size: 16px !important;
                            }
                        }
                    }
                }
                .bls-round-selected-button{
                    padding: 20px;
                    & button{
                        width: 100%;
                        & img{
                            margin-top: 0px;
                            margin-right: 5px;
                        }
                    }
                }
                
            }
        }
        .bls-round-item-arrow{
            min-height: 240px;
            width: 100%;
            padding-top: 25px;
            .bls-round-center {
                text-align: center;
                visibility: hidden;
            }
            .active{
                visibility: unset !important;
            }
        }
    }
    .dividends-block{
        margin-top: 15px;

        .dividends-left{
            max-width: 450px;
            height: auto;
            margin-top: 10%;
            h3{
                color: #000A42;
            }
          
            & p{
                font-size: 16px;
                margin-bottom: 0px;
            }
            & h3{
                font-size: 60px;
                font-family: HelveticaNeue-Bold;
                margin-bottom: 0px;
                @include sp {
                        font-size: 42px;
                    }
            }
            & ul{
                padding: 0;
                margin: auto;
                padding-left: 0px;
                display: inline-block;

                & li{
                    list-style-type: none;
                    float: left;
                    padding: 0 10px;

                    & a{
                        & img{
                            width: 20px;
                            filter: brightness(0.3);
                        }
                        & :hover{
                            filter: brightness(1);
                        }
                    }
                }
            }
        }
        .dividends-right {
            max-width: 450px;
            height: auto;

            .dividends-right-content{
                padding-bottom: 20px;
                background: #FFFFFF;
                border: 2px solid #4759EB;
                border-radius: 30px;
                padding: 25px;
                padding-top: 10px !important;
               
                .dividends-tabs{
                    .ant-tabs-tab .ant-tabs-tab-btn { 
                        font-size: 18px !important;
                        line-height: 22px;
                        font-family: HelveticaNeue-Medium;
                        font-weight: 500 !important;
                    }
                    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
                        color: #4759EB !important;
                    }
                    .ant-tabs-nav::before{
                        border-bottom: none !important;
                    }
                }
                .dividends-buy-block{
                    background: #E6EAF9;
                    border-radius: 12px;
                    padding: 15px;
                    height: auto;
                }
                .dividends-buy-button{ 
                    margin-left: 5px;
                    & button {
                        width: 100% !important;
                    }
                    &.small-btn{
                        button{
                            padding: 12px
                        } 
                    }
                }
                .dividends-claim-block{ 
                    height: auto;
                    border-radius: 12px; 
                    .dividends-text{
                        margin-left: 15px;
                        margin-bottom: 7px;
                        display: block;
                        font-weight: 500;
                        font-size: 14px;
                        color:#212529
                    }
                    .dividends-claim-token{
                        display: flex;
                        align-items: center;
                        background: linear-gradient(90.32deg, #E7F6FE 0.23%, #CCEEFF 99.78%);
                        border-radius: 13px;
                        padding: 20px 15px;
                        justify-content: space-between;
                        margin-bottom: 18px;
                        @include max(991px) { 
                            flex-direction: column;
                        }
                        &-info{
                            display: flex;
                            align-items: center; 
                            .d-grid{
                                margin-left: 17px;
                            }
                        } 
                    }
                    .your-dividends-claim{
                        margin-top: 10px;
                        background: rgba(255, 255, 255, 0.4);
                        border-radius: 8px;
                        padding: 10px;
                    }
                }
            }
        }
    }
}
.bluesale-section{
    padding-top: 50px;
    padding-bottom: 50px;

    a:hover {
        text-decoration: underline !important;
    }

    .bluesale-token-content{
        padding-top: 30%;
        @include sp {
            padding-top: 10%;
            text-align: center;
        }
        & h3{
            font-family: HelveticaNeue-Bold;
            font-size: 40px;
            color:#000A42;
        }
        & p{
            color: #394262;
        }
        & a{
            font-family: HelveticaNeue-Light;
            margin-left: 15px;
            font-size: 15px;
            color: #4759EB;
        }
        
    }
    .bluesale-token-right{
        padding: 90px 50px;
        height: auto;
        text-align: center;
        background-image: url(../../assets/images/bg-circle.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        animation: zomImg 3s ease-in-out infinite alternate;
        & img{
            width: 100%;
            max-width: 565px;
            margin: auto;
        }
        @include sp {
            padding: 0px;
        }
    }
}
.token-usecase-section{
    padding-top: 50px;

    .token-usecase-block{
        width: 100%;

        & h3{
            font-family: HelveticaNeue-Bold;
            font-size: 40px;
            text-align: center;
            color: #000A42;

            & span{
                color: #4759EB;
            }
        }
        .fee-structure {
            width: 100%;
            padding-left: 45px;
            overflow-y: hidden;
            margin-bottom: 30px;
            // fee-structure-bg.png
            background-image: url(../../assets/images/fee-structure-bg.png);
            background-repeat: no-repeat;
            background-size: 65%;
            background-position: center;
            animation: zomImg1 3s ease-in-out infinite alternate;
            @include sp {
                animation: unset;
            }
            & img {
                width: 100%;
                margin-top: 15px;
            }
            
        }
        @include sp {
            .fee-structure {
                padding-left: 0px;
                & img {
                    margin-top: 10px;
                }
            }
        }
        .token-usecase-img{
            width: 100%;
            padding: 40px;
            @include sp {
                padding: 0px;
            }
            & img{
                width: 100%;
                animation: 5s linear infinite running topToBotI2;
                @include sp {
                    margin-bottom: 15px;
                }
            }
        }
        .token-usecase-item{
            background: #F8FAFB;
            border-radius: 20px;
            padding: 20px;
            @include sp {
                background: transparent;
            }
            .token-usecase-title{
                font-family: HelveticaNeue-Medium;
                font-size: 28px;
                color: #212529;
            }
            .token-usecase-description{
                color: #394262;
            }
        }
        .token-usecase-item:hover {
            background: #E7EAF3;
            cursor: pointer;
        }
        .token-usecase-item.active{
            background: #E7EAF3;
            border-radius: 20px;
            @include sp {
                background: transparent;
            }
        }
    }
}
.tokenomic-section{
    padding-bottom: 50px;
    .tokenomic-block{
        max-width: 960px;
        
        margin: auto;
        & h3 {
            font-family: HelveticaNeue-Bold;
            font-size: 40px;
            text-align: center;
            color: #000A42;
        }
        .tokenomic-info{
            padding: 0 25px;
            background: rgba(255, 255, 255, 0.48);
            border-radius: 50px;
            & p{
                margin-bottom: 0px;
                padding: 10px 0px;
            }
        }
        .tokenomic-info::before{
            content: "";
            width: 100%;
            height: 2px;
            background: linear-gradient(270deg, rgba(71, 89, 235, 0) .01%, #4759eb 51.05%, rgba(71, 89, 235, 0));
            position: absolute;
            max-width: 940px;
        }
        .tokenomic-info::after{
            content: "";
            width: 100%;
            height: 2px;
            background: linear-gradient(270deg, rgba(71, 89, 235, 0) .01%, #4759eb 51.05%, rgba(71, 89, 235, 0));
            position: absolute;
            max-width: 940px;
        }
        @include sp {
            .tokenomic-info::before {
                width: 80%;
            }
            .tokenomic-info::after {
                width: 80%;
            }
        }
        .tokenomic-chart{
            text-align: center;
            overflow-y: hidden;

            & img{
                margin-top: -30px;
                width: auto;
                max-width: 100%;
            }
            .tokenomic-chart-info{
                text-align: center;
                width: 100%;
                margin-top: -30px;
                & ul{
                    list-style: none;
                    padding: 0;
                    margin: 28px 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    & li{
                        list-style-type: none;
                        float: left;
                        padding-left: 0px;
                        padding-right: 35px;
                        margin-bottom: 20px;
                        display: flex;

                        .color-chart-info{
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                        .name-chart-info {
                            font-size: 16.0043px;
                            line-height: 20px;
                            align-items: center;
                            color: #1D2129;
                            margin-right: 5px;
                        }
                        .percent-chart-info {
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}
.vestingschedule-section{
    padding-bottom: 50px;
    .vesting-block{
        max-width: 100%;
        
        margin: auto;
        & h3 {
            font-family: HelveticaNeue-Bold;
            font-size: 40px;
            text-align: center;
            color: #000A42;
        }
        
        .vesting-chart{
            text-align: center;
            overflow-y: hidden;
            padding-bottom: 50px;
            padding-top: 50px;
            & img{
                width: 100%;
                max-width: 100%;

            }
            .vesting-chart-info{
                margin-top: 50px;
                text-align: center;
                width: 100%;
                & ul{
                    list-style: none;
                    padding: 0;
                    margin: 28px 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    & li{
                        list-style-type: none;
                        float: left;
                        padding-left: 0px;
                        padding-right: 35px;
                        margin-bottom: 20px;
                        display: flex;

                        .color-chart-info{
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                        .name-chart-info {
                            font-size: 16.0043px;
                            line-height: 20px;
                            align-items: center;
                            color: #1D2129;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.backer-partner-section{
    padding-bottom: 50px;
    .backer-partner-block{
        max-width: 100%;
        margin: auto;
        & h3 {
            font-family: HelveticaNeue-Bold;
            font-size: 40px;
            text-align: center;
            color: #000A42;
        }
        .backer-partner-content{
            margin: auto;

            & ul{
                list-style: none;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding: 0;

                & li{
                    width: 20%;
                    padding: 30px;
                    text-align: center;
                    @include sp {
                        width: 50%;
                        padding: 15px;
                    }

                    & img{
                        width: auto;
                        max-width: 100%;
                        height: 60px;
                    }
                }
            }
        }
    }
}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width: 476px) {
    .container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .container {
        width: 720px;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1140px;
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1340px;
        max-width: 100%
    }
}
@keyframes topToBotI1 {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(10px);
    }

    75% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0px);
    }
}
@keyframes topToBotI2 {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(15px);
    }

    75% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}
@keyframes zomImg {
    0% {
        background-size: 95% auto;
    }

    100% {
        background-size: 105% auto;
    }
}
@keyframes zomImg1 {
    0% {
        background-size: 60% auto;
    }

    100% {
        background-size: 70% auto;
    }
}
.flex{
    display: flex !important;
    align-items: center; 
}
.just-bw{
    justify-content: space-between;
}
.block-round{
    display: block; 
    text-align: center;
    position: absolute;  
    z-index: 100;
    top:50%;
    left:50%;
    transform: translate(-54%, -50%);
    width:100%; 
    margin-left: -30px;
    @include max(1025px){
        transform: translate(-50%, -50%);
        margin-left: 0px;
    }
    @include sp{
        transform: translate(-50%, -50%);
        margin-left: 0px;
    }
    .block-text{
        display: block;
        font-weight: 400;
        font-size: 18px;
        color: #212529;  
        @include max(991px) {
            font-size: 14px;
        }
        @include sp {
            font-size: 14px;
        }
    }
}
.pay-input{
   background-color:transparent;
   border:0px;
   padding: 0px;
   width: 100%;
   border-radius: 5px;
   border-color:transparent;
   box-shadow: none;
   &:focus{
        border: 0px;
        box-shadow:none
   }
}
.community{
    margin-top: 340px;
    @include sp {
        margin-top: 0px;
    }
}
.text-round{
    padding:10px;
    font-weight: 400;
    font-size: 16px;   
    color: #4759EB;
    display: block;
    background: rgba(52, 121, 255, 0.1);
    border-radius: 100px;
    text-align: center;
    margin-top: -8px; 
    @include max (991px){
        font-size: 12px;
        padding:5px
    }
    @include sp{
        font-size: 12px;
        margin-top: 0px;
    }
}

.icon-arrow {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #fff;
    filter: drop-shadow(0px 4px 20px rgba(45, 52, 128, 0.12));
    font-size: 30px;
    margin: auto;
    margin-top: 30%;
    overflow: hidden;
    
    @include sp { 
        margin-top: 5% !important;
        margin: 20px auto;
        transform: rotate(90deg); 
    }
    & img {
        width: 100%;
    }
}
.down-arrow {
    display: flex;
    transform: rotate(90deg);  
    justify-content: center;
    @include max(1025px) {
        margin-left: 70px; 
    }

    @include max(991px){
        margin-left:60px;
        margin-top: -100px;
    }
    
    @include sp{
        margin-left: 0px;
        margin-top: 0px;
        transform: rotate(0deg);
    }
    .icon-arrow{
        margin-top: 10%;
    }
} 

.water-effect{
    position: absolute;
    left: 20%;
    @include sp{
        display: none;
    }
}
.circleblue-effect{  
    position: absolute;
    left: -5%;
    top:60%; 
    @include sp {
        display: none;
    }
}

.social-fixed{
    position: fixed;
    right: -30px;
    bottom: 22%;
    z-index: 2;
    @include max(991px) {
        right: -55px;
    }
    @include sp {
        display: none;
    }
    &-item{
        margin-top: 5px; 
        display: block;
        img{
            filter: brightness(0.3)
        } 
        :hover{
            filter: brightness(1)
        }
    &:hover {
        cursor: pointer; 
    }
    }
    .contact-public{
        display: block;
        transform: rotate(-90deg);
        margin-top: 100px ;
        margin-left: -42px;
    }
}

.dividends-buyclaim{
    .buyclaim{
        width: 100%;
    @include sp {
        display: flex;
        flex-direction: column-reverse;
    }
    } 
}

@keyframes bgOpacity {
    20% {
        opacity: 0; 
 
    } 
    40% {
        opacity: 0.5
    }
    60%{
        opacity: 1
    }
    80% {
        opacity: 0.5
    }
    100%{
        opacity: 0; 
    }
}

.ant-tabs-tab:hover{
    color:#4759EB !important
}

.arrow-bottom{
    margin: 5px auto;
    text-align: center;  
    padding: 13px;
    background: rgba(71, 89, 235, 0.1);
    width: fit-content;
    border-radius: 99px;
    img{ 
        width: 26px;
        height: 26px; 
    } 
}

.bls-round-selected-content{ 
    .ant-progress-inner {
        margin-top: -10px;
}} 
 

.p-bottom {
    position: relative;
    z-index: 1; 
    @include max(991px) {
        padding: 70px 32px 24px;
    }

    @include sp {
        padding: 70px 24px 24px;
        z-index: 0;
    }

    &__box {
        background: #07133B;
        border-radius: 10px;
        display: flex;
        align-items: center;

        @include sp {
            display: block;
        }
    }

    &__img {
        text-align: left;
        width: 40%;
        padding: 40px;

        @include sp {
            width: 100%;
        }

        img {
            max-width: 370px;
            width: 100%;
        }
    }

    &__content {
        width: 60%;
        padding: 0px 10% 0 0%;

        @include sp {
            width: 100%;
            padding: 30px;
        }

        h3 {
            color: #F1F6F9;
            font-weight: 700;
            font-size: 30px;
            line-height: 54px;
            margin: 0 0 4px;

            @include sp {
                font-size: 28px;
                line-height: inherit;
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 141.02%;
            color: #A7B5CB;
            margin: 0 0 24px;
        }
    }

    &__btn {
        .c-btn {
            display: inline-block;
            color: #07133B;
            border-radius: 10px;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        padding-bottom: 24px;

        @include sp {
            display: block;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;

            @include sp {
                justify-content: center;
            }

            li {
                display: block;

                +li {
                    margin-left: 40px;

                    @include sp {
                        margin-left: 24px;
                    }
                }

                a {
                    color: #394262;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 25px;
                    text-decoration: none;
                    transition: .3s ease-in-out;

                    &:hover {
                        color: #4759EB;
                    }
                }
            }
        }

        p {
            color: #606E9C;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            padding: 0;

            @include sp {
                text-align: center;
                margin-top: 14px;
            }
        }
    }
}

.vestingschedule-block{
    font-family: HelveticaNeue-Bold;
    font-size: 40px;
    text-align: center;
    color: #212529;
}
// @media screen and (max-width: 768px) {
//     .tokenomic-info::before{
//         width: 80%;
//     }
//     .tokenomic-info::after {
//         width: 80%;
//     }
// }
.joined{
    display: flex;
    align-items: center;
}

 .ant-tabs-ink-bar {
     background: #4759EB;
 }

 .join-text{ 
    font-size: 18px;
    @include max(991px) {
            font-size: 14px;
    }
    @include sp{
        font-size: 12px;
    }
 }

 .currency-join{
    font-size: 24px;
    @include max(991px){
        font-size: 18px;
    }
    @include sp {
        font-size: 14px;
    }
 }

 .bls-account-detail{
    width: 100%;

    .your-wallet{
        width: 100%;
        text-align: center;
        font-size: 26px;
        line-height: 32px;
        color: #000A42;
        font-family: HelveticaNeue-Bold;
        font-style: normal;
        font-weight: 700;
    }
    .your-wallet-content{
        margin-top: 15px;
        background: #ECF1FF;
        border-radius: 24px;
        padding: 15px;

        .btn-connected{
            width: 120px;
            height: 40px;
            background: rgba(52, 121, 255, 0.05);
            border: 1px solid #4759EB;
            box-shadow: inset 0px 0px 12px rgba(71, 89, 235, 0.26);
            border-radius: 100px;
        }
    }
 }

 .page-block {
    padding-top: 100px;
    width: auto;
 }

 .page-block .page-title {
    font-family: HelveticaNeue-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 58px;
    align-items: center;
    text-align: center;
    color: #4759EB;
    width: 100%;
 }

 .page-block .page-content {
     width: 100%;
     padding-top: 20px;
     padding-bottom: 50px;
     font-size: 15px;
     line-height: 22px;
 }

 .page-block .page-content ul li {
     margin-bottom: 7px;
 }

 .page-block .page-content p {
    font-family: HelveticaNeue-Bold;
     color: #000;
 }

 .page-block .page-content h2,
 .page-block .page-content h3,
 .page-block .page-content h4 {
    font-family: HelveticaNeue-Bold;
     color: #000;
 }

 .page-block .page-content h2 {
    font-size: 30px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
        line-height: 40px;
 }

 .page-block .page-content h3 {
     font-size: 25px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
        line-height: 40px;
 }

 .page-block .page-content h4 {
     font-size: 20px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
        line-height: 40px;
 }

.toTop {
     position: fixed;
     bottom: 36px;
     right: 55px;
     width: 50px;
     height: 50px;
     border-radius: 12px;
     background: #4759EB;
     box-shadow: 3px 5px 14px rgba(71, 89, 235, 0.34);
     z-index: 999;
     cursor: pointer;
     padding: 10px;
    @include sp {
        display: none;
    }
     img {
         display: block;
         width: 100%;
         transform: rotate(-90deg);
     }
 }