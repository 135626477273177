body {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
    scrollbar-color: #5868E9 transparent;
    scrollbar-width: thin;
    transition: .3s ease-in-out;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
        transition: .3s ease-in-out;
    } 
    &::-webkit-scrollbar-thumb {
        background-color: #5868E9;
        border-radius: 2px;
        transition: .3s ease-in-out;
    }
    iframe{
        display: none;
    }
}
.l-main {
    padding-left: 240px;
    transition: .3s ease-in-out;
    @include max(991px) {
        padding-left: 0;
    }
    &.is-open {
        padding-left: 110px;
        @include max(991px) {
            padding-left: 0;
        }
    }
    
}

.is-isBanner {
    margin-left: -240px;
    transition: .3s ease-in-out;
    position: relative;
    z-index: 999;
    @include max(991px) {
        margin-left: 0;
    }
    &.is-open {
        margin-left: -110px;
        @include max(991px) {
            margin-left: 0;
        }
    }
    // .c-headerBanner {
    //     position: relative;
    //     z-index: 9;
    // }
    .c-sideBar {
        top: 72px;
        height: calc(100vh - 72px);
        @include max(1200px) {
            top: 84px;
            height: calc(100vh - 84px);
        }
        @include max(991px) {
            top: 104px;
            height: calc(100vh - 104px);
        }
        @include sp {
            top: 69px;
            height: calc(100vh - 69px);
        }
        @include max(509px) {
            top: 86px;
            height: calc(100vh - 86px);
        }
    }
    .header {
        top: 72px;
        @include max(1200px) {
            top: 84px;
        }
        @include max(991px) {
            top: 94px;
        }
        @include sp {
            top: 69px;
        }
        @include max(509px) {
            top: 86px;
        }
    }
    .c-logo {
        top: 72px;
        @include max(1200px) {
            top: 84px;
        }
        @include max(991px) {
            top: 94px;
        }
        @include sp {
            top: 69px;
        }
        @include max(509px) {
            top: 86px;
        }
    }
}