.c-sideBar {
    background: #fff;
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 64px 0 160px;
    box-sizing: border-box;
    z-index: 8;
    transition: .3s ease-in-out;
    @include max(991px) {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0;
    }
    &__footer {
        width: 100%;
        box-sizing: border-box;
        background: #F2F3FC;
        box-shadow: 0px -10px 10px rgba(240, 241, 250, 0.5);
        padding: 16px 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &__media {
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0 0 18px;
        transition: .3s ease-in-out;
        li {
            display: block;
            a {
                display: block;
                width: 20px;
                height: 20px;
                position: relative;
                img {
                    width: 20px;
                    height: 20px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: .3s ease-in-out;
                    &:nth-child(2) {
                        opacity: 0;
                    }
                }
                &:hover {
                    img {
                        &:nth-child(1) {
                            opacity: 0;
                        }
                        &:nth-child(2) {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    &__flogo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #6B7AEF;
        margin: 0 0 18px;
        transition: .3s ease-in-out;
        span {
            margin-left: 8px;
        }
    }
    &.is-sp {
        max-height: 100vh;
        padding: 60px 0 195px;
    }

    &.is-open {
        width: 110px;
        .c-language,
        .c-sideBar__media,
        .c-sideBar__flogo {
            transform: scale(0);
            display: none;
        }
        .c-nav {
            overflow: visible;
            height: calc(100vh - 128px);
            &__txt {
                transform: scale(0);
                display: none;
            }
            &__icon {
                margin-right: 0;
            }
            > ul {
                padding: 24px 16px;
                > li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    > a {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        box-sizing: border-box;
                    }
                    > span {
                        width: 50px;
                        height: 50px;
                        box-sizing: border-box;
                        &.is-sub {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
            &__sub {
                position: absolute;
                top: 0;
                left: 100px;
                width: 250px;
                padding-left: 7px;
                &:before {
                    content: '';
                    width: 7px;
                    height: 14px;
                    background: url(../images/icon-nav-arrow.png) no-repeat center;
                    background-size: 100% 100%;
                    position: absolute;
                    top: 17px;
                    left: 0;
                }
                > li {
                    > a {
                        padding: 12px 16px;
                        background: #fff;
                        &:hover {
                            background: #F2F3FC;
                        }
                    }
                    &:first-child {
                        a {
                            border-radius: 5px 5px 0 0;
                        }
                    }
                    &:last-child {
                        a {
                            border-radius: 0 0 5px 5px;
                        }
                    }
                }
            }
        }
    }
}

.c-nav {
    overflow-y: auto;
    height: calc(100vh - 224px);
    scrollbar-color: #5868E9 transparent;
    scrollbar-width: thin;
    @include sp {
        height: calc(100vh - 250px);
    }
    &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    } 
    &::-webkit-scrollbar-thumb {
        background-color: #5868E9;
        border-radius: 2px;
    }
    > ul {
        list-style: none;
        margin: 0;
        padding: 24px 16px 24px 24px;
        > li {
            + li {
                margin-top: 8px;
            }
            > a,
            > span {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #94A7C6;
                padding: 10px 12px;
                text-decoration: none;
                border-radius: 2px;
                transition: .3s ease-in-out;
                &.is-active {
                    background: #D9DFFB;
                    color: #4759EB;
                    .c-nav__icon {
                        img {
                            opacity: 1;
                            filter: brightness(1);
                        }
                    }
                }
                &:hover {
                    background: #F2F3FC;
                    color: #4759EB;
                    .c-nav__icon {
                        img {
                            opacity: 1;
                            filter: brightness(1);
                        }
                    }
                    &.is-sub {
                        &:after {
                            filter: brightness(1);
                            opacity: 1;
                        }
                    }
                }
            }
            > span {
                cursor: pointer;
                &.is-sub {
                    position: relative;
                    &:after {
                        content: '';
                        width: 14px;
                        height: 14px;
                        background: url(../images/sideBar-icon-arrow.svg) no-repeat center;
                        background-size: 100% 100%;
                        position: absolute;
                        top: 15px;
                        right: 13px;
                        filter: brightness(.5);
                        opacity: .5;
                        transform: rotate(180deg);
                        transition: .3s ease-in-out;
                    }
                    &.is-open {
                        &:after {
                            transform: rotate(0);
                            filter: brightness(1);
                            opacity: 1;
                        }
                    }
                }
            }            
        }
    }
    &__icon {
        display: block;
        width: 24px;
        margin-right: 4px;
        img {
            display: block;
            width: 100%;
            filter: brightness(.5);
            opacity: .5;
            transition: .3s ease-in-out;
        }
    }
    &__sub {
        padding: 0;
        list-style: none;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: .3s ease-in-out;
        &.is-open {
            max-height: 400px;
        }
        > li {
            
            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #94A7C6;
                text-decoration: none;
                transition: .3s ease-in-out;
                padding: 14px 0 12px 42px;
                display: block;
                &.is-active {
                    color: #4759EB;
                }
                &:hover {
                    background: #F2F3FC;
                    border-radius: 2px;
                    color: #5C6D8A;
                }
            }
            
        }
    }
}