.p-homePage {
    padding-top: 64px;
    // background: rgba(255, 254, 255, 1) url(../images/home/bg-home.png) no-repeat top center;
    background-size: 100% auto;
    &.is-bannerHeader {
        padding-top: 136px;
        @include max(1200px) {
            padding-top: 148px;
        }
    }
    @include sp {
        overflow-x: hidden;
    }
    &__bg {
        width: 100%;
        height: 100%;
        background: url(../images/home/home-bg.png) no-repeat top center;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        animation: 20s ease-in-out infinite alternate opacityBG;
    }
    .p-titleHome {
        width: 100%;
        font-weight: 400;
        font-size: 36px;
        line-height: 54px;
        color: #000A42;
        padding: 0;
        margin: 0 0 8px;
        @include max(1024px) {
            text-align: center;
        }
        &.is-center {
            text-align: center;
        }
    }
    .p-top {
        padding: 32px 20px 32px 32px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        @include max(1024px) {
            display: block;
            padding: 50px 32px 40px;
        }
        @include max(991px) {
            padding: 50px 24px 40px;
        }
        &__content {
            width: 65%;
            padding-right: 40px;
            @include max(1200px) {
                width: 60%;
            }
            @include max(1024px) {
                width: 100%;
                padding: 0;
            }
            span {
                display: block;
                color: #94A7C6;
                font-weight: 400;
                font-size: 13.4779px;
                line-height: 20px;
                margin-bottom: 8px;
                img {
                    margin-left: 8px;
                    height: 30px;
                }
            }
            h2 {
                padding: 0;
                margin: 0 0 12px;
                font-weight: 400;
                font-size: 48px;
                color: #1D2129;
                @include sp {
                    font-size: 40px;
                    line-height: inherit;
                }
            }
            p {
                padding: 0;
                margin: 0 0 32px;
                font-weight: 400;
                font-size: 22px;
                color: #1D2129;
            }
        }
        &__btn {
            display: flex;
            align-items: center;
            .c-btn {
                display: block;
                min-width: 200px;
                @include max(991px) {
                    min-width: auto;
                }
                + .c-btn {
                    margin-left: 8px;
                }
                &--border {
                    background: transparent;
                    &:hover {
                        background: #D9DFFB;
                    }
                }
            }
        }
        &__img {
            width: 35%;
            animation: 8s linear infinite running runImg;
            @include max(1200px) {
                width: 40%;
            }
            @include max(1024px) {
                width: 100%;
                margin-top: 32px;
                text-align: center;
            }
            img {
                width: 100%;                
                @include max(1024px) {
                    max-width: 526px;
                }
            }
        }
    }
    .p-banner {
        padding: 50px 32px 64px;
        position: relative;
        z-index: 1;
        @include max(991px) {
            padding: 48px 24px;
        }
    }
    .p-opening {
        margin-bottom: 50px;
        position: relative;
        z-index: 1;
        @include max(991px) {
            margin-bottom: 40px;
        }
        > p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #1D2129;
            margin: 0 0 26px;
        }
        .c-sliderCenter {
            padding: 0;
        }
    }
    .p-blueSale {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        position: relative;
        z-index: 1;
        @include max(1024px) {
            display: block;
        }
        &__img {
            width: 50%;
            padding: 8.3%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include max(1024px) {
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
            }
            @include sp {
                padding: 64px;
            }
            span {
                display: block;
                position: relative;
                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: url(../images/home/bls-token-bg.svg) no-repeat center center;
                    background-size: cover;
                    position: absolute;
                    top: 50%;
                    left: 50.8%;
                    z-index: 0;
                    transform: translate(-50%, -50%);
                    animation: zoom-in-zoom-out 8s ease-in-out infinite alternate;
                }
            }
            img {
                width: 100%;
                position: relative;
                z-index: 1;
            }
        }
        &__content {
            width: 50%;
            padding: 0 32px;
            @include max(1024px) {
                width: 100%;
                margin-top: 24px;
            }
            @include max(991px) {
                padding: 0 24px;
            }
            > p {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #1D2129;
                margin: 0 0 26px;
                @include max(1024px) {
                    text-align: center;
                }
            }
        }
        &__btn {
            display: flex;
            align-items: center;
            @include max(1024px) {
                justify-content: center;
            }
            .c-btn {
                display: block;
                margin-right: 8px;
                &:first-child {
                    min-width: 200px;
                    @include max(1100px) {
                        min-width: 150px;
                    }
                }
                &--white {
                    white-space: nowrap;
                    background: transparent;
                    img {
                        transition: .3s ease-in-out;
                    }
                    &:hover {
                        background: #d9dffb;
                        img {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
    .p-token {
        // background: #F7F8FC;
        background: linear-gradient(rgba(247,248,252,0), #F7F8FC);
        padding: 32px 32px 28px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        @include max(991px) {
            padding: 32px 24px 28px;
        }
        .p-titleHome {
            margin-bottom: 24px;
        }
        &__content {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 50%;
            @include max(1024px) {
                width: 100%;
                order: 3;
            }
            li {
                border-radius: 2px;
                padding: 26px;
                cursor: pointer;
                transition: .3s ease-in-out;
                @include max(991px) {
                    padding: 26px 0;
                }
                + li {
                    margin-top: 14px;
                }
                > h4 {
                    color: #212529;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 8px;
                }
                > p {
                    margin-bottom: 16px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #394262;
                }
                &.is-active,
                &:hover {
                    background: #E7EAF3;
                    @include max(991px) {
                        background: transparent;
                    }
                }
            }
        }
        &__btn {
            display: flex;
            .c-btn {
                + .c-btn {
                    margin-left: 8px;
                }
                &--blue {
                    min-width: 200px;
                    @include max(1100px) {
                        min-width: 150px;
                    }
                }
                &--white {
                    background: transparent;
                    white-space: nowrap;
                    &:hover {
                        background: #D9DFFB;
                    }
                }
            }
        }
        &__img {
            width: 50%;
            padding-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: 8s linear infinite running runImg;
            @include max(1024px) {
                order: 2;
                padding-left: 0;
                width: 60%;
                margin: 0 auto 32px;
            }
            @include max(991px) {
                display: none;
            }
            img {
                display: block;
                max-width: 403px;
                width: 100%;
            }
        }
        &__imgSP {
            text-align: center;
            display: none;
            margin-bottom: 24px;
            background-size: auto 100%;
            animation: 8s linear infinite running runImg;
            @include max(991px) {
                display: block;
            }
            img {
                max-width: 403px;
                width: 100%;
            }
        }
    }
    .p-fee {
        background: url(../images/home/bg-fee.png) no-repeat top center;
        background-size: 100% 100%;
        padding: 50px 100px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;
        margin-bottom: 50px;
        position: relative;
        z-index: 1;
        @include max(1200px) {
            padding: 50px 32px;
        }
        @include sp {
            background-size: 100% auto;
        }
        &__img {
            max-width: 942px;
            margin-top: 80px;
            position: relative;
            @include sp {
                margin-top: 40px;
            }
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                background: url(../images/home/fee-img-bg.png) no-repeat center center;
                background-size: auto 100%;
                position: absolute;
                top: calc(50% - 3px);
                left: calc(50% - 14px);
                z-index: 0;
                transform: translate(-50%, -50%);
                animation: zoom-in-zoom-out 8s ease-in-out infinite alternate;
                @include sp {
                    top: calc(50% - 2px);
                    left: calc(50% - 11px);
                }
                @include max(375px) {
                    top: calc(50% - 1px);
                    left: calc(50% - 5px);
                }
            }
            img {
                width: 100%;
                position: relative;
                z-index: 1;
            }
        }
    }
    .p-tokennomic {
        position: relative;
        z-index: 1;
        &__box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 32px;
        }
        &__info {
            margin: 0 0 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 60px;
            position: relative;
            @include max(1360px) {
                padding: 5px 20px;
            }
            @include max(1024px) {
                flex-wrap: wrap;
                width: 600px;
                margin-bottom: 24px;
            }
            @include sp {
                width: 350px;
                padding: 16px 20px;
            }
            &:before,
            &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: linear-gradient(270deg, rgba(71, 89, 235, 0) 0.01%, #4759EB 51.05%, rgba(71, 89, 235, 0) 100%);
                position: absolute;
                left: 0;
                @include sp {
                    height: 100%;
                    border-radius: 68px;
                    z-index: 0;
                }
            }
            &:before {
                top: 0;
            }
            &:after {
                bottom: 0;
                @include sp {
                    width: calc(100% - 2px);
                    height: calc(100% - 4px);
                    background: #fff;
                    top: 2px;
                    left: 1px;
                }
            }
            li {
                display: flex;
                align-items: center;
                color: #1D2129;
                font-weight: 400;
                font-size: 16.8799px;
                line-height: 28px;
                white-space: nowrap;
                margin: 5px 20px;
                @include max(1280px) {
                    margin: 5px 8px;
                    font-size: 16px;
                }
                @include max(1024px) {
                    width: calc(50% - 24px);
                    justify-content: center;
                }
                @include sp {
                    width: 230px;
                    position: relative;
                    z-index: 1;
                }
                span {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: rgba(71, 89, 235, 1);
                    margin-left: 4px;
                    img {
                        width: 28px;
                        margin-left: 6px;
                        @include max(1280px) {
                            width: 24px;
                        }
                    }
                }
            }
        }
        &__chart {
            display: flex;
            justify-content: center;
            align-items: center;
            @include max(1024px) {
                display: block;
                text-align: center;
            }
            img {
                width: 100%;
                max-width: 550px;
                @include max(1360px) {
                    width: calc(100% - 400px);
                }
                @include max(1024px) {
                    width: 100%;
                }
            }
            ul {
                padding: 0 0 0 30px;
                margin: 0;
                @include max(1360px) {
                    padding: 0 10px 0 7px;
                }
                @include max(1024px) {
                    padding: 0 32px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                li {
                    color: #1D2129;
                    font-weight: 500;
                    font-size: 21.84px;
                    line-height: 33px;
                    padding-left: 38px;
                    position: relative;
                    white-space: nowrap;
                    @include max(1024px) {
                        margin: 5px 16px;
                    }
                    @include max(991px) {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 12px 16px;
                    }
                    @include sp {
                        padding-left: 32px;
                    }
                    + li {                        
                        @include min(1025px) {
                            margin-top: 20px;
                        }
                    }
                    &::before {
                        content: '';
                        width: 24px;
                        height: 24px;
                        background: #5667F1;
                        border-radius: 50%;
                        position: absolute;
                        top: calc(50% - 12px);
                        left: 0;
                        @include sp {
                            width: 18px;
                            height: 18px;
                            top: calc(50% - 9px);
                        }
                    }
                    span {
                        display: inline-block;
                        margin-left: 16px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 33px;
                        @include max(991px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                    &.is-01 {
                        &:before {
                            background: #5667F1;
                        }
                        span {
                            color: #5667F1;
                        }
                    }
                    &.is-02 {
                        &:before {
                            background: #137DC3;
                        }
                        span {
                            color: #137DC3;
                        }
                    }
                    &.is-03 {
                        &:before {
                            background: #546BB9;
                        }
                        span {
                            color: #546BB9;
                        }
                    }
                    &.is-04 {
                        &:before {
                            background: #7259AA;
                        }
                        span {
                            color: #7259AA;
                        }
                    }
                    &.is-05 {
                        &:before {
                            background: #B455A7;
                        }
                        span {
                            color: #B455A7;
                        }
                    }
                    &.is-06 {
                        &:before {
                            background: #F6BA33;
                        }
                        span {
                            color: #F6BA33;
                        }
                    }
                    &.is-07 {
                        &:before {
                            background: #F15B86;
                        }
                        span {
                            color: #F15B86;
                        }
                    }
                    &.is-08 {
                        &:before {
                            background: #5EC8C5;
                        }
                        span {
                            color: #5EC8C5;
                        }
                    }
                    &.is-09 {
                        &:before {
                            background: #31C1FF;
                        }
                        span {
                            color: #31C1FF;
                        }
                    }
                }
            }
        }
        &__canvas {
            width: calc(100% - 400px);
            canvas {
                width: 500px !important;
                height: 500px !important;
            }
        }
    }
    .p-vesting {
        max-width: 1052px;
        margin: 24px auto 0;
        padding: 0 32px;
        position: relative;
        z-index: 1;
        @include max(991px) {
            margin-top: 50px;
        }
        @include sp {
            padding: 0 24px;
        }
        img {
            width: 100%;
            margin-top: 24px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 28px 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #1D2129;
                padding-left: 30px;
                display: block;
                margin: 12px 12px;
                position: relative;
                &:before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    background: #9EA6E0;
                    border-radius: 50%;
                    position: absolute;
                    top: calc(50% - 9px);
                    left: 0;
                }
                &:nth-child(2) {
                    &:before {
                        background: #4753B0;
                    }
                }
                &:nth-child(3) {
                    &:before {
                        background: #6570CC;
                    }
                }
                &:nth-child(4) {
                    &:before {
                        background: #1C85CA;
                    }
                }
                &:nth-child(5) {
                    &:before {
                        background: #624E8E;
                    }
                }
                &:nth-child(6) {
                    &:before {
                        background: #974C8D;
                    }
                }
                &:nth-child(7) {
                    &:before {
                        background: #E8406F;
                    }
                }
                &:nth-child(8) {
                    &:before {
                        background: #52A5A3;
                    }
                }
                &:nth-child(9) {
                    &:before {
                        background: #2FB7F2;
                    }
                }
                &:nth-child(10) {
                    &:before {
                        background: #F6BA33;
                    }
                }
                &:nth-child(11) {
                    &:before {
                        background: #F68533;
                    }
                }
            }
        }
    }
    .p-parter {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 50px 0 0;
        position: relative;
        z-index: 1;
        .p-titleHome {
            width: 100%;
            margin-bottom: 20px;
        }
        ul {
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            max-width: 1093px;
            @include max(1024px) {
                max-width: 100%;
            }
            li {
                width: 218.59px;
                position: relative;
                @include sp {
                    width: 50%;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
    .p-bottom {
        position: relative;
        z-index: 1;
        padding: 70px 80px 24px;
        @include max(991px) {
            padding: 70px 32px 24px;
        }
        @include sp {
            padding: 70px 24px 24px;
        }
        &__box {
            background: #07133B;
            border-radius: 10px;
            display: flex;
            align-items: center;
            @include sp {
                display: block;
            }
        }
        &__img {
            text-align: center;
            width: 50%;
            padding: 24px;
            @include sp {
                width: 100%;
            }
            img {
                max-width: 370px;
                width: 100%;
            }
        }
        &__content {
            width: 50%;
            padding: 24px;
            @include sp {
                width: 100%;
            }
            h3 {
                color: #F1F6F9;
                font-weight: 700;
                font-size: 30px;
                line-height: 54px;
                margin: 0 0 4px;
                @include sp {
                    font-size: 28px;
                    line-height: inherit;
                }
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 141.02%;
                color: #A7B5CB;
                margin: 0 0 24px;
            }
        }
        &__btn {
            .c-btn {
                display: inline-block;
                color: #07133B;
                border-radius: 2px;
            }
        }
        &__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            @include sp {
                display: block;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                @include sp {
                    justify-content: center;
                }
                li {
                    display: block;
                    + li {
                        margin-left: 40px;
                        @include sp {
                            margin-left: 24px;
                        }
                    }
                    a {
                        color: #394262;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 25px;
                        text-decoration: none;
                        transition: .3s ease-in-out;
                        &:hover {
                            color: #4759EB;
                        }
                    }
                }
            }
            p {
                color: #606E9C;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin: 0;
                padding: 0;
                @include sp {
                    text-align: center;
                    margin-top: 14px;
                }
            }
        }
    }
    .toTop {
        position: fixed;
        bottom: 36px;
        right: 12px;
        width: 50px;
        height: 50px;
        border-radius: 12px;
        background: #4759EB;
        box-shadow: 3px 5px 14px rgba(71,89,235,0.34);
        z-index: 999;
        cursor: pointer;
        padding: 10px;
        img {
            display: block;
            width: 100%;
            transform: rotate(-90deg);
        }
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: translate(-50%, -50%) scale(1.1)
    }
    50% {
        transform: translate(-50%, -50%) scale(1.44956);
    }
    100% {
        transform: translate(-50%, -50%) scale(1.1)
    }
}
@keyframes zoom-fee {
    0% {
        transform: scale(0.7)
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7)
    }
}
@keyframes runImg {
    0% {
        transform: translate(-2px, -15px);
    }
    25% {
        transform: translate(2px, 0);
    }
    50% {
        transform: translate(0, 15px);
    }
    75% {
        transform: translate(-2px, 0);
    }
    100% {
        transform: translate(-2px, -15px);
    }
}

@keyframes opacityBG {
    20% {
        opacity: 0; 
    } 
    40% {
        opacity: 0.5
    }
    60%{
        opacity: 1
    }
    80% {
        opacity: 0.5
    }
    100%{
        opacity: 0; 
    }
}