.ant-select-arrow {
  &:after {
    content: "";
    width: 12px;
    height: 12px;
    border-bottom: 2px solid #5c6d8a;
    border-left: 2px solid #5c6d8a;
    position: absolute;
    top: calc(50% - 8px);
    right: 2px;
    transform: rotate(-45deg);
    transition: 0.3s ease-in-out;
  }
  .anticon-down {
    display: none;
  }
}
.ant-select-open {
  .ant-select-arrow {
    &:after {
      top: calc(50% - 4px);
      transform: rotate(-225deg);
    }
  }
}

.c-input {
  &__title {
    color: #5c6d8a;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    margin: 0 0 8px;
  }
  &__max {
    position: relative;
    > span {
      background: #f2f3f5;
      width: 55px;
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1px;
      right: 1px;
      box-shadow: inset 0px 4px 4px #c2cdfc;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1a7ec8;
    }
  }
  input {
    appearance: none;
    display: block;
    width: 100%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d9dffb;
    box-shadow: inset 0px 4px 4px #c2cdfc;
    border-radius: 2px;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c6d8a;
    box-sizing: border-box;
    transition: 0.3s ease-in-out;
    &::placeholder {
      color: #94a7c6;
    }
    &:hover {
      background: #f2f3fc;
    }
    &:focus-visible {
      border: 1px solid #4759eb;
      background: #fff;
      outline: none;
    }
    &:disabled {
      background: #ffffff;
      opacity: 0.5;
      cursor: not-allowed;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  input[number] {
    -moz-appearance: textfield;
  }
  textarea {
    appearance: none;
    display: block;
    width: 100%;
    height: 160px;
    background: #ffffff;
    border: 1px solid #d9dffb;
    box-shadow: inset 0px 4px 4px #c2cdfc;
    border-radius: 2px;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c6d8a;
    box-sizing: border-box;
    transition: 0.3s ease-in-out;
    &::placeholder {
      color: #94a7c6;
    }
    &:hover {
      background: #f2f3fc;
    }
    &:focus-visible {
      border: 1px solid #4759eb;
      background: #fff;
      outline: none;
    }
    &:disabled {
      background: #ffffff;
      opacity: 0.5;
      cursor: not-allowed;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.c-search {
  position: relative;
  input {
    appearance: none;
    display: block;
    width: 100%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d9dffb;
    box-shadow: inset 0px 4px 4px #c2cdfc;
    border-radius: 2px;
    padding: 10px 46px 10px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c6d8a;
    box-sizing: border-box;
    transition: 0.3s ease-in-out;
    &::placeholder {
      color: #94a7c6;
    }
    &:hover {
      background: #f2f3fc;
    }
    &:focus-visible {
      border: 1px solid #4759eb;
      background: #fff;
      outline: none;
    }
    &:disabled {
      background: #ffffff;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  button {
    appearance: none;
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(50% - 12px);
    right: 16px;
    cursor: pointer;
  }
}

.ant-radio-group {
  .ant-radio-wrapper {
    font-size: 16px;
    line-height: 24px;
    color: #5c6d8a;
    margin-right: 0;
    .ant-radio {
      + * {
        padding-right: 0;
      }
      .ant-radio-inner {
        border-width: 5px;
        border-color: #e5e6eb;
        width: 18px;
        height: 18px;
      }
    }
    &:hover,
    &.ant-radio-wrapper-checked {
      .ant-radio-inner {
        border-color: #4759eb;
        &:after {
          display: none;
        }
      }
    }
  }
}
