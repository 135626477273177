
.c-btn {
  height: 44px;
  appearance: none;
  background: #d9dffb;
  border-radius: 2px;
  padding: 10px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #394df5;
  border: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  box-sizing: border-box;
  @include sp {
    padding: 10px 8px;
  }
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      display: block;
      margin: 0 8px;
    }
  }
  svg {
    width: 14px;
    height: 14px;
    path {
      fill: #4759eb;
      transition: 0.3s ease-in-out;
    }
  }
  &:hover {
    background: #5868e9;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &:focus-visible {
    background: #394df5;
  }
  &:disabled {
    background: #d9dffb;
    opacity: 0.5;
    color: #394df5;
    cursor: not-allowed;
    svg {
      path {
        fill: #394df5;
      }
    }
  }
  .spin-modal {
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &--blue {
    background: #4759eb;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
    &:hover {
      background: #5868e9;
    }
    &:focus-visible {
      background: #394df5;
    }
    &:disabled {
      background: #4759eb;
      opacity: 0.5;
      color: #fff;
    }
  }
  &--red {
    background: #ffece8;
    color: #f53f3f;
    svg {
      path {
        fill: #f53f3f;
      }
    }
    &:hover {
      background: #fdcdc5;
      color: #f53f3f;
    }
    &:focus-visible {
      background: #f76560;
      color: #ffffff;
    }
    &:disabled {
      background: #ffece8;
      color: #f53f3f;
      opacity: 0.5;
    }
  }
  &--grey {
    background: #5c6d8a;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
    &:hover {
      background: #6f7c8e;
    }
    &:focus-visible {
      background: #5c6d8a;
    }
    &:disabled {
      background: #5c6d8a;
      opacity: 0.5;
      color: #fff;
    }
  }
  &--greyBorder {
    background: #fff;
    color: #5c6d8a;
    border: 1px solid #5c6d8a;
    svg {
      path {
        fill: #5c6d8a;
      }
    }
    &:hover {
      background: #e5e6eb;
      color: #5c6d8a;
      border: 1px solid #5c6d8a;
      svg {
        path {
          fill: #5c6d8a;
        }
      }
    }
    &:focus-visible {
      background: #e5e6eb;
      color: #5c6d8a;
      svg {
        path {
          fill: #5c6d8a;
        }
      }
    }
    &:disabled {
      background: #fff;
      opacity: 0.5;
      color: #5c6d8a;
      svg {
        path {
          fill: #5c6d8a;
        }
      }
    }
  }
  &--border {
    background: #fff;
    color: #4759eb;
    border: 1px solid #4759eb;
    svg {
      path {
        fill: #4759eb;
      }
    }
    &:hover {
      background: #d9dffb;
      color: #5868e9;
      svg {
        path {
          fill: #5868e9;
        }
      }
    }
    &:focus-visible {
      background: #4759eb;
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    &:disabled {
      background: #fff;
      opacity: 0.5;
      color: #4759eb;
      svg {
        path {
          fill: #4759eb;
        }
      }
    }
  }
  &--white {
    background: #fff;
    color: #4759eb;
    svg {
      path {
        fill: #4759eb;
      }
    }
    &:hover {
      background: #d9dffb;
      color: #5868e9;
      svg {
        path {
          fill: #5868e9;
        }
      }
    }
    &:focus-visible {
      background: #4759eb;
      color: #fff;
      svg {
        path {
          fill: #4759eb;
        }
      }
    }
    &:disabled {
      background: #fff;
      opacity: 0.5;
      color: #4759eb;
      svg {
        path {
          fill: #4759eb;
        }
      }
    }
  }
}
.copy-btn{
  background: transparent;
  border: none;
  &:hover{
    cursor: pointer;
  }
}