.ant-picker-now {
    display: none !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;
}

.th-title{
    text-align: inherit;
}
.list-contributor{
    width: 100%;
}
.btn-contributor{
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.not-connect-pri {
    text-align: center;
    width: 100%;
    background: #5c6d8a;
    color: #fff;
    height: auto;
    font-weight: 400;
    border-radius: 2px;
    font-size: 16px;
    opacity: 0.5;
    padding: 10px;
    /* border: none; */
}
.down-csv {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}
.list-contributor td,th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px;
}

.list-contributor tr:nth-child(even) {
    background-color: #dddddd66;
}
.copy-private:hover{
cursor: pointer;
}
.add-cont.coppy{
    display: flex;
    justify-content: space-between;
}

.c-loading {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: block;
        width: 80%;
        max-width: 450px;
    }
}

.c-nodata {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &.w-auto {
        img {
            width: auto;
            max-width: 100%;
        }
    }
    &.is-home {
        img {
            width: 100%;
            max-width: 300px;
        }
        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #5C6D8A;
            margin: 0;
        }
    }
    img {
        display: block;
        width: 290px;
    }
    p {
        width: 100%;
        margin: 20px 0 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #1D2129;
        span {
            display: block;
            margin-top: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #5C6D8A;
        }
    }
}

.c-logo {
    width: 240px;
    height: 64px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.002);
    opacity: 0.9;
    //backdrop-filter: blur(20px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
    box-sizing: border-box;
    @include max(991px) {
        width: 100%;
        height: 60px;
        padding: 18px 24px;
        justify-content: center;
        backdrop-filter: blur(20px);
    }
    &__toggle {
        width: 24px;
        height: 24px;
        display: block;
        cursor: pointer;
        margin-right: 8px;
        @include max(991px) {
            position: absolute;
            left: 24px;
            top: calc(50% - 12px);
        }
        img {
            width: 100%;
            display: block;
            transition: .3s ease-in-out;
        }
    }
}

.c-language {
    width: 110px;
    display: flex;
    align-items: center;
    transition: .3s ease-in-out;
    &__icon {
        display: block;
        width: 21px;
        img {
            display: block;
            width: 100%;
            filter: brightness(.5);
            opacity: .5;
            transition: .3s ease-in-out;
        }
    }
    &__txt {
        display: block;
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5C6D8A;
    }
    &:hover {
        background: #D9DFFB;
        border-radius: 5px;
        .c-language__icon {
            img {
                filter: brightness(1);
                opacity: 1;
            }
        }
        .c-language__txt {
            color: #4759EB;
        }
    }
}

.c-darkMore {
    width: 57px;
    label {
        display: block;
        cursor: pointer;
        input {
            display: none;
            &:checked {
                + span {
                    background: linear-gradient(to left, rgba(#2E3F57,1), rgba(#FFFFFF,.07));
                    &:before {
                        left: 32px;
                        background: #D9DFFB;
                        box-shadow: -4px 4px 20px #2E3F57, inset -12px 12px 12px #C7D1F0, inset 12px -12px 20px #4759EB;
                    }
                    &:after {
                        background: url(../images/darkMore-bg.png) no-repeat center;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        span {
            display: block;
            width: 100%;
            height: 29px;
            background: linear-gradient(to left, rgba(#A8BCDA,.25), rgba(#FFFFFF,1));
            box-shadow: inset 15px 15px 30px rgba(128, 156, 198, 0.25);
            border-radius: 100px;
            position: relative;
            transition: .3s ease-in-out;
            &:before {
                content: '';
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background: #4759EB;
                box-shadow: -4px 4px 20px rgba(0, 17, 44, 0.24), inset -12px 12px 12px #4759EB, inset 12px -12px 20px #091AA9;
                position: absolute;
                top: calc(50% - 11px);
                left: 4px;
                z-index: 2;
                transition: .3s ease-in-out;
            }
            &:after {
                content: '';
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                background: url(../images/lightMore-bg.png) no-repeat center;
                background-size: 100% 100%;
                border-radius: 100px;
                position: absolute;
                top: 2px;
                left: 2px;
                z-index: 1;
            }
        }
    }
}

.c-tabEdit {
    .ant-tabs-nav {
        margin-bottom: 24px;
        @include sp {
            display: block;
        }
        &:before {
            display: none;
        }
        &-list {
            border-bottom: 1px solid #e5e6eb;
            padding: 0 16px;
        }
        .ant-tabs-tab {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #94A7C6;
            padding: 8px 0;
            @include sp {
                font-size: 16px;
                line-height: 24px;
            }
            + .ant-tabs-tab {
                margin: 0 0 0 24px;
            }
            &-active {
                .ant-tabs-tab-btn {
                    color: #4759EB;
                }                
            }
        }
        .ant-tabs-ink-bar {
            background: #4759EB;
        }
        .ant-tabs-extra-content {
            margin-top: 16px;

        }
    }
}

.c-selectEdit {
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 2px;
    border: 1px solid transparent;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C6D8A;
    &.ant-select-open {
        border: 1px solid #4759EB;
    }
    .ant-select-selector {
        border: 0 !important;        
        height: 36px !important;
        padding-left: 0 !important;
    }
    .ant-select-selection-item {
        padding: 2px 8px !important;
    }
    &.ant-select-focused {
        box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
        .ant-select-selector {
            box-shadow: unset !important;
            outline: none;
        }
    }
    &.is-ml24 {
        margin-left: 24px;
        @include sp {
            margin-left: 16px;
        }
    }
}

.c-pagingEdit {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-pagination-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1D2129;
        border: 0;
        width: 36px;
        height: 36px;
        background: transparent;
        border-radius: 2px;
        transition: .3s ease-in-out;
        @include sp {
            width: 32px;
            height: 32px;
            margin-right: 2px;
        }
        a {
            color: #1D2129;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            @include sp {
                width: 32px;
                height: 32px;
            }
        }
        &:hover {
            background: #D9DFFB;
        }
        &.ant-pagination-item-active {
            background: #5868E9;
            a {
                color: #fff;
            }
        }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        width: 36px;
        height: 36px;        
        border: 0;
        @include sp {
            width: 32px;
            height: 32px;
        }
        .ant-pagination-item-link {
            border: 0;
            color: #5C6D8A;
            background: transparent;
        }
        &.ant-pagination-disabled {
            color: rgba(92, 109, 138, 1);
            opacity: .5;
        }
        &:hover {
            background: #D9DFFB;
        }
    }
    .ant-pagination-options {
        display: none;
    }
}

.c-dateEdit {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #5C6D8A;
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px #C2CDFC;
    border-radius: 2px;
    border: 0;
    padding: 10px 12px;
    transition: .3s ease-in-out;
    &:hover {
        background: #F2F3FC;
    }
}

.c-sortBy {
    position: relative;
    margin-left: 24px;
    height: 36px;
    display: flex;
    align-items: center;
    @include sp {
        margin-left: 16px;
    }
    &__box {
        max-height: 0;
        overflow: hidden;
        transition: .3s ease-in-out;
        position: absolute;
        top: 38px;
        right: 0;
        z-index: 2;
        &.is-open {
            max-height: 450px;
        }
    }
    > span {
        width: 32px;
        height: 32px;
        display: block;
        cursor: pointer;
        img {
            display: block;
            width: 100%;
        }
    }
    ul {
        list-style: none;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
        border-radius: 4px;
        padding: 4px 0;
        min-width: 106px;
        li {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;            
            color: #5C6D8A;
            padding: 10px 12px;
            transition: .3s ease-in-out;
            cursor: pointer;
            &:hover {
                background: #F2F3FC;
            }
            &.is-active {
                color: #4759EB;
                background: #D9DFFB;
            }
        }
    }
}

.c-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
    @include sp {
        margin: 0;
    }
    &.is-list {
        margin: 0 0 35px;
        display: block;
        background: #fff;
    }
    &__item {
        width: calc(100%/4 - 36px);
        margin: 0 18px 36px;
        border-radius: 40px;
        padding: 24px;
        box-sizing: border-box;
        background: linear-gradient(157.09deg, #FFFFFF 0.05%, rgba(71, 89, 235, 0.2) 48.84%, rgba(192, 211, 237, 0.4) 98.34%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
        position: relative;
        filter: drop-shadow(0px 0px 20px rgba(88, 104, 233, 0.1));
        transition: .3s ease-in-out;
        @include max(1680px) {
            width: calc(100%/3 - 36px);
        }
        @include max(1100px) {
            width: calc(100%/2 - 36px);
        }
        @include sp {
            width: 100%;
            margin: 0 0 36px;
        }
        &:before {
            content: '';
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: #fff;
            position: absolute;
            top: 1px;
            left: 1px;
            border-radius: 40px;
            z-index: 0;
        }
        &:hover {
            filter: drop-shadow(4px 4px 0px #4759EB);
            .c-list__detail {
                background: #887100;
                svg {
                    path {
                        fill: #FEFFE8;
                    }
                }
            }
            .c-list__img {
                img {
                    transform: scale(1.1);
                }
            }
        }
        &.is-Opening {
            .c-list__detail {
                background: #FF9A2E;
                svg {
                    path {
                        fill: #D25F00;
                    }
                }
            }
            .c-countDown {
                color: #D25F00;
                span {
                    color: #D25F00;
                }
            }
            &:hover {
                .c-list__detail {
                    background: #D25F00;
                    svg {
                        path {
                            fill: #FEFFE8;
                        }
                    }
                }
            }
        }
        &.is-Completed {
            .c-list__detail {
                background: #23C343;
                svg {
                    path {
                        fill: #009A29;
                    }
                }
            }
            .c-countDown {
                color: #009A29;
                span {
                    color: #009A29;
                }
            }
            &:hover {
                .c-list__detail {
                    background: #009A29;
                    svg {
                        path {
                            fill: #FEFFE8;
                        }
                    }
                }
            }
        }
        &.is-private {
            .c-list__logo {
                p {
                    span {
                        &:nth-child(1) {
                            color: #00B42A;
                            background: #E8FFEA;
                        }
                        &:nth-child(2) {
                            color: #887100;
                            background: #FEFEBE;
                        }
                    }
                }
            }
            .c-list__text {
                &.is-btn {
                    margin-top: 16px;
                }
                .c-btn {
                    width: 100%;
                }
            }
        }
    }
    &__itemDisplay {
        width: 100%;
        border-top: 1px solid #F2F3F5;
        display: flex;
        transition: .3s ease-in-out;
        @include max(990px) {
            padding: 16px 24px;
            display: block;
        }
        &:last-child {
            border-bottom: 1px solid #F2F3F5;
        }
        &.is-open {
            background: #F2F3FC;
        }
    }
    &__rowDisplay {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: calc(100%/8);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #1D2129;
        padding: 12px 4px;
        transition: .3s ease-in-out;
        @include max(990px) {
            width: 50%;
            justify-content: flex-start;
            padding: 0;
        }
        &.is-full {
            width: 100%;
        }
        a {
            display: inline-block;
            padding: 4px 16px;
            border: 1px solid #4759EB;
            border-radius: 100px;
            color: #4759EB;
            font-size: 14px;
            line-height: 20px;
            box-sizing: border-box;
        }
        > .is-network {
            // display: flex;
            // align-items: center;
            font-weight: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            white-space: nowrap;
            line-height: 22px;
            @include sp {
                display: contents;
            }
            img {
                display: inline-block;
                width: 22px;
                height: 22px;
                margin-right: 5px;
            }
        }
    }
    &__progress {
        width: 100%;
        height: 20px;
        background: #F2F3FC;
        border-radius: 10px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #3947B7;
        position: relative;
        overflow: hidden;
        span {
            display: block;
            position: relative;
            z-index: 2;
            &:nth-child(2) {
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #D9DFFB;
                border-radius: 10px 0px 0px 10px;
                z-index: 1;
            }
        }
    }
    &__detail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 0 0 auto;
        background: #FBE842;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        transition: .3s ease-in-out;
        svg {
            width: 24px;
            height: 24px;
            path {
                fill: #887100;
                transition: .3s ease-in-out;
            }
        }
    }
    &__head {
        position: relative;
        z-index: 1;
        margin-top: 4px;
        margin-bottom: 16px;
        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #1D2129;
            margin-bottom: 8px;
            a {
                color: #1D2129;
            }
            span {
                display: block;
                margin-top: 4px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #5C6D8A;
            }
        }
        p {
            margin: 0;
            display: flex;
            justify-content: space-between;
            span {
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #1D2129;
                &:last-child {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #3947B7;
                    text-align: right;
                }
            }
        }
    }
    &__img {
        display: block;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        img {
            height: 142px;
            display: block;
            width: 100%;
            transition: 0.3s ease-in-out;
            object-fit: cover;
        }
    }
    &__logo {
        padding-left: 16px;
        position: relative;
        z-index: 2;
        display: flex;
        margin-top: -25px;
        margin-bottom: 16px;
        img {
            display: block;
            width: 50px;
            filter: drop-shadow(2px 0px 0px #4759EB);
            border-radius: 5px;
        }
        p {
            width: calc(100% - 50px);
            margin: 0;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-left: 16px;
            box-sizing: border-box;
            span {
                display: block;
                border-radius: 5px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                padding: 6px 10px;
                + span {
                    margin-left: 8px;
                }
                &:nth-child(1) {
                    color: #1A7EC8;
                    background: #C2E5FF;
                }
                &:nth-child(2) {
                    color: #00B42A;
                    background: #E8FFEA;
                }
                &:nth-child(3) {
                    color: #4759EB;
                    background: #F2F3FC;
                }
            }
        }
    }
    &__info {
        position: relative;
        z-index: 1;
        margin-top: 18px;
    }
    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #94A7C6;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        + .c-list__text {
            margin-top: 10px;
        }
        > span {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #5C6D8A;
            text-align: right;
            img {
                display: block;
                width: 24px;
                margin-right: 8px;
            }
            &.is-Finished {
                color: #009A29;
            }
        }
    }
    &__more {
        display: none;
        width: 25px;
        height: 25px;
        border: 3px solid #94A3B8;
        box-sizing: border-box;
        margin-right: 12px;
        cursor: pointer;
        position: relative;
        border-radius: 2px;
        transition: .3s ease-in-out;
        @include max(990px) {
            display: inline-block;
        }
        &:before {
            content: '';
            width: 14px;
            height: 1px;
            background: #94A3B8;
            position: absolute;
            left: calc(50% - 7px);
            top: calc(50% - 0.5px);
            transition: .3s ease-in-out;
        }
        &:after {
            content: '';
            width: 1px;
            height: 14px;
            background: #94A3B8;
            position: absolute;
            top: calc(50% - 7px);
            left: calc(50% - 0.5px);
            transition: .3s ease-in-out;
        }
        &.is-open {
            border: 3px solid #1A7EC8;
            &:before {
                background: #1A7EC8;
            }
            &:after {
                transform: rotate(90deg);
                background: #1A7EC8;
            }
        }
    }
    &__box {
        max-height: 0;
        overflow: hidden;
        transition: .3s ease-in-out;
        display: flex;
        flex-wrap: wrap;
        &.is-open {
            max-height: 100%;
        }
        > p {
            margin: 8px 0 0;
            font-size: 14px;
            line-height: 20px;
            color: #1D2129;
            width: 50%;
            padding-right: 8px;
            @include max(600px) {
                width: 100%;
            }
            &:last-child {
                width: 100%;
            }
            span {
                display: inline-block;
                font-weight: 700;
                margin-right: 16px;
                &.is-network {
                    font-weight: normal;
                    margin-right: 0;
                    img {
                        display: inline-block;
                        width: 22px;
                        margin-right: 5px;
                    }
                }
            }
            a {
                display: inline-block;
                padding: 4px 16px;
                border: 1px solid #4759EB;
                border-radius: 100px;
                color: #4759EB;
                font-size: 14px;
                line-height: 20px;
                box-sizing: border-box;
            }
        }
    }
}

.c-progress {
    width: 100%;
    position: relative;
    z-index: 1;
    &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5C6D8A;
        margin: 0 0 8px;
    }
    &__bar {
        width: 100%;
        height: 8px;
        background: #D9DFFB;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        span {
            display: block;
            height: 100%;
            border-radius: 10px;
            background: #4759EB;
            position: absolute;
            top: 0;
            left: 0;
            transition: .3s ease-in-out;
        }
    }
    &__txt {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #94A7C6;
        margin: 4px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.c-countDown {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #887100;
    span {
        display: block;
        padding: 0 5px;
        color: #887100;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}

.c-breadcrumb {
    background: #F2F3FC;
    ul {
        width: 100%;
        box-sizing: border-box;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 12px 24px;
        margin: 0;
        @include sp {
            padding: 10px 20px;
        }
        li {
            display: block;
            padding-left: 24px;
            position: relative;
            &:before {
                content: '/';
                font-size: 10px;
                line-height: 10px;
                position: absolute;
                top: calc(50% - 5px);
                left: 9px;
            }
            span {
                display: block;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #5C6D8A;
            }
            a {
                display: block;
                color: #1D2129;
                font-size: 14px;
                line-height: 18px;
                @include sp {
                    font-size: 12px;
                }
            }
            &:first-child {
                padding: 0;
                &:before {
                    display: none;
                }
            }
            &:last-child {
                width: 100%;
                margin-top: 12px;
                @include sp {
                    margin-top: 8px;
                }
                span {
                    color: #1D2129;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 400;
                    @include sp {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                &:before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: url(../images/breadcrumb-icon-left.png) no-repeat center;
                    background-size: cover;
                    top: calc(50% - 8px);
                    left: 0;
                    cursor: pointer;
                    @include sp {
                        width: 14px;
                        height: 14px;
                        top: calc(50% - 7px);
                    }
                }
            }
        }
    }
}

.c-noti {
    display: block;
    width: 400px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
    color: #1D2129;
    position: fixed;
    top: 64px;
    right: 0;
    z-index: 9;
    border-radius: 2px;
    &.is-success {
        background: #E8FFEA;
    }
}

.c-notification {
    margin: 0 0 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1D2129;
    padding: 9px 36px 9px 40px;
    background: #FFF7E8;
    border-radius: 2px;
    position: relative;
    span {
        display: block;
        color: rgba(162, 53, 17, 1);
    }
    button {
        appearance: none;
        background: transparent;
        padding: 0;
        border: 0;
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin: 0;
        position: absolute;
        top: calc(50% - 6px);
        right: 16px;
        img {
            display: block;
            width: 100%;
        }
    }
    &:before {
        content: '';
        width: 16px;
        height: 16px;
        background: url(../images/icon-notification.svg) no-repeat center;
        background-size: 16px 16px;
        position: absolute;
        left: 16px;
        top: calc(50% - 8px);
    }
}

.c-ttl {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1D2129;
    margin: 0 0 8px;
}

.c-txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C6D8A;
    margin: 0;
    &.is-blue {
        color: #1A7EC8;
        text-align: right;
    }
}

.c-copy {
    a {
        color: #1A7EC8;
    }
    button {
        display: inline-block;
        width: 24px;
        appearance: none;
        padding: 0;
        margin: 0 0 0 20px;
        border: 0;
        background: transparent;
        cursor: pointer;
        img {
            width: 100%;
            display: block;
        }
    }
}

.c-createLaunchpad{
    padding-top: 96px;
}

.title-media{
    margin-left: 5px;
}
.option-media{ 
}
.input-media{
    width: 60% !important;
    .inputForm{
        width: 100% !important;
    }
}

.c-repeater__row{
    margin-bottom: 8px;
}

.c-social {
    &__head {
        display: flex;
        margin-bottom: 16px;
        p {
            width: 55%;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            margin: 0;
            &:first-child {
                width: calc(45% - 8px);
                margin-right: 8px;
            }
            span {
                color: rgba(66, 133, 244, 1);
            }
        }
    }
    &__row {
        width: 100%;
        display: flex;
        + .c-social__row {
            margin-top: 8px;
        }
        span {
            display: flex;
            align-items: center;
            width: calc(45% - 8px);
            margin-right: 8px;
            background: #FFFFFF;
            border: 1px solid #D9DFFB;
            box-shadow: inset 0px 4px 4px #C2CDFC;
            border-radius: 2px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            color: #5C6D8A;
            padding-left: 16px;
            img {
                display: block;
                width: 14px;
                margin-right: 12px;
            }
        }
        .ant-form-item {
            width: 55%;
        }
        .c-input {
            width: 100%;
            margin-bottom: 0 !important;
        }
    }
    &__select {
        width: calc(45% - 38px);
        margin-right: 6px;
    }
    &__input {
        width: 55%;
        .c-input {
            margin-bottom: 0 !important;
        }
    }
    .c-btn {
        background: #1A7EC8;
        border-radius: 2px;
        width: 225px;
    }
}

.c-upBanner {
    position: relative;
    input {
        display: none !important;
    }
    img {
        width: 100%;
        position: relative;
        z-index: 1;
    }
    &__edit {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(2px);
        z-index: 2;
        span {
            display: block;
            position: absolute;
            top: 16px;
            right: 16px;
            width: 48px;
            cursor: pointer;
        }
    }
    &.is-thumbnail {
        width: 50%;
        min-height: 150px;
    }
}

.c-upLogo {
    display: flex;
    align-items: center;
    input {
        display: none !important;
    }
    &__img {
        position: relative;
        width: 70px;
        img {
            display: block;
            width: 100%;
            position: relative;
            z-index: 1;
        }
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2px);
        }
    }
    
    &__edit {
        width: 32px;
        height: 32px;
        margin-left: 8px;
        span {
            display: block;
            cursor: pointer;            
            img {
                width: 100%;
            }
        }
    }
}

.c-sliderCenter {
    padding: 36px 0 0;
    @include sp {
        padding: 24px 0 0;
    }
    &__item {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &:hover {
            span {
                transform: scale(1.05);
            }
        }
        img {
            display: block;
            width: 100%;
            opacity: 0;
        }
        span {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-position: center;
            z-index: 1;
            transition: .3s ease-in-out;
        }
        a {
            display: block;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            border: 1px solid #FFFFFF;
            border-radius: 2px;
            padding: 10px 16px;
            position: absolute;
            bottom: 36px;
            left: calc(50% - 62px);
            z-index: 3;
        }
        p {
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
            padding: 36px 10px 0;
            position: absolute;
            z-index: 3;
            width: 100%;
        }
    }
    .slick-list {
        .slick-slide {
            box-sizing: border-box;
            width: 250px;
            transition: .5s ease-in-out;
            transform: scale(.9);
            > div {
                display: flex;
            }
            &.slick-active {
                width: 290px;
                transform: scale(.95);
                &.slick-center {
                    &.slick-current {
                        width: 330px;
                        transform: scale(1);
                    }
                }
            }
        }
    }    
    .slick-dots {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 24px 0 0;
        padding: 0;
        li {
            display: block;
            cursor: pointer;
            line-height: 1;
            font-size: 0;
            + li {
                margin-left: 20px;
            }
            button {
                appearance: none;
                border: 0;
                background: #D9DFFB;
                padding: 0;
                margin: 0;
                font-size: 0;
                width: 10px;
                height: 10px;
                cursor: pointer;
                transition: .3s ease-in-out;
            }
            &.slick-active,
            &:hover {
                button {
                    background: #394DF5;
                }
            }
        }
    }
}

.c-sliderBanner {
    &__item {
        padding: 0 5px;
        border-radius: 4px;
        overflow: hidden;
        img {
            width: 100%;
            border-radius: 4px;
        }
    }
    // .slick-list {
    //     .slick-track {
    //         .slick-slide {
    //             margin: 0 5px;
    //         }
    //     }
    // }
    
    .slick-dots {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 10px 0 0;
        padding: 0;
        li {
            display: block;
            cursor: pointer;
            line-height: 1;
            font-size: 0;
            + li {
                margin-left: 20px;
            }
            button {
                appearance: none;
                border: 0;
                background: #D9DFFB;
                padding: 0;
                margin: 0;
                font-size: 0;
                width: 10px;
                height: 10px;
                cursor: pointer;
                transition: .3s ease-in-out;
            }
            &.slick-active,
            &:hover {
                button {
                    background: #394DF5;
                }
            }
        }
    }
}

.c-sliderList {
    width: calc(100% - 332px);
    @include max(1280px) {
        width: calc(100% - 296px);
    }
    @include max(991px) {
        width: 100%;
        padding-left: 28px;
    }
    @include sp {
        padding-left: 12px;
    }
    &__item {
        background: linear-gradient(157.09deg, #FFFFFF 0.05%, rgba(71, 89, 235, 0.2) 48.84%, rgba(192, 211, 237, 0.4) 98.34%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border-radius: 40px;
        padding: 24px 16px;
        position: relative;
        filter: drop-shadow(0px 0px 5px rgba(88, 104, 233, 0.1));
        box-sizing: border-box;
        &:after {
            content: '';
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: #fff;
            position: absolute;
            top: 1px;
            left: 1px;
            border-radius: 40px;
            z-index: 0;
        }
        &.is-Opening {
            .c-sliderList__detail {
                background: #FF9A2E;
                svg {
                    path {
                        fill: #D25F00;
                    }
                }
            }
            .c-countDown {
                color: #D25F00;
                span {
                    color: #D25F00;
                }
            }
            &:hover {
                .c-sliderList__detail {
                    background: #D25F00;
                    svg {
                        path {
                            fill: #FEFFE8;
                        }
                    }
                }
            }
        }
    }
    &__detail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 0 0 auto;
        background: #FBE842;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        transition: .3s ease-in-out;
        svg {
            width: 24px;
            height: 24px;
            path {
                fill: #887100;
                transition: .3s ease-in-out;
            }
        }
    }
    &__head {
        margin: 4px 0 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #1D2129;
        position: relative;
        z-index: 1;
        a {
            color: #1D2129;
        }
        p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #1D2129;
            margin: 8px 0 0;
            span {
                display: block;
                text-align: right;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #3947B7;
            }
        }
    }
    &__img {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        img {
            width: 100%;
        }
    }
    &__body {
        margin-top: 18px;
        position: relative;
        z-index: 1;
        .c-btn {
            width: 100%;
            margin-top: 8px;
        }
    }
    &__text {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #94A7C6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #5C6D8A;
            img {
                margin-right: 8px;
            }
        }
    }
    .slick-list {
        .slick-slide {
            padding: 0 8px;
        }
    }
    .slick-dots {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 24px 0 0;
        padding: 0;
        li {
            display: block;
            cursor: pointer;
            + li {
                margin-left: 20px;
            }
            button {
                appearance: none;
                border: 0;
                background: #D9DFFB;
                padding: 0;
                margin: 0;
                font-size: 0;
                width: 10px;
                height: 10px;
                cursor: pointer;
                transition: .3s ease-in-out;
            }
            &.slick-active,
            &:hover {
                button {
                    background: #394DF5;
                }
            }
        }
    }
}

.c-listBox {
    display: flex;
    flex-wrap: wrap;
    padding-right: 32px;
    @include max(991px) {
        padding: 0 32px;
    }
    @include sp {
        display: block;
        padding: 0 16px;
    }
    &__item {
        width: calc(100% - 687px);
        border: 1px solid #0D0D0D;
        border-radius: 14px;
        padding: 40px 40px 84px;
        margin-bottom: 32px;
        position: relative;
        @include max(1680px) {
            width: calc(100% - 516px);
        }
        @include max(1280px) {
            width: calc(100% - 432px);
        }
        @include max(1024px) {
            width: calc(50% - 16px);
        }
        @include sp {
            padding: 24px 32px 68px;
            margin-bottom: 16px;
            width: 100%;
            min-height: 369px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            width: 655px;
            min-height: 428px;
            background: url(../images/bg-listBox.png) top left;
            background-size: cover;
            border: 0;
            @include max(1680px) {
                width: 484px;
                min-height: 452px;
            }
            @include max(1280px) {
                width: 400px;
                min-height: 350px;
            }
            @include max(1024px) {
                width: calc(50% - 16px);
            }
            @include sp {
                width: 100%;
                min-height: 369px;
            }
            .c-btn {
                background: transparent;
            }
        }
        &:nth-child(2) {
            margin-left: 32px;
            @include sp {
                margin-left: 0;
            }
        }
        &:nth-child(3) {
            margin-right: 32px;
            @include sp {
                margin-right: 0;
            }
            &:after {
                content: '';
                width: 162px;
                height: 184px;
                background: url(../images/icon-logo.png) center;
                background-size: cover;
                position: absolute;
                top: calc(50% - 92px);
                left: calc(50% - 81px);
                z-index: 0;
            }
        }
        h3 {
            font-weight: 400;
            font-size: 48px;
            line-height: 56px;
            color: #1D2129;
            margin-bottom: 36px;
            position: relative;
            z-index: 1;
            @include sp {
                font-size: 32px;
                line-height: 42px;
                margin-bottom: 24px;
            }
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #1D2129;
            max-width: 420px;
            @include sp {
                font-size: 14px;
            }
        }
        .marquee-container {
            span {
                font-weight: 400;
                font-size: 24px;
                line-height: 56px;
                color: #0D0D0D;
                padding: 0 16px 0 32px;
                position: relative;
                z-index: 1;
                @include sp {
                    font-size: 18px;
                }
                &:before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: #0D0D0D;
                    position: absolute;
                    top: calc(50% - 8px);
                    left: 0;
                }
            }
        }
        .c-btn {
            position: absolute;
            left: 40px;
            bottom: 40px;
            z-index: 1;
            @include sp {
                left: 32px;
                bottom: 24px;
            }
        }
    }
}

.c-about {
    position: relative;
    overflow: hidden;
    &.is-about {
        .c-about__box {
            h3, p {
                opacity: 1;
                transform: translateY(0);
            }
        }
        .c-about__imgLeft {
            transform: translateX(-225px);
            @include sp {
                transform: translateX(-150px);
            }
            &::before {
                opacity: 1;
            }
        }
        .c-about__imgRight {
            transform: translateX(225px);
            @include sp {
                transform: translateX(150px);
            }
            &::before {
                opacity: 1;
            }
        }
    }
    &__box {
        min-height: 450px;
        background: linear-gradient(110.63deg, #FEFEFE 0%, #EBECF3 100.47%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
            margin-bottom: 90px;
            text-align: center;
            padding-bottom: 8px;
            position: relative;
            opacity: 0;
            transition: .3s ease-in-out;
            transition-delay: .15s;
            transform: translateY(50px);
            &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: #4759EB;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            a {
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                text-align: center;
                color: #4759EB;
                @include sp {
                    font-size: 32px;
                    line-height: 39px;
                }
            }
        }
        p {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #1D2129;
            opacity: 0;
            transition: .3s ease-in-out;
            transition-delay: .15s;
            transform: translateY(50px);
            @include sp {
                font-size: 18px;
                line-height: 22px;
            }
            a,
            span {
                color: rgba(26, 126, 200, 1);
                cursor: pointer;
            }
        }
    }
    &__imgLeft,
    &__imgRight {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: .3s ease-in-out;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transition: .3s ease-in-out;
        }
    }
    &__imgLeft {        
        left: 0;
        background: url(../images/about-left-1920.svg) no-repeat center right;
        @include max(1360px) {
            background: url(../images/about-left.svg) no-repeat center right;
        }
    }
    &__imgRight {
        right: 0;
        background: url(../images/about-right1902.svg) no-repeat center left;
        @include max(1360px) {
            background: url(../images/about-right.svg) no-repeat center left;
        }
    }
}

.c-lockRecords {
    width: 100%;
    background: linear-gradient(157.09deg, #FFFFFF 0.05%, rgba(71, 89, 235, 0.2) 48.84%, rgba(192, 211, 237, 0.4) 98.34%);
    box-shadow: 0px 0px 20px rgba(88, 104, 233, 0.1);
    border-radius: 5.47064px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1px;
    &:after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: #FFFFFF;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 0;
        border-radius: 5.47064px;
    }
    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #1D2129;
        padding: 24px;
        margin: 0;
        position: relative;
        z-index: 1;
    }
    &__head {
        background: #F2F3FC;
        display: flex;
        align-items: center;
        padding: 8px 0;
        position: relative;
        z-index: 1;
        @include sp {
            display: none;
        }
        p {
            width: calc(100%/5);
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #5C6D8A;
            text-align: center;
            margin: 0;
            padding: 0 6px;
        }
    }
    &__row {
        border-bottom: 1px solid #D9DFFB;
        display: flex;
        align-items: center;
        padding: 16px 0;
        position: relative;
        z-index: 1;
        @include sp {
            background: #F7F8FA;
        }
        p {
            width: calc(100%/5);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #1D2129;
            text-align: center;
            margin: 0;
            padding: 0 6px;
        }
    }
    &__btn {
        position: relative;
        z-index: 1;
        padding: 16px 16px 24px;
        text-align: center;
        @include sp {
            text-align: left;
            padding-left: 24px;
        }
    }
    &__table {
        padding-left: 24px;
        padding-bottom: 16px;
        background: #fff;
        p {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #1D2129;
            + p {
                margin-top: 8px;
            }
            span {
                font-weight: 400;
                padding-left: 24px;
            }
        }
    }
    .ant-table-wrapper {
        position: relative;
        z-index: 1;
        .ant-table {
            table {
                thead {
                    th {
                        background: #F2F3FC;
                        padding: 8px 6px;
                        text-align: center;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: #5C6D8A;
                        border: 0;
                        @include sp {
                            text-align: left;
                            width: 100px;
                            padding-left: 24px;
                        }
                        &:nth-child(1) {
                            @include sp {
                                width: 60px;
                            }
                        }
                        &:nth-child(2) {
                            @include sp {
                                width: calc(100% - 60px);
                                padding-left: 12px;
                            }
                        }
                        &:before {
                            display: none
                        }
                    }
                }
                tbody {
                    td {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #1D2129;
                        text-align: center;
                        padding: 16px 6px;
                        border-bottom: 1px solid #D9DFFB;
                        @include sp {
                            text-align: left;
                            width: 100px;
                            padding-left: 24px;
                        }
                        &:nth-child(1) {
                            @include sp {
                                width: 60px;
                            }
                        }
                        &:nth-child(2) {
                            @include sp {
                                width: calc(100% - 60px);
                                padding-left: 12px;
                            }
                        }
                    }
                }
            }
            .ant-table-row-level-0 {
                td {
                    @include sp {
                        border: 0;
                    }
                    &.ant-table-row-expand-icon-cell {
                        .ant-table-row-expand-icon {
                            width: 25px;
                            height: 25px;
                            border: 1px solid #94A3B8;
                            box-sizing: border-box;
                            background: #F7F8FA;
                            &:before {
                                background: #94A3B8;
                                top: 11px;
                            }
                            &:after {
                                background: #94A3B8;
                                left: 11px;
                            }
                            &.ant-table-row-expand-icon-expanded {
                                border: 1px solid #1A7EC8;
                                &:before {
                                    background: #1A7EC8;
                                }
                                &:after {
                                    background: #1A7EC8;
                                }
                            }
                        }
                    }
                    
                }
            }
            .ant-table-expanded-row-level-1 {
                td {
                    padding: 0;
                }
            }
            .ant-table-placeholder {
                .ant-table-cell {
                    border: 0;
                }
            }
        }
        .ant-empty-normal {
            position: relative;
            height: 120px;
            margin: 0;
            &:before {
                content: '';
                width: 117px;
                height: 117px;
                background: url(../images/empty-table.svg) no-repeat center;
                background-size: cover;
                position: absolute;
                left: calc(50% - 58px);
                bottom: 0;
            }
            .ant-empty-image,
            .ant-empty-description {
                display: none;
            }
        }
    }
}

.c-tableToken {
    background: #FFFFFF;
    @include sp {
        background: #F7F8FA;
    }
    &__head {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F2F3F5;
        @include sp {
            background: #FFFFFF;
            border: 0;
            display: block;
        }
        p {
            width: calc(100%/8);
            margin: 0;
            padding: 16px 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #5C6D8A;
            &.is-ellipsis {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            @include sp {
                font-size: 14px;
                line-height: 20px;
                padding: 16px 24px;
                width: 100%;
                text-align: left;
            }
        }
    }
    &__row {
        border-bottom: 1px solid #E5E6EB;
        @include sp {
            padding: 24px 24px 16px;
            border-bottom: 1px solid #F2F3F5;
        }
        &.is-toggle {
            @include sp {
                background: #F2F3FC;
            }
        }
    }
    &__content {
        display: flex;
        align-items: center;
        transition: .3s ease-in-out;
        @include sp {
            display: block;
            
            max-height: 0;
            overflow: hidden;
        }
        &.is-toggle {
            @include sp {
                max-height: 250px;
                margin-top: 16px;
            }
        }
        p {
            width: calc(100%/8);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #1D2129;
            padding: 16px 5px;
            margin: 0;
            @include sp {
                width: 100%;
                text-align: left;
                padding: 0;
            }
             + p {
                 @include sp {
                     margin-top: 8px;
                 }
             }
            a {
                color: #1A7EC8;
                &:hover {
                    text-decoration: underline;
                }
            }
            button {
                color: #1A7EC8;
                cursor: pointer;
                padding: 0;
                margin: 0;
                appearance: none;
                border: 0;
                background: transparent;
            }
            span {
                font-weight: bold;
                margin-right: 24px;
                display: none;
                @include sp {
                    display: inline-block;
                }
                &.is-network {
                    display: block;
                    font-weight: normal;
                    margin: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    white-space: nowrap;
                    line-height: 22px;
                    @include sp {
                        display: contents;
                    }
                    img {
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        margin-right: 5px;
                    }
                }
            }
            &.is-ellipsis {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    &__toggle {
        display: none;
        appearance: none;
        padding: 0;
        margin: 0;
        border: 0;
        background: transparent;
        font-weight: 400;
        font-size: 12px;
        line-height: 25px;
        color: #1D2129;
        cursor: pointer;
        @include sp {
            display: flex;
            align-items: center;
        }
        &.is-toggle {
            span {
                border: 2px solid #1A7EC8;
                &::before,
                &::after {
                    background: #1A7EC8;
                }
                &::after {
                    transform: rotate(0);
                }
            }
        }
        span {
            display: block;
            width: 25px;
            height: 25px;
            border: 2px solid #94A3B8;
            margin-right: 12px;
            position: relative;
            transition: .3s ease-in-out;
            border-radius: 2px;
            &::before,
            &::after {
                content: '';
                width: 16px;
                height: 2px;
                background: #94A3B8;
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% - 8px);
                transition: .3s ease-in-out;
            }
            &::after {
                transform: rotate(90deg);
            }
        }
    }
    &__network {
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-weight: normal;
        margin: 0;
        img {
            display: block;
            width: 22px;
            margin-right: 5px;
        }
    }
}

.is-displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    @include sp {
        display: block;
    }
    .c-search {
        width: 40%;
        @include sp {
            width: 100%;
            margin-bottom: 16px;
        }
    }
}

.c-chartToken {
    width: 405.84px;
    height: 469px;
    position: relative;
    z-index: 1;
    &__item {
        position: absolute;
        &.is-01 {
            width: 197.6px;
            height: 217.56px;
            top: 17.3px;
            right: 2.66px;
            background: url(../images/home/chart01.png) no-repeat;
            background-size: 100% 100%;
        }
        &.is-02 {
            width: 200.26px;
            height: 99.13px;
            top: 151.69px;
            right: 0;
            background: url(../images/home/chart02.png) no-repeat;
            background-size: 100% 100%;
        }
        &.is-03 {
            width: 200.26px;
            height: 99.13px;
            top: 151.69px;
            right: 0;
            background: url(../images/home/chart03.png) no-repeat;
            background-size: 100% 100%;
        }
    }
}

.c-headerBanner {
    background: radial-gradient(100% 308.81% at 100% 0%, #1A3593 0%, #090F30 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    padding: 0 50px;
    @include max(991px) {
        padding: 0 50px 0 24px;
    }
    button {
        appearance: none;
        border: 0;
        padding: 0;
        margin: 0;
        border-radius: 0;
        background: transparent;
        width: 20px;
        height: 20px;
        position: absolute;
        top: calc(50% - 10px);
        right: 25px;
        cursor: pointer;
        img {
            width: 100%;
            display: block;
        }
    }
    &__box {
        padding: 6px 0 6px 108px;
        position: relative;
        @include sp {
            min-height: 69px;
            padding: 5px 0 5px 98px;
        }
        @include max(509px) {            
            min-height: 86px;
        }
    }
    &__icon {
        width: 82px;
        height: 72px;
        position: absolute;
        bottom: 0;
        left: 0;
        img {
            width: 100%;
        }
    }
    &__content {
        position: relative;
        border-radius: 59px;
        background: linear-gradient(279.3deg, #20A1FF 2.67%, rgba(0, 0, 0, 0) 50.59%, #596BFF 97.53%);
        padding: 19px 30px;
        @include max(1200px) {
            padding: 14px 30px;
        }
        @include max(991px) {
            padding: 8px 30px;
        }
        @include sp {
            padding: 0;
            background: transparent;
            border-radius: 0;
        }
        &:after {
            content: '';
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background: rgba(9, 15, 34, 0.7);
            box-shadow: inset 0px 4px 76px rgba(31, 31, 53, 0.25);
            backdrop-filter: blur(59px);
            border-radius: 59px;
            position: absolute;
            top: 1px;
            left: 1px;
            z-index: 1;
            @include sp {
                display: none;
            }
        }
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: inherit;
            letter-spacing: 0.02em;
            text-shadow: 1.52692px 1.52692px 0px #060C3C;
            color: #fff;
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 2;
            @include sp {
                font-size: 12px;
            }
            .is-br1200 {
                display: none;
                @include max(1200px) {
                    display: block;
                }
                @include max(480px) {
                    display: none;
                }
            }
            .is-br991 {
                display: none;
                @include max(991px) {
                    display: block;
                }
                @include max(480px) {
                    display: none;
                }
            }
            span {
                &.is-blue {
                    font-weight: 600;
                    color: rgba(57, 211, 245, 1);
                    a {
                        color: rgba(57, 211, 245, 1);
                        transition: .3s ease-in-out;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                &.is-gray {
                    font-weight: 400;
                    color: rgba(185, 192, 246, 1);
                }
            }
            a {
                color: #fff;
            }
        }
    }
}

.overlay-private{
    background: rgb(231,234,243 ,0.4);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 40px;
}