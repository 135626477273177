.c-popup {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    width: 660px;
    background: #f2f3fc;
    box-shadow: 0px 0px 40px rgba(71, 89, 235, 0.15);
    border-radius: 5px;
    padding: 24px;
    position: relative;
    @include sp {
      width: calc(100% - 32px);
    }
    &.is-w400 {
      width: 400px;
      @include sp {
        width: calc(100% - 32px);
      }
    }
    &.is-small {
      width: 510px;
      @include sp {
        width: calc(100% - 20px);
      }
    }
    &.is-btnAbsolute {
      padding-bottom: 100px;
      .c-popup__btn {
        margin-top: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 28px 24px;
        background: #f2f3fc;
        box-shadow: 8px -4px 20px rgba(71, 89, 235, 0.5);
        @include sp {
          padding: 16px 16px 24px;
        }
      }
    }
    .c-search {
      margin-bottom: 24px;
    }
  }
  &__icon {
    width: 48px;
    margin: 0 auto 24px;
    img {
      display: block;
      width: 100%;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #1d2129;
    text-align: center;
    margin-bottom: 24px;
    span {
      display: block;
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #5c6d8a;
    }
  }
  &__btn {
    margin-top: 24px;
    display: flex;
    &.is-full {
      .c-btn {
        width: 100%;
      }
    }
    .c-btn {
      width: calc(50% - 12px);
      // font-size: 13px;
      @include sp {
        width: calc(50% - 8px);
      }
      + .c-btn {
        margin-left: 24px;
        @include sp {
          margin-left: 16px;
        }
      }
    }
  }
  &__whitelist {
    max-height: 265px;
    overflow-y: auto;
    padding-right: 5px;
    scrollbar-color: #5868e9 transparent;
    scrollbar-width: thin;
    &.affiliate-list > p{
      border:0px !important
    }
    @include sp {
      max-height: 220px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5868e9;
      border-radius: 2px;
    }
    > p {
      padding: 16px 16px 16px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5c6d8a;
      margin: 0;
      border-top: 1px solid #d9dffb;
      position: relative;
      &:last-child {
        border-bottom: 1px solid #d9dffb;
      }
      span {
        display: block;
        color: #f53f3f;
        width: 60px;
        text-align: right;
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
        cursor: pointer;
      }
    }
  }
  &__box {
    &.is-editVesting {
      .ant-form-item {
        margin-bottom: 16px;
      }
      // .title {

      // }
    }
  }

  &.is-xbls {
    .c-popup__title,
    .c-popup__box,
    .c-popup__btn {
      position: relative;
      z-index: 1;
    }
    .c-popup__content {
      padding-bottom: 110px;
      background: linear-gradient(
        157.09deg,
        #ffffff 0.05%,
        rgba(71, 89, 235, 0.2) 48.84%,
        rgba(192, 211, 237, 0.4) 98.34%
      );
      &::after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: #f2f3fc;
        border-radius: 5px;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 0;
      }
    }
    .c-popup__box {
      > p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #5c6d8a;
        margin: 4px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        + p {
          margin-top: 8px;
        }
        span {
          color: #1d2129;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .c-ttl {
        margin-top: 16px;
        margin-bottom: 4px;
      }
      .is-note {
        margin-top: 16px;
        background: #fff7e8;
        border-radius: 2px;
        padding: 9px 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1d2129;
      }
    }
    .c-popup__btn {
      width: 100%;
      margin: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 16px 24px 24px;
      background: #f2f3fc;
      box-shadow: 6px -4px 20px rgba(71, 89, 235, 0.5);
      border-radius: 0 0 5px 5px;
      &.is-one {
        .c-btn {
          width: 100%;
        }
      }
    }
    .c-input {
      display: flex;
      flex-wrap: wrap;
      &__title {
        width: 100%;
      }
      input {
        width: calc(100% - 73px);
      }
      .c-btn {
        width: 65px;
        margin-left: 8px;
      }
    }
    .c-btn {
      > span {
        > span {
          margin: 0;
        }
      }
    }
  }
}
