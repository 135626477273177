ul{
    list-style-type: none;
}
.mobile{
    display: flex;
    justify-content: space-between; 
    margin-bottom: 10px;
    padding-right: 0px;
    width: 100%;
    z-index: 1002; 
    align-items: center; 
    padding-bottom: 10px;
    .popupSP {
        @include sp {  
            position: fixed; 
            top:50%;
            left:50% !important;
            transform: translate(-50%, -50%); 
            width: 100%;
        } 
    }
    .popupwallet{
        position: fixed; 
        top:50%;
        left:50% !important;
        transform: translate(-50%, -50%); 
        width: 90%;
        &.network{
            width: 90%; 
        }
        &.wallet{
            width: 90%;
        }
    }
    .btn_header{
        width: 45%;
        @include sp {
            width: calc(50% - 16px);
        }
        &_mainet{
            display: flex;
            padding: 10px;
            background-color: #F2F3FC;
            border: 1px solid #4759EB; 
            border-radius: 2px;
            align-items: center; 
            width: 100%;
            img{
                width: 20px !important;
                height: 20px !important;
            }
            span {
                @include sp {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-break: keep-all;
                    overflow: hidden;
                    margin-left: 6px;
                }
            }
        }
        &_wallet{
            padding:10px;
            background-color: #4759EB;
            border-radius: 2px;
            border:1px solid transparent;
            width: 100%;
            color:#fff
        }
        &_connectedWallet{
            padding: 5px 8px;
            background-color: #4759EB;
            display: flex;
            align-items: center;
            border-radius: 2px;
            border:1px solid transparent;
            @include sp {
                width: 100%;
            }
            .balance{
                display: none;
            }
            .btn_address{
                background-color: #ffffff;
                border-radius: 2px;
                padding: 5px;
                @include sp {
                    width: 100%;
                }
                .address{
                    color:#4759EB;
                    border-radius: 2px;
                    font-size: 16px;
                }
            } 
        }
    }
}
.header {
    display: flex; 
    position: fixed;
    right: 0px; 
    align-items: center;
    z-index: 1001;
    height: 64px;
    // width: 100%;
    justify-content: end; 
    &-bg{
        display: flex;
        background: rgba(0, 0, 0, 0.002);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        position: fixed;
        width: calc(100vw - 244px);
        height: 64px;
        justify-content: end; 
        align-items: center;
        padding-right:20px
    }
    & .btn_header{ 
        position: relative; 
        &_create{
            padding:10px;
            background-color: #D9DFFB;
            border-radius: 2px;
            display: flex;
            align-items: center;
            border:1px solid #D9DFFB;
            &:hover{
                background-color: #F2F3FC;
                cursor: pointer;  
            }
            span{
                color:#394DF5;
                font-size: 16px;
                margin-left: 5px;
            }
        }
        &_mainet{
            display: flex;
            padding: 10px;
            background-color: #F2F3FC;
            border: 1px solid #4759EB;
            margin: 0 20px;
            border-radius: 2px;
            align-items: center;
            &:hover{
                background-color: #4759EB;
                cursor: pointer;  
                span{
                    color:#fff
                }
            }
            span{
                font-size: 16px;
                margin-left: 5px;
            }
            img {
                width: 20px;
            }
        }
        &_wallet{
            padding:10px;
            background-color: #4759EB;
            border-radius: 2px;
            border:1px solid transparent;
            span{
                font-size: 16px;
                color: #FFFFFF;
            }
            &:hover{
                cursor: pointer;
            }
        }
        &_connectedWallet{
            padding: 5px 8px;
            background-color: #4759EB;
            display: flex;
            align-items: center;
            border-radius: 2px;
            border:1px solid transparent;
            .balance{
                font-weight: 700;
                font-size: 16px;
                color: #FFFFFF;
                margin-right: 10px;
            }
            .btn_address{
                background-color: #ffffff;
                border-radius: 2px;
                padding: 5px;
                .address{
                    color:#4759EB;
                    border-radius: 2px;
                    font-size: 16px;
                }
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
}
.overplay{
    position: fixed;
    background-color:rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0; 
    z-index: 1000;
}
.popupwallet{ 
    padding:25px;
    position: absolute;
    top:100%;
    margin-top: 10px;
    background-color: white;
    right: 0px; 
    width: 260px; 
    z-index: 1002;
    .smalltriangle{
        width: 0; 
        height: 0; 
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        position: absolute;
        top: -8px;
        right: 50px;
        @include max(991px){
            display:none
        }
        &.network{
            left: 50%;
            transform: translateX(-50%)
        }
        &.create{
            left: 50%;
            transform: translateX(-50%)
        }
    }
    .wallet{
        margin-left: 8px; 
        &_address{
            color: #5C6D8A;
        }
        &_amount{
            display: block;
            color: #394DF5;
        }
    }
    .infowallet{
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #F2F3FC;
    }
    .tabwallet{
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 5px;
        padding-bottom: 10px; 
        cursor: pointer;
        &:hover{
            background-color: #F2F3FC;
            & .title{
                color:#394DF5
            }
        }
        .img{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
        }
        .title{
            margin-left: 10px;
            color:#5C6D8A
        } 
    }
    .logout{
        padding-top: 30px;
        border-top: 1px solid #F2F3FC;
        button{ 
            border-radius: 2px;
            width: 100%;
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            border: 0px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &.create{  
       left:-56px;
       width: 224px;
     
    }
    &.network{
        left:-103px;
        width: 412px;
      
    }
    &.wallet{
        width: 412px; 
    }
}
.listwallet{
    width: 100%;
    margin-bottom: 15px;
    padding:10px 16px;
    background-color:#F2F3FC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    &:hover{
        background-color: #D9DFFB;
        cursor: pointer;
        transition-delay: 0.1s;
    }
    &:last-child{
        margin-bottom: 0px;
    }
    &_left{
        display: flex;
        align-items: center;
        & .img{
            margin-right: 15px;
            display: flex;
            justify-content: center;
            padding-right: 15px;
            border-right: 1px solid #D9DFFB;
            img {
                width: 30px;
            }
        } 
    }
    &_right{
        width: 20px;
        height: 20px;
    }
}

.ant-modal-content{
    border-radius: 30px;
}