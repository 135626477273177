.p-blsDashboard-vesting
  .ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 12px 6px;
  padding-right: 35px;
}
.row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.in {
  padding-bottom: 10px;
}
.in span {
  color: #5868e9;
  font-size: 14px;
}
.in .text {
  color: #3947b7;
  font-size: 15px;
  font-weight: 400;
}
.p-blsDashboard-vesting .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 16px 6px 6px;
}
.time .time-txt {
  color: #5868e9;
  font-size: 14px;
}
.time .text {
  color: #3947b7;
  font-size: 16px;
}
.p-blsDashboard-vesting
  .ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 10px;
}
.btn {
  padding-right: 15px;
}
.ree-his {
  display: flex;
}

.his-header {
  width: 100%;
}
.his-row .put {
  width: 20%;
}
.his-row .time {
  width: 25%;
}
.his-row .hash {
  width: 20%;
}
.his-row .btn-his {
  width: 15%;
}
.his-title {
  background: #d9dffb;
  text-align: left;
}
.his-row {
  padding: 15px 10px;
  color: #5868e9;
  font-size: 14px;
  font-weight: 500;
}
.drawer-his {
  z-index: 10000;
}
.drawer-his .ant-drawer-content {
  background: #f2f3fc;
}
.drawer-his .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
}
td.his-content {
  padding: 15px 0px 15px 10px;
}
.his-content.put {
  color: #5868e9;
  font-size: 16px;
  font-weight: 500;
}
.his-content.time {
  color: #1d2129;
  font-size: 16px;
  font-weight: 500;
}
.his-content.status {
  color: #fbaca3;
  font-size: 16px;
  font-weight: 500;
}
.his-content.statusClaim {
  color: #7be188;
  font-size: 16px;
  font-weight: 500;
}
.his-content.txh {
  color: #20a1ff;
  font-size: 16px;
  font-weight: 500;
}
.his-content.txh:hover {
  cursor: pointer;
}
.drawer-his .ant-drawer-header {
  border-bottom: 1px solid #5c6d8a;
}
.drawer-his .ant-collapse {
  border-radius: 4px;
  margin-bottom: 10px;
}
.drawer-his
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  display: flex;
  justify-content: space-between;
}
.p-blsDashboard-vesting .ant-collapse {
  background-color: #ffffff;
  border: none;
}
.drawer-his .ant-collapse {
  background-color: #f2f3fc;
}
.drawer-his .ant-collapse-content {
  background-color: #f2f3fc;
}
.nodata {
  width: 100%;
  text-align: center;
}
.vesting {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.vesting-text {
  display: flex;
}
.info-img {
  padding-top: 3px;
  padding-left: 3px;
}
.p-blsDashboard-cancel.mobie {
  padding: 8px 3px 8px 8px;
}
.tooltip-mobie {
  left: 25% !important;
  top: 1390px !important;
}
.tooltip-mobie.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 15%;
}
.tooltip-vesting.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 2%;
}
.tooltip-vesting.ant-tooltip-inner {
  min-width: 300px;
}
.notice {
  font-size: 15px;
  font-weight: 500;
}
.ant-pagination.pag-redeem {
  padding-top: 10px;
  text-align: center;
}
.ant-pagination.pag-redeem-his {
  padding-top: 15px;
  text-align: center;
}
.pag-redeem-his .ant-pagination-prev .ant-pagination-item-link {
  background-color: #f2f3fc;
}
.pag-redeem-his .ant-pagination-next .ant-pagination-item-link {
  background-color: #f2f3fc;
}

.pag-redeem-his .ant-pagination-item-active {
  background-color: #f2f3fc;
}
.pag-redeem-his .ant-pagination-item {
  background-color: #f2f3fc;
}
.list-reedem-col .ant-collapse-content {
  border-top: none;
}
.list-reedem-col .anticon {
  padding: 4px;
  background: black;
  border-radius: 99px;
}
.list-reedem-col .anticon svg {
  fill: #fff;
}
.w-31 {
  width: 31%;
}
.w-35 {
  width: 35%;
}
.over-view {
  display: none;
}
.reserved {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #94a7c6;
  font-size: 14px;
  bottom: 10;
}
.not-connect {
  width: 100%;
  background: #5c6d8a;
  color: #fff;
  height: 35px;
  border-radius: 2px;
  opacity: 0.5;
  border: none;
}
.not-connect-redeem {
  width: auto;
  background: #5c6d8a;
  color: #fff;
  height: 35px;
  border-radius: 2px;
  opacity: 0.5;
  border: none;
}
.switch-arb {
  width: 100%;
  height: 35px;
  border: none;
  color: #fff;
  background: #4759eb;
  border-radius: 2px;
}
.switch-arb:hover {
  cursor: pointer;
}
.switch-arb-redeem:hover {
  cursor: pointer;
}
.switch-arb-redeem {
  width: 30%;
  height: 35px;
  border: none;
  color: #fff;
  background: #4759eb;
  border-radius: 2px;
}
@media screen and (max-width: 768px) {
  .switch-arb-redeem {
    width: 50%;
  }
  .not-connect-redeem {
    width: 50%;
    background: #5c6d8a;
    color: #fff;
    height: 35px;
    border-radius: 2px;
    opacity: 0.5;
    border: none;
  }
  .reserved {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #94a7c6;
    font-size: 14px;
    bottom: 10;
  }
  .over-view {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: block;
  }

  .w-31 {
    width: 100%;
  }
  .w-35 {
    width: 100%;
  }
  .in {
    padding-bottom: 0px;
  }
  .c-popup__btn .c-btn {
    font-size: 13px;
  }
  .his-content {
    font-size: 14px !important;
  }
  .p-blsDashboard-vesting h2 {
    margin-bottom: 0;
  }
  .p-blsDashboard-redeem {
    font-size: 12px;
    margin-right: 5px;
  }
  .p-blsDashboard-redeem img {
    width: 14px;
    margin-bottom: 1px;
    margin-right: 5px;
  }
}
